import { useNotification } from 'ui/src/components/Notification/useNotification';
import { trpc } from '../lib/trpc';

export function usePingPongCreateRecipient() {
  const utils = trpc.useContext();
  const setNotification = useNotification((e) => e.setNotification);
  const {
    mutateAsync: createPingPongRecipient,
    isLoading: createRecipientLoading,
  } = trpc.a.bankAccounts.createPingPongRecipient.useMutation({
    onSuccess: ({ resultType }) => {
      utils.a.bankAccounts.bankAccount.invalidate();
      setNotification({
        type: 'success',
        title: 'Success',
        message: `${resultType}: Bank account information updated`,
      });
    },
    onError: (e: any) => {
      setNotification({
        type: 'error',
        title: 'Error',
        message: e.toString(),
      });
    },
  });

  return {
    createRecipientLoading,
    createPingPongRecipient,
  };
}
