import { uniq } from 'lodash';
import { DateTime } from '../luxonUtc';

export function stringFromDateOrString(dateOrString: string | Date): string {
  if (typeof dateOrString === 'string') {
    return dateOrString;
  }

  return DateTime.fromJSDate(dateOrString).toISODate() ?? '';
}

export function dateFromStringOrDate(
  dateOrString?: string | Date | null,
): Date | undefined {
  if (typeof dateOrString !== 'string' && !dateOrString) return undefined;
  if (typeof dateOrString === 'string') {
    return DateTime.fromISO(dateOrString).toJSDate();
  }
  return dateOrString;
}

/**
 * Extracts a luxon DateTime from a variety of possible inputs
 */
export function dateTimeOf(
  dateOrString?: string | Date | null | DateTime,
): DateTime | undefined {
  if (!dateOrString) return undefined;
  if (DateTime.isDateTime(dateOrString)) {
    return dateOrString;
  }
  if (typeof dateOrString === 'string') {
    return DateTime.fromISO(dateOrString);
  }
  return DateTime.fromJSDate(dateOrString);
}

export function monthNamesBetweenDates(start: Date, end: Date) {
  const firstMonthDt = DateTime.fromJSDate(start);
  const lastMonthDt = DateTime.fromJSDate(end);

  const monthNames = uniq(
    firstMonthDt
      .until(lastMonthDt)
      .splitBy({ months: 1 })
      .map((i) => [i.start?.monthShort, i.end?.monthShort])
      .flat(),
  );

  return monthNames;
}

export function getMonthsArray(start: Date, end: Date) {
  const startDate = DateTime.fromJSDate(start);
  const endDate = DateTime.fromJSDate(end);

  return uniq(
    startDate
      .until(endDate)
      .splitBy({ months: 1 })
      .map((i) => [i.start?.monthLong, i.end?.monthLong])
      .flat(),
  );
}

export function getMonthNumber(monthName: string, year: number): number {
  const date = DateTime.fromFormat(`${year} ${monthName}`, 'yyyy LLLL');
  return date.month;
}

export function getDaysInMonth(month: number, year: number): number {
  return new Date(year, month, 0).getDate();
}

export function parseDate(date: Date): DateTime {
  return DateTime.fromJSDate(date);
}

export function parseIso(date: string): DateTime {
  return DateTime.fromISO(date);
}

export function formatDate(date: string | Date | undefined) {
  if (date === undefined) return 'Invalid Date';
  const iso = stringFromDateOrString(date);
  return DateTime.fromISO(iso).toLocaleString(DateTime.DATE_MED);
}

export function formatDateShort(date: Date | undefined) {
  if (date === undefined) return 'Invalid Date';
  return DateTime.fromJSDate(date).toFormat('MMM d');
}

export function formatDateShortened(date: string | Date | undefined) {
  if (date === undefined) return 'Invalid Date';
  const iso = stringFromDateOrString(date);
  return DateTime.fromISO(iso).toLocaleString();
}

export function dateFromIsoString(dateString: string) {
  return DateTime.fromISO(dateString).toJSDate();
}

export function dateFromShortString(dateString: string) {
  return DateTime.fromFormat(dateString, 'yyyy-MM-dd').toJSDate();
}

export function formatIsoLocalDate(date: string | Date | DateTime | undefined) {
  const dateTime = dateTimeOf(date);
  return dateTime?.toFormat('yyyy-MM-dd');
}
