import { useState } from 'react';

/**
 * Used to track focused state
 */
export function useFocused(): [
  boolean,
  { onFocus: VoidFunction; onBlur: VoidFunction },
] {
  const [focused, setFocused] = useState(false);
  return [
    focused,
    {
      onFocus: () => setFocused(true),
      onBlur: () => setFocused(false),
    },
  ];
}
