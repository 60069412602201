import { useCallback, useState } from 'react';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { useNavigate } from 'react-router-dom';
import { trpc } from '../../lib/trpc';
import { Spinner } from '../../components/Spinner/Spinner';

export function GenerateInvoice({ payPeriodId }: { payPeriodId: string }) {
  const setNotification = useNotification((state) => state.setNotification);
  const [shouldDisable, setShouldDisable] = useState(false);
  const navigate = useNavigate();

  const { data: isQBConnected } = trpc.a.quickbooks.isConnected.useQuery();
  const { mutate: generateInvoice } =
    trpc.a.payPeriods.generateInvoice.useMutation({
      onSuccess: () => {
        setNotification({
          title: 'Success',
          message: 'Invoice Generated from Pay Period',
          type: 'success',
        });
      },
      onError: (err) => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: `Error creating invoice: ${err}`,
        });
        setShouldDisable(false);
      },
    });

  const handleSubmit = useCallback(() => {
    setShouldDisable(true);
    generateInvoice(payPeriodId);
  }, [payPeriodId]);

  if (isQBConnected == null) {
    return <Spinner />;
  }

  return isQBConnected ? (
    <ActionPanel
      title="Generate Invoice from Pay Period"
      content="Generate Invoice via Quickbooks"
    >
      <Button
        text="Generate"
        disabled={shouldDisable}
        onClick={() => handleSubmit()}
      />
    </ActionPanel>
  ) : (
    <ActionPanel
      title="Connect Quickbooks"
      content="You need to connect Quickbooks to continue"
    >
      <Button text="Connect" onClick={() => navigate('/quickbooks')} />
    </ActionPanel>
  );
}
