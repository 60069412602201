import { HTMLProps } from 'react';

export function Card({
  children,
  className,
  ...props
}: HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={`rounded-lg bg-white px-3 pt-3 pb-3 shadow sm:px-6 sm:pt-6 ${
        className ?? ''
      }`}
      {...props}
    >
      {children}
    </div>
  );
}
