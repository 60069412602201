import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { DateTime } from 'listo/src/luxonUtc';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import superjson from 'superjson';
import { Banner } from 'ui/src/components/Banner';
import useLocalStorage from 'ui/src/hooks/useLocalStorage';
import { IntlProvider } from 'react-intl';
import AppShell from './components/AppShell';
import { AuthProvider } from './hooks/useAuth';
import useImpersonate from './hooks/useImpersonate';
import useSidebar from './hooks/useSidebar';
import { API_URL, BUNDLE_VERSION, IS_PROD } from './lib/constants';
import { customLink } from './lib/customLink';
import { trpc } from './lib/trpc';
import Admins from './pages/Admins';
import { BulkContracts } from './pages/BulkContracts';
import Clients from './pages/Clients';
import ClientTabs from './pages/ClientTabs';
import PayOverrides from './pages/ClientTabs/PayOverrides';
import Contract from './pages/Contract';
import BankAccounts from './pages/Contract/BankAccounts';
import { Document, Payments } from './pages/Contract/DetailCard';
import EditBankAccountInfo from './pages/Contract/EditBankAccountInfo';
import EditContract from './pages/Contract/EditContract';
import Contracts from './pages/Contracts';
import CreateAdmin from './pages/CreateAdmins';
import CreateClient from './pages/CreateClient';
import { CreateContract } from './pages/CreateContract';
import { Credential } from './pages/Credential';
import { Credentials } from './pages/Credentials';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Distribution } from './pages/Distribution';
import Distributions from './pages/Distributions';
import DistributionsCreate from './pages/DistributionsCreate';
import EditDistribution from './pages/Distribution/EditDistribution';

import EditClient from './pages/EditClient';
import ForgotPassword from './pages/ForgotPassword';
import { Internal } from './pages/Internal';
import { InvoiceCreate } from './pages/InvoiceCreate';
import { InvoiceEdit } from './pages/InvoiceEdit';
import Invoices from './pages/Invoices';
import { InvoiceShow } from './pages/InvoiceShow/InvoiceShow';
import Login from './pages/Login';
import { PayPeriod } from './pages/PayPeriod';
import { PayPeriodCreate } from './pages/PayPeriodCreate';
import { PayPeriods } from './pages/PayPeriods';
import { PingPong } from './pages/PingPong';
import { Connected } from './pages/PingPong/Connected';
import { Quickbooks } from './pages/Quickbooks';
import { Quote } from './pages/Quote';
import { Quotes } from './pages/Quotes';
import { ViewWorkerProfile } from './pages/ViewWorkerProfile/ViewWorkerProfile';
import Workers from './pages/Workers';
import { Integrations } from './pages/Integrations/Integrations';

function getJwt() {
  const jwt = window.localStorage.getItem('jwt');
  if (jwt) {
    return JSON.parse(jwt);
  }

  return null;
}

function AdminRoutes() {
  const location = useLocation();
  const [showUpdateAvailable, setShowUpdateAvailable] = useState(false);
  const [latestVersion, setLatestVersion] = useLocalStorage(
    'latestVersion',
    undefined,
  );
  const [lastTimeUpdateRequested, setLastTimeUpdateRequested] = useLocalStorage(
    'lastTimeUpdateRequested',
    undefined,
  );

  useEffect(() => {
    if (IS_PROD)
      fetch('https://admin.listoglobal.com/latestVersion.txt')
        .then((res) => res.text())
        .then((version) => {
          const c = DateTime.fromISO(version.replace(/\n/g, ''));
          setLatestVersion(c.toISO());
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
  }, [setLatestVersion]);

  useEffect(() => {
    if (!latestVersion) return;
    const bundleVersion = DateTime.fromISO(BUNDLE_VERSION);
    const c = DateTime.fromISO(latestVersion);

    if (bundleVersion < c) {
      const now = DateTime.now();

      if (
        lastTimeUpdateRequested === undefined ||
        DateTime.fromISO(lastTimeUpdateRequested).diff(now, 'days').days > 1
      ) {
        setLastTimeUpdateRequested(now.toISO());
        setShowUpdateAvailable(true);
      }
    }
  }, [
    latestVersion,
    lastTimeUpdateRequested,
    setLastTimeUpdateRequested,
    // check if there is a new bundle version set to localstorage on each pageload
    location.pathname,
  ]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/" element={<AppShell />}>
          <Route index element={<Dashboard />} />
          <Route path="clients/:id" element={<ClientTabs />}>
            <Route index element={<EditClient />} />
            <Route path="info" element={<EditClient />} />
            <Route path="price-overrides" element={<PayOverrides />} />
          </Route>
          <Route path="clients" element={<Clients />} />
          <Route
            path="workerProfiles/:workerProfileId"
            element={<ViewWorkerProfile />}
          />
          <Route path="quickbooks" element={<Quickbooks />} />
          <Route path="credentials/:credentialId" element={<Credential />} />
          <Route path="credentials" element={<Credentials />} />
          <Route path="workers" element={<Workers />} />
          <Route path="invoices/:invoiceId" element={<InvoiceShow />} />
          <Route path="invoices/create" element={<InvoiceCreate />} />
          <Route path="invoices/:invoiceId/edit" element={<InvoiceEdit />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="pay-periods/create" element={<PayPeriodCreate />} />
          <Route path="pay-periods/:payPeriodId" element={<PayPeriod />} />
          <Route path="pay-periods" element={<PayPeriods />} />
          <Route path="quotes/:quoteId" element={<Quote />} />
          <Route path="quotes" element={<Quotes />} />
          <Route path="integrations" element={<Integrations />} />
          <Route
            path="distributions/create"
            element={<DistributionsCreate />}
          />
          <Route
            path="distributions/:distributionId"
            element={<Distribution />}
          />
          <Route
            path="distributions/:id/:client/edit"
            element={<EditDistribution />}
          />

          <Route path="distributions" element={<Distributions />} />
          <Route path="clients/create" element={<CreateClient />} />
          <Route path="contracts" element={<Contracts />} />
          <Route path="contracts/create" element={<CreateContract />} />
          <Route path="contracts/bulk" element={<BulkContracts />} />
          <Route path="contracts/:id/quotes/new" element={<Contract />} />
          <Route path="contracts/:id" element={<Contract />} />
          <Route path="contracts/:id/edit" element={<EditContract />} />
          <Route path="contracts/:id" element={<Contract />} />
          <Route path="admins" element={<Admins />} />
          <Route path="admins/create" element={<CreateAdmin />} />
          <Route path="contracts/:id/documents" element={<Document />} />
          <Route
            path="worker-profiles/:workerProfileId/bank-accounts"
            element={<BankAccounts />}
          />
          <Route
            path="worker-profiles/:workerProfileId/bank-accounts/:bankAccountId"
            element={<EditBankAccountInfo />}
          />
          <Route path="contracts/:id/payments" element={<Payments />} />
          <Route path="contracts/:id/quotes" element={<Quote />} />
          <Route path="ping-pong" element={<PingPong />} />
          <Route path="ping-pong/connected" element={<Connected />} />
          <Route path="internal" element={<Internal />} />
        </Route>
        <Route
          path="*"
          element={
            <div>
              <h1>404</h1>
            </div>
          }
        />
      </Routes>

      {showUpdateAvailable ? (
        <Banner
          bigText="We're always improving! There is a new app release available."
          shortText="Get our newest app version"
          buttonText="Update Now"
          onConfirm={() => {
            window.location.reload();
          }}
          onClose={() => {
            setShowUpdateAvailable(false);
          }}
        />
      ) : null}
    </>
  );
}

export function Dummy({ hasTopBar = true }: { hasTopBar?: boolean }) {
  const location = useLocation();
  const { setSidebarIsOpen, sidebarIsOpen } = useSidebar();

  return (
    <>
      {hasTopBar && (
        <div className="bg-white shadow">
          <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
            <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
              <div className="flex-1 min-w-0">
                <div className="flex items-center">
                  <div>
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
                        onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
                      >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3CenterLeftIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </button>

                      <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                        {location.pathname}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <h1>{`placeholder component ${location.pathname}`}</h1>
      </div>
    </>
  );
}

function App() {
  useImpersonate();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });
  const [trpcQueryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        customLink,
        httpBatchLink({
          url: `${API_URL}/trpc`,
          headers() {
            return {
              authorization: `Bearer ${getJwt()}` || '',
            };
          },
        }),
      ],
    }),
  );

  return (
    <ErrorBoundary>
      <IntlProvider locale="en-us">
        <QueryClientProvider client={queryClient}>
          <IntlProvider locale="en-us">
            <trpc.Provider client={trpcClient} queryClient={trpcQueryClient}>
              <QueryClientProvider client={trpcQueryClient}>
                <AuthProvider>
                  <Router>
                    <AdminRoutes />
                  </Router>
                </AuthProvider>
              </QueryClientProvider>
            </trpc.Provider>
          </IntlProvider>
        </QueryClientProvider>
      </IntlProvider>
    </ErrorBoundary>
  );
}

export default App;
