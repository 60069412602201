import {
  ArrowDownTrayIcon,
  PaperClipIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import UploadBox from '../../components/UploadBox';

import { trpc } from '../../lib/trpc';

import { AddNewDocument } from './AddNewDocument';

export function Payments() {
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState('');

  const { data, isLoading, error } = trpc.a.contracts.paystubs.useQuery({
    contractId: id as string,
  });

  const contract = trpc.a.contracts.contract.useQuery(id as string);

  const utils = trpc.useContext();

  const { mutate } = trpc.a.contracts.addPaystub.useMutation({
    onSuccess: () => {
      setName('');
      utils.a.contracts.paystubs.invalidate({ contractId: id as string });
    },
  });

  if (isLoading) return <Loader />;
  if (error) return <p>{error.message}</p>;
  if (data === undefined) return <Loader />;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Payments" />
      <section aria-labelledby="custom-contract-actions" className="m-8">
        <div className="bg-white shadow sm:rounded-lg p-6">
          <div className="flow-root mt-6">
            <ul className="-my-5 divide-y divide-gray-200">
              {data.map((paystub) => (
                <li key={paystub.id} className="py-4">
                  <div className="flex items-center space-x-4">
                    <div>
                      <PaperClipIcon className="h-5 w-5 rounded-full" />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {paystub.name}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {paystub.createdAt.toString()}
                      </p>
                    </div>
                    <div>
                      <a
                        href={paystub.upload?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ArrowDownTrayIcon className="w-5 h-5" />
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-6">
            <div>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="block text-sm font-medium text-gray-700">
                Paystub Name
              </label>
              <div className="mt-1">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="name"
                  type="text"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            {contract.data?.clientId ? (
              <UploadBox
                clientId={contract.data.clientId}
                allowedTypes={['.pdf']}
                listoAssetType="paystubs"
                onFileUploaded={(u) => {
                  mutate({
                    name,
                    contractId: id as string,
                    uploadId: u.id,
                  });
                }}
              />
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
}

export function Document() {
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const contract = trpc.a.contracts.contract.useQuery(id as string);
  let clientId = '';

  if (contract.data) {
    clientId = contract.data.clientId;
  }

  const {
    data: documents,
    isLoading: documentsLoading,
    error,
  } = trpc.a.documents.list.useQuery({ contractId: id as string });

  if (error) return <div>error</div>;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Documents" />
      <section aria-labelledby="custom-contract-actions" className="m-8">
        <div className="bg-white shadow sm:rounded-lg p-6">
          <div className="p-5">
            <div className="flow-root mt-6">
              <div className="sm:flex sm:items-center mb-10">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Documents
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of documents required in this contract.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    onClick={() => setOpenModal(true)}
                  >
                    Add New
                  </button>
                </div>
              </div>
              <Modal isOpen={openModal} setIsOpen={() => setOpenModal(false)}>
                <AddNewDocument
                  clientId={clientId}
                  setOpenModal={setOpenModal}
                />
              </Modal>

              {documentsLoading || !documents ? (
                <Loader />
              ) : (
                <ul className="my-5 divide-y divide-gray-200 border-t border-gray-200">
                  {documents.length === 0 && (
                    <li className="py-4">
                      <div className="flex items-center space-x-4 ">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          No Documents Requested
                        </p>
                      </div>
                    </li>
                  )}
                  {documents.map((doc) => (
                    <li key={doc.id} className="py-4">
                      <div className="flex items-center space-x-4">
                        <div>
                          <PaperClipIcon className="h-5 w-5 rounded-full" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {doc.documentType}
                          </p>
                        </div>
                        <div>
                          <a href="#">
                            <XCircleIcon className="w-5 h-5" />
                          </a>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
