import { titleCase } from 'listo/src/utils/strings';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'listo/src/luxonUtc';
import { Button } from 'ui/src/components/Button';
import { formatDate } from 'listo/src/utils/dates';
import Header from '../../components/Header';
import TableLoading from '../../components/TableLoading';
import { trpc } from '../../lib/trpc';
import { DeletePayPeriod } from './DeletePayPeriod';
import { GenerateInvoice } from './GenerateInvoice';
import { MarkAsDistributed } from './MarkAsDistributed';

export function PayPeriod() {
  const { payPeriodId } = useParams();
  const navigate = useNavigate();

  const { data, error, isLoading } = trpc.a.payPeriods.payPeriod.useQuery(
    payPeriodId as string,
  );

  if (error) return <h1>error</h1>;
  if (isLoading || !data)
    return (
      <div className="bg-gray-100 min-h-screen">
        <Header title="Pay Period" />
        <TableLoading />
      </div>
    );

  const generatePayPeriodURL = () => {
    // gets required data for create payperiod
    const copiedPayPeriodDetails = {
      clientId: data.clientId,
      periodStart: data.periodStart,
      periodEnd: data.periodEnd,
      approvalDate: data.approvalDate,
      invoiceDate: data.invoiceDate,
      processDate: data.processDate,
      distributeDate: data.distributeDate,
      payPeriodType: data.periodType,
    };

    // normalizes the data to correct form format
    let normalizedObj = {};
    const copiedPayPeriodArray = Object.entries(copiedPayPeriodDetails);

    copiedPayPeriodArray.forEach(([key, value]) => {
      let normalizedValue = value;
      const validDateCondition1 = value instanceof Date;
      const validDateCondition2 = DateTime.fromJSDate(value as Date).isValid;

      if (validDateCondition1 && validDateCondition2) {
        const date = DateTime.fromJSDate(value);
        const formatedValue = date.toISODate();
        normalizedValue = formatedValue;
      }

      normalizedObj = Object.assign(normalizedObj, { [key]: normalizedValue });
    });

    // generates url using normalized Data

    let url = '';
    const normalizedArray = Object.entries(normalizedObj);

    normalizedArray.forEach(([key, value], index) => {
      if (index === 0) {
        url += `?${key}=${value}`;
      } else {
        url += `&${key}=${value}`;
      }
    });

    return url;
  };
  return (
    <div className="bg-gray-100 min-h-screen pb-8">
      <Header title="Pay Period" />
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-3">
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg p-6">
              <div>
                <div className="flex items-start justify-between px-4 py-5 sm:px-6">
                  <div>
                    <div className="flex items-center justify-between">
                      <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Pay Period Information
                      </h2>
                    </div>

                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Pay Period details and overview.
                    </p>
                  </div>
                  <Button
                    text="Copy Pay Period"
                    onClick={() =>
                      navigate(`/pay-periods/create${generatePayPeriodURL()}`)
                    }
                  />
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 border-b border-gray-200 pb-4 sm:pb-4">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Client
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {titleCase(data.client.name)}
                      </dd>
                    </div>
                    <div id="status" className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Period Type
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {data.periodType}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Period Start
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatDate(data.periodStart)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Period End
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatDate(data.periodEnd)}
                      </dd>
                    </div>

                    <div id="status" className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Status
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {data.status}
                      </dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Invoice Date
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatDate(data.invoiceDate)}
                      </dd>
                    </div>
                    {data.invoicedAt && (
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Invoiced At
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {formatDate(data.invoicedAt)}
                        </dd>
                      </div>
                    )}
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Distributed Date
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatDate(data.distributeDate)}
                      </dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Process Date
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatDate(data.processDate)}
                      </dd>
                    </div>

                    {data.distributedAt && (
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Distributed At
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {formatDate(data.distributedAt)}
                        </dd>
                      </div>
                    )}

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Created At
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatDate(data.createdAt)}
                      </dd>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 mt-8">
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        No of Distributions
                      </h2>

                      <a
                        href="#"
                        className="mt-1 grid grid-cols-1 gap-4"
                        onClick={() => {
                          navigate(`/distributions?clientId=${data.clientId}`);
                        }}
                      >
                        <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400">
                          <div className="min-w-0 flex-1">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            <p className="text-sm font-medium text-gray-900">
                              {data.distributions.length}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Invoice Amount
                      </h2>
                      <div className="mt-1 grid grid-cols-1 gap-4">
                        <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400">
                          <div className="min-w-0 flex-1">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            <p className="text-sm font-medium text-gray-900">
                              ${' '}
                              {data.invoice
                                ? data.invoice.amountInCents / 100
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section aria-labelledby="custom-contract-actions">
            <div className="sm:rounded-lg grid grid-cols-3 gap-4">
              <div className="col-span-1 flex flex-col">
                {!data.distributedAt ? (
                  <MarkAsDistributed payPeriodId={data.id} />
                ) : null}
              </div>
              {data.status === 'APPROVED' && !data.invoiceId && (
                <div className="col-span-1 flex flex-col items-start">
                  <GenerateInvoice payPeriodId={data.id} />
                </div>
              )}
              <div className="col-span-1 flex flex-col">
                <DeletePayPeriod payPeriodId={data.id} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
