import { z } from 'zod';

export const ContractTypeEnum = z.enum([
  'VARIABLE_RATE',
  'FIXED_RATE',
  'MILESTONE',
]);
export type ContractType = z.infer<typeof ContractTypeEnum>;

export const ContractStatusEnum = z.enum([
  'DRAFT',
  'PENDING_RELEASE',
  'AWAITING_SIGNATURES',
  'EXECUTED',
]);
export type ContractStatus = z.infer<typeof ContractStatusEnum>;

export const BillingFrequencyEnum = z.enum(['HOURLY', 'MONTHLY', 'ANNUALLY']);
export type BillingFrequency = z.infer<typeof BillingFrequencyEnum>;

export const DistributionMethodEnum = z.enum([
  'PING_PONG',
  'CORPAY',
  'WISE',
  'UNKNOWN',
]);
export type DistributionMethod = z.infer<typeof DistributionMethodEnum>;

export const LineItemTypesEnum = z.enum(['CONTRACTOR_PAYMENT']);
export type LineItemTypes = z.infer<typeof LineItemTypesEnum>;

export const contractBaseFields = z.object({
  name: z.string(),
  contractType: ContractTypeEnum,
  entityId: z.string().optional(),
  clientId: z.string().uuid().min(1, "What is the client's id?"),
});

export const contractorFields = z.object({
  firstName: z.string().min(1, "What's the contractor's first name?"),
  lastName: z.string().min(1, "What's the contractor's last name?"),
  email: z.string().email().min(1, "What is the contractor's email?"),
  isoCountryCode: z.string().min(1, "What is the contractor's country code?"),
});

export type ContractorFields = z.infer<typeof contractorFields>;

export const contractorJobFields = z.object({
  jobTitle: z.string().min(1, 'What is the job title?'),
  seniorityLevel: z.string().min(1, 'What is the seniority level?'),
  scopeOfWork: z.string().min(1, 'What is the scope of work?'),
  startDate: z.string().min(1, 'What is the start date?'),
});

export type ContractorJobFields = z.infer<typeof contractorJobFields>;

export const contractorPaymentFields = z.object({
  distributionCurrency: z.string().min(1, 'What is the distribution currency?'),
  distributionFrequency: z.enum(['MONTHLY', 'SEMI_MONTHLY', 'BI_WEEKLY']),
  billingAmountInCents: z.preprocess(
    (val) => parseFloat(val as string),
    z.number().min(1, 'What is the billing amount?'),
  ),
  billingFrequency: BillingFrequencyEnum,
  billingCurrency: z.string(),
});

export type ContractorPaymentFields = z.infer<typeof contractorPaymentFields>;

export const contractorContractCreate = contractBaseFields
  .merge(contractorFields)
  .merge(contractorJobFields)
  .merge(contractorPaymentFields);
export type ContractorContractCreate = z.infer<typeof contractorContractCreate>;

export const eorEmployeeFields = z.object({
  fullName: z.string().min(1, "What is the empolyee's full name?"),
  email: z
    .string()
    .email('Not a valid email address')
    .min(1, "What is the employee's email?"),
  nationality: z.string().min(1, "What is the employee's nationality?"),
  country: z.string().min(1, 'Where will the employee be working?'),
  requiresVisa: z
    .string({ required_error: 'Does this employee require visa?' })
    .min(1),
});

export type EorEmployeeFields = z.infer<typeof eorEmployeeFields>;

export const eorJobFields = z.object({
  jobTitle: z.string().min(1, 'What is the job title?'),
  seniorityLevel: z.string().min(1, 'What is the seniority level?'),
  jobDescription: z.string().min(1, 'What is the job description?'),
  startDate: z.string().min(1, 'What is the start date?'),
});

export type EorJobFields = z.infer<typeof eorJobFields>;

export const eorCompensationFields = z.object({
  distributionCurrency: z.string().min(1, 'What is the distribution currency?'),
  annualBaseSalary: z.optional(
    z.preprocess(
      (val) => parseInt(val as string, 10),
      z.number().min(0, 'What is the annual base salary?'),
    ),
  ),
  variableCompensation: z
    .string({
      invalid_type_error: 'Is user eligible for variable compensation?',
    })
    .min(1, 'Is user eligible for variable compensation?'),
  employmentType: z
    .string({ invalid_type_error: 'What is the employment type?' })
    .min(1, 'What is the employment type?'),
  contractTerm: z
    .string({ invalid_type_error: 'What is the contract term?' })
    .min(1, 'What is the contract term?'),
  workType: z
    .string({ required_error: "'Does this employee is on Salary or Hourly?'" })
    .min(1, 'What is the work type?'),
  billingFrequency: z.optional(
    z
      .string({ required_error: 'What is the billing frequency?' })
      .min(1, 'What is the billing frequency?'),
  ),
  billingAmountInCents: z.optional(
    z.preprocess(
      (val) => parseFloat(val as string),
      z
        .number({ invalid_type_error: 'Invalid Payment Amount' })
        .min(0, 'What is the annual Payment Amount?'),
    ),
  ),
});

export type EorCompensationFields = z.infer<typeof eorCompensationFields>;

export const eorBenefits = z.object({
  benefits: z
    .string({ invalid_type_error: 'What are the benefits?' })
    .min(1, 'What are the benefits?'),
});

export type EorBenefits = z.infer<typeof eorBenefits>;

export const eorContractCreate = eorEmployeeFields
  .merge(eorJobFields)
  .merge(eorCompensationFields)
  .merge(eorBenefits);

export type EorContractCreate = z.infer<typeof eorContractCreate>;

export const updatePayZod = z.object({
  billingAmountInCents: z.preprocess(
    (val) => parseFloat(val as string),
    z.number().min(1, 'What is the new billing amount?'),
  ),
  billingCurrency: z.string(),
  effectiveDate: z.string().min(1, 'What is the effective date?'),
});

export type UpdatePayType = z.infer<typeof updatePayZod>;

export const adminContractsZod = z.object({
  clientId: z.string().uuid(),
  engagementType: z.enum(['CONTRACTOR', 'EOR', 'PAYROLL']),
  isoCountryCode: z.string().min(1),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email().min(1),
  jobTitle: z.string().min(1),
  seniorityLevel: z.string().min(1),
  scopeOfWork: z.string().min(1),
  startDate: z.string().min(1),
  billingFrequency: z.enum(['HOURLY', 'MONTHLY']),
  billingAmountInCents: z.preprocess(
    (val) => parseFloat(val as string),
    z.number().min(1),
  ),
  distributionCurrency: z.string().min(1),
  distributionFrequency: z.enum(['MONTHLY', 'SEMI_MONTHLY', 'BI_WEEKLY']),
  employmentType: z.string().nullable(),
  contractTerm: z.string().nullable(),
});

export type AdminContractsType = z.infer<typeof adminContractsZod>;

export const offboardContractsZod = z.object({
  effectiveDate: z.string().min(1, 'What is the effective date?'),
});

export type OffboardContractsType = z.infer<typeof offboardContractsZod>;
