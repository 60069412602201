import { z } from 'zod';

export const updateBankInfoZod = z.object({
  number: z.string().min(1, 'Bank account number is required'),
  country: z.string().min(1, 'Bank account country is required'),
  bankName: z.string().min(1, 'Bank name is required'),
  entityType: z.enum(['COMPANY', 'PERSONAL']),
  type: z.enum(['CHECKING', 'SAVINGS']),
  routingNumber: z
    .string()
    .min(1, 'Bank routing number is required')
    .regex(
      /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$|^[0-9]{9}/,
      "This format doesn't look right. A swift code should be 8 or 11 letters or digits where the first six must be letters (no dashes). A routing number should be 9 digits.",
    ),
  ownerName: z.string().min(1, 'Bank account owner name is required'),
  iban: z.string().nullable().optional(),
  externalData: z.record(z.any()).optional(),
});

export type UpdateBankInfoType = z.infer<typeof updateBankInfoZod>;
