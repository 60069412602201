import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';
import { BankAccountPingPongStatus } from './BankAccountPingPongStatus';

export default function bankNumber() {
  const { workerProfileId } = useParams();

  const { data: workerProfile } = trpc.a.bankAccounts.list.useQuery({
    workerProfileId: workerProfileId as string,
  });

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Bank Accounts" />
      <div className="bg-white shadow sm:rounded-lg mx-10 my-10">
        <div className="flex justify-between mx-4 my-4 pt-3 ">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Bank Account Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              You can update bank account and routing number information here!
            </p>
          </div>
        </div>

        <div className="mt-5 px-4 py-4 border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                Employee Name
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{workerProfile?.fullName}</span>
              </dd>
            </div>

            {workerProfile?.bankAccounts.map((account) => (
              <div
                key={account.id}
                className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
              >
                <dt className="text-sm font-medium text-gray-500">
                  {account.ownerName}
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <div className="flex-grow">
                    <div className="flex">
                      <span>
                        <p>Owner Name: {account.ownerName}</p>
                        <p>Account Number: {account.number}</p>
                        <p>Routing: {account.routingNumber}</p>
                        {account.iban && <p>Iban: {account.iban}</p>}
                        <div>
                          PingPong:{' '}
                          <BankAccountPingPongStatus id={account.id} />
                        </div>
                      </span>
                      {account.isDefault && (
                        <span>
                          <CheckBadgeIcon
                            className="ml-2 h-5 w-5 flex-shrink-0 text-green-600 cursor-pointer"
                            aria-hidden="true"
                            aria-label="Default"
                            title="Default"
                          />
                        </span>
                      )}
                    </div>
                  </div>
                  <span className="py-2 px-4 flex-shrink-0">
                    <Link
                      to={`/worker-profiles/${workerProfile?.id}/bank-accounts/${account.id}`}
                    >
                      <button
                        type="button"
                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Update
                      </button>
                    </Link>
                  </span>
                </dd>
              </div>
            ))}
          </dl>
          <div className="mt-1 border-t border-gray-200">
            <dl className="divide-y divide-gray-200">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">{}</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
