import { removeSurrounding } from './strings';

export function arrayOf<T>(...input: (undefined | null | T | T[])[]): T[] {
  return input.reduce<T[]>((result, next) => {
    if (Array.isArray(next)) {
      result.push(...next);
    } else if (next != null) {
      result.push(next);
    }
    return result;
  }, [] as T[]);
}

export function buildUrl(
  path: string | string[],
  params?: Record<string, unknown>,
): string;

export function buildUrl(
  base: string,
  path?: string,
  params?: Record<string, unknown>,
): string;
export function buildUrl(
  p1: string | string[],
  p2?: string | Record<string, unknown>,
  p3?: Record<string, unknown>,
) {
  let base: string[] = [];
  let path: string | undefined;
  let params: Record<string, unknown> | undefined;
  if (typeof p2 === 'string') {
    base = arrayOf(p1);
    path = p2;
    params = p3;
  } else {
    base = arrayOf(p1);
    params = p2;
  }

  let joinedPath = arrayOf(base, path)
    .map((e) => removeSurrounding(e, '/'))
    .join('/');
  const entries = Object.entries(params ?? {});
  if (entries.length) {
    joinedPath += `?${entries
      .map(
        ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(String(v))}`,
      )
      .join('&')}`;
  }
  return joinedPath;
}
