import { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/24/outline';

import { XMarkIcon } from '@heroicons/react/20/solid';
import { useNotification } from './useNotification';

export function Notification() {
  const show = useNotification((state) => state.show);
  const hide = useNotification((state) => state.hide);
  const content = useNotification((state) => state.content);
  const autoDismissMs = useNotification((state) => state.autoDismissMs);
  const iSuccess = content.type === 'success';

  useEffect(() => {
    if (show === false) return;

    const timeout = setTimeout(() => {
      hide();
    }, autoDismissMs);

    return () => clearTimeout(timeout);
  }, [show]);

  return (
    <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
      <Transition
        show={show}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                {iSuccess ? (
                  <CheckCircleIcon
                    style={{ color: '#60f542' }}
                    className="h-6 w-6 text-green-400"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    style={{ color: 'red' }}
                    className="h-6 w-6 text-green-400"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">
                  {content.title}
                </p>
                <p className="mt-1 text-sm text-gray-500">{content.message} </p>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    hide();
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
