import {
  PingPongFoundStatus,
  RecipientAccountStatus,
} from 'listo/src/pingPong.shared-types';
import { mapKeys } from 'lodash';
import { trpc } from '../../lib/trpc';

const pingPongStatuses = mapKeys(
  {
    [PingPongFoundStatus.FOUND]: ['OK', 'green'],
    [RecipientAccountStatus.APPROVED]: ['OK', 'green'],
    [RecipientAccountStatus.AVAILABLE]: ['OK', 'green'],
    [RecipientAccountStatus.APPROVED]: ['OK', 'green'],
    [PingPongFoundStatus.UNKNOWN]: ['Unknown', 'gray'],
    [PingPongFoundStatus.NOT_FOUND]: ['Not set up', 'warning'],
    [PingPongFoundStatus.WRONG_ACCOUNT]: ['Invalid', 'red'],
    [RecipientAccountStatus.PENDING]: ['Pending', 'warning'],
    [RecipientAccountStatus.DECLINED]: ['Declined', 'red'],
  },
  (v, k) => k.toLowerCase(),
);

/**
 * Displays a status chip whether this bank account has been synced with PingPong.
 *
 * This widget will also query PingPong directly for additional status information.
 *
 * @param id The ID of the Listo BankAccount record
 */
export function BankAccountPingPongStatus({ id }: { id: string }) {
  const accountStatus = trpc.a.bankAccounts.getPingPongStatus.useQuery(id);

  if (accountStatus.status === 'success') {
    const statusOrFound =
      accountStatus.data?.status ??
      accountStatus.data?.found ??
      PingPongFoundStatus.UNKNOWN;
    const [label, color] = pingPongStatuses[`${statusOrFound}`.toLowerCase()]!;

    return (
      <div
        className={`inline-flex rounded-full bg-${color}-100 px-2 text-xs font-semibold leading-5 text-${color}-800`}
      >
        {label}
      </div>
    );
  }
  return <span>...</span>;
}
