import Header from '../../components/Header';
import { AdminsTable } from './Table';

export function Admins() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Admins" buttonRoute="/admins/create" buttonText="Create" />
      <div className="m-8 block">
        <AdminsTable />
      </div>
    </div>
  );
}
