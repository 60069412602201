type ButtonProps = {
  id?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  icon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit';
  variant?: string;
};

export function Button({
  id = '',
  onClick,
  text,
  icon,
  loading,
  disabled,
  className,
  type,
  variant = 'primary',
}: ButtonProps) {
  const baseClasses =
    variant === 'secondary'
      ? 'inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white disabled:opacity-25 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
      : 'inline-flex items-center justify-center gap-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 disabled:opacity-25 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
  if (loading)
    return (
      <button
        id={id}
        type={type === 'submit' ? 'submit' : 'button'}
        disabled={disabled}
        className={`${baseClasses} ${className}`}
      >
        <svg className="w-5 h-5 animate-spin" viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </button>
    );

  return (
    <button
      id={id}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled}
      className={`${baseClasses} ${className}`}
      onClick={onClick}
    >
      {icon} {text}
    </button>
  );
}
