import { useEffect, useState } from 'react';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { Input } from 'ui/src/components/Input';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { trpc } from '../../lib/trpc';

export function UpdateConfirmationNumber({
  distributionId,
  confirmationNumber,
}: {
  distributionId: string;
  confirmationNumber?: string;
}) {
  const setNotification = useNotification((state) => state.setNotification);
  const [confirmationNumberState, setConfirmationNumberState] = useState(
    confirmationNumber ?? '',
  );
  useEffect(() => {
    if (confirmationNumber && !confirmationNumberState) {
      setConfirmationNumberState(confirmationNumber);
    }
  }, [confirmationNumber]);

  const utils = trpc.useContext();

  const { mutate: updateNumber, isLoading: sending } =
    trpc.a.distributions.updateConfirmationNumber.useMutation({
      onSuccess: () => {
        utils.a.distributions.distribution.invalidate({ distributionId });

        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Confirmation number updated. Email sent.',
        });
      },
      onError: (e: any) => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: e.toString(),
        });
      },
    });

  return (
    <ActionPanel
      title="Update Confirmation Number"
      content="Updates the confirmation number for the distribution and sends an email to the worker indicating their payment is on the way."
    >
      <div>
        <Input
          label="Confirmation Number"
          inputProps={{
            required: true,
            onChange: (e) => setConfirmationNumberState(e.target.value),
            value: confirmationNumberState,
          }}
        />

        <Button
          className="mt-4"
          disabled={!confirmationNumberState}
          text="Update and Send Email"
          loading={sending}
          onClick={() =>
            updateNumber({
              distributionId,
              confirmationNumber: confirmationNumberState || '',
            })
          }
        />
      </div>
    </ActionPanel>
  );
}
