import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

export function Quickbooks() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const realmId = searchParams.get('realmId');
  const navigate = useNavigate();
  const setNotification = useNotification((s) => s.setNotification);

  const initialState = Math.random().toString(36).slice(2);
  const { mutateAsync: mutate } = trpc.a.quickbooks.authUri.useMutation();

  const { mutateAsync: getAccessToken } =
    trpc.a.quickbooks.accessToken.useMutation({
      onSuccess: () => {
        setNotification({
          type: 'success',
          title: 'Connected to Quickbooks',
          message: 'Listo is now connected to Quickbooks',
        });
        navigate('/quickbooks', { replace: true });
      },
      onError: (error) => {
        setNotification({
          type: 'error',
          title: 'Error Connecting to Quickbooks',
          message: error.message,
        });
        navigate('/quickbooks', { replace: true });
      },
    });

  // fml
  useEffect(() => {
    let ignore = false;

    if (!code || !state || !realmId) {
      return;
    }

    async function getAccessTokenOncePlease() {
      try {
        if (code && !ignore) getAccessToken({ code });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    getAccessTokenOncePlease();

    // eslint-disable-next-line consistent-return
    return () => {
      ignore = true;
    };
  }, [code, state, realmId]);

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Quickbooks" />
      <div className="m-8 flex flex-col">
        <div className="sm:rounded-lg grid grid-cols-3 gap-4">
          <div className="col-span-1 flex flex-col items-center">
            <ActionPanel
              title="Connect To Quickbooks"
              content="click here to connect with quickbooks and maybe solve world hunger"
            >
              <Button
                text="Connect"
                onClick={() => {
                  mutate(initialState).then((res) => {
                    window.location.href = res;
                  });
                }}
              />
            </ActionPanel>
          </div>
        </div>
      </div>
    </div>
  );
}
