import { useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { unionBy } from 'lodash';
import { createColumnHelper } from '@tanstack/react-table';

import { formatDate } from 'listo/src/utils/dates';
import { classNames } from 'listo/src/utils/strings';
import { useDebounce } from 'ui/src/hooks/useDebounce';
import { formatCentsToDollars } from 'listo/src/utils/currency';

import { PaginateContext } from '../../components/Table/Pagination';
import Table from '../../components/Table/Table';
import { RouterOutput, trpc } from '../../lib/trpc';
import { SelectedFilter } from './InvoiceFilters';

function variables({
  searchTerm,
  sort,
  selectedFilters,
  page,
}: {
  searchTerm: string | undefined;
  sort: string;
  selectedFilters: SelectedFilter[];
  page?: number;
}) {
  const statusFilters = selectedFilters
    .filter((filter) => filter.filterName === 'Filters' && !!filter.optionValue)
    .map((filter) => filter.optionValue);

  return {
    page,
    limit: 10,
    searchTerm,
    sort,
    filters: {
      status: statusFilters.length ? statusFilters.join('|') : undefined,
    },
  };
}

type Invoice = RouterOutput['a']['invoices']['list']['invoices'][0];

const columnHelper = createColumnHelper<Invoice>();

function getExternalInvoiceId(invoice: Invoice): string | null {
  if (!invoice.externalInvoiceId && !invoice.stripeInvoiceId) {
    return null;
  }
  switch (invoice.externalProvider) {
    case 'STRIPE':
      return invoice.stripeInvoiceId || invoice.externalInvoiceId;
    case 'QUICKBOOKS':
      return (invoice.externalJson as any)?.Invoice?.DocNumber as string;
    default:
      return null;
  }
}

function ActionButtons({ invoice }: { invoice: Invoice }) {
  const navigate = useNavigate();
  return (
    <>
      <button
        type="button"
        className="text-indigo-600 hover:text-indigo-900"
        onClick={() => {
          if (invoice.externalProvider === 'QUICKBOOKS') {
            window.open(
              `${import.meta.env.VITE_QUICK_BOOKS_APP_URL}/invoice?txnId=${
                invoice.externalInvoiceId
              }`,
              '_blank',
            );
          }
          if (invoice.stripeInvoiceId)
            window.open(
              `${import.meta.env.VITE_STRIPE_APP_URL}/invoices/${
                invoice.stripeInvoiceId
              }`,
              '_blank',
            );
        }}
      >
        View
      </button>
      <button
        type="button"
        className="text-indigo-600 hover:text-indigo-900 ml-4 disabled:opacity-25"
        disabled={invoice.stripeInvoiceStatus !== 'draft'}
        onClick={() => {
          navigate(`/invoices/${invoice.id}/edit`);
        }}
      >
        Edit
      </button>
    </>
  );
}

const columns = [
  columnHelper.display({
    id: 'Invoice Name',
    header: 'Invoice Name',
    cell: (props) =>
      `${props.row.original.client?.name} - ${formatDate(
        props.row.original.createdAt,
      )}`,
  }),
  columnHelper.display({
    id: 'stripeId',
    header: () => <span>Status</span>,
    cell: (props) => (
      <div
        className={classNames(
          props.row.original.stripeInvoiceStatus?.toLowerCase() === 'draft'
            ? 'bg-rose-100 text-rose-800'
            : 'bg-green-100 text-green-800',
          'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
        )}
      >
        <span>{props.row.original.stripeInvoiceStatus || 'unknown'}</span>
      </div>
    ),
  }),

  columnHelper.display({
    id: 'status',
    header: () => <span>Invoice Id</span>,
    cell: (props) => (
      <div className="lg:ml-6 text-sm font-medium text-gray-500">
        <span>{getExternalInvoiceId(props.row.original) || ''}</span>
      </div>
    ),
  }),
  columnHelper.display({
    id: 'date',
    header: () => <span className="sr-only">Date</span>,
    cell: (props) => (
      <div className="lg:ml-6 text-sm font-medium text-gray-500">
        <span>{formatDate(props.row.original.createdAt)}</span>
      </div>
    ),
  }),
  columnHelper.display({
    id: 'currency',
    header: () => <span className="sr-only">Currency</span>,
    cell: (props) => (
      <h1>{formatCentsToDollars(props.row.original.amountInCents, 'USD')}</h1>
    ),
  }),
  columnHelper.display({
    id: 'actions',
    header: () => <span className="sr-only">Actions</span>,
    cell: ({ row }) => <ActionButtons invoice={row.original} />,
  }),
];

const sortOptions = [
  { label: 'Invoice Date (Asc)', value: 'createdAt.asc' },
  { label: 'Invoice Date (Desc)', value: 'createdAt.desc' },
];

function selectedFiltersReducer(
  state: SelectedFilter[],
  action: SelectedFilter,
) {
  const filters = unionBy([action, ...state], 'optionLabel');
  return filters;
}

export function InvoicesTable() {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState('createdAt.desc');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    selectedFiltersReducer,
    [],
  );

  const debouncedSearchTerm = useDebounce<string | undefined>(searchTerm, 275);

  const { data, isLoading, error, refetch } = trpc.a.invoices.list.useQuery(
    variables({ page, searchTerm, sort, selectedFilters }),
  );

  const paginationData = useMemo(
    () => ({
      currentPage: page,
      setPage,
      nextPage: data?.nextPage,
      prevPage: data?.prevPage,
      total: data?.total,
      pageSize: data?.pageSize,
    }),
    [page, data],
  );

  useEffect(() => {
    if (page !== 0) setPage(0);
  }, [selectedFilters]);

  useEffect(() => {
    refetch();
  }, [page, debouncedSearchTerm, sort, selectedFilters]);

  return (
    <PaginateContext.Provider value={paginationData}>
      <Table
        data={data?.invoices || []}
        columns={columns}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        error={error}
        isLoading={isLoading}
        tableType="Invoices"
        tableFilterProps={{
          sort: {
            name: 'Sort',
            options: sortOptions,
          },
          filters: [],
          currentFilters: [],
          setCurrentFilters: dispatchSelectedFilters,
          sortValue: sort,
          setSortValue: setSort,
        }}
      />
    </PaginateContext.Provider>
  );
}
