import { useState, useRef, ChangeEventHandler } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { XMarkIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { DocumentTypes } from '../../lib/constants';
import { trpc } from '../../lib/trpc';

type RequestDocumentForm = {
  documentType: DocumentTypes;
};

export function AddNewDocument({
  clientId,
  setOpenModal,
}: {
  clientId: string;
  setOpenModal: (val: boolean) => void;
  afterSubmit?: () => void;
}) {
  const documentFileInputRef = useRef<HTMLInputElement>(null);
  const [documentFile, setDocumentFile] = useState<File>();
  const utils = trpc.useContext();
  const { id } = useParams();
  const setNotification = useNotification((state) => state.setNotification);

  const contractId = id as string;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RequestDocumentForm>();

  const { mutateAsync: getSignedUrlForUpload } =
    trpc.a.assets.getSignedUrl.useMutation();

  const { mutateAsync: uploadFileSuccess } =
    trpc.a.assets.uploadSuccess.useMutation();

  const { mutateAsync: addDocument } =
    trpc.a.documents.addDocument.useMutation();

  const { mutateAsync: createDocument } = trpc.a.documents.create.useMutation({
    onSuccess: () => {
      utils.a.documents.list.invalidate({
        contractId,
      });
    },
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const fileList = event.target.files;

    if (fileList) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const selectedFile = fileList[0]!;
      setDocumentFile(selectedFile);
    }
  };

  const removeUpload = () => {
    setDocumentFile(undefined);

    if (documentFileInputRef.current) {
      documentFileInputRef.current.value = '';
    }
  };

  const uploadDocument = async (selectedFile: File) => {
    try {
      const fileName = selectedFile.name;
      const mimeType = selectedFile.type;

      if (mimeType && fileName) {
        const { signedUrl, cloudStorageKey } = await getSignedUrlForUpload({
          listoAssetType: 'documents',
          mimeType,
          clientId,
        });

        await axios.put(signedUrl, selectedFile, {
          headers: {
            'Content-Type': selectedFile.type,
          },
        });

        const file = await uploadFileSuccess({
          clientId,
          fileName,
          mimeType,
          cloudStorageKey,
        });

        return file.id;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Something went wrong',
      });
    } finally {
      removeUpload();
    }

    return '';
  };

  const onSubmit: SubmitHandler<RequestDocumentForm> = async (data) => {
    try {
      const document = await createDocument({ ...data, contractId });
      if (documentFile) {
        const uploadId = (await uploadDocument(documentFile)) as string;

        await addDocument({ documentId: document.id, uploadId });

        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Document has been submitted.',
        });
      } else {
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Document request has been sent.',
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
      if (e instanceof Error) {
        setNotification({
          type: 'error',
          title: 'Error',
          message: e.message || 'something went wrong',
        });
      }
    } finally {
      setOpenModal(false);
    }
  };

  return (
    <div className="w-full md:min-w-[500px] p-4 sm:p-6">
      <h2 className="font-semibold text-xl text-gray-900 mb-6">
        Add New Document
      </h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-y-8">
          <div className="sm:col-span-1">
            <label
              htmlFor="documentType"
              className="block text-sm text-gray-700 font-medium"
            >
              Document Type *
            </label>

            <div className="grid grid-cols-1 gap-x-4 mt-2">
              <select
                id="documentType"
                {...register('documentType', {
                  required: 'This field required.',
                })}
                defaultValue=""
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option disabled hidden value="">
                  New Document
                </option>

                {Object.values(DocumentTypes).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {errors.documentType?.message ? (
              <p className="mt-2 text-sm text-red-600">
                {errors.documentType.message}
              </p>
            ) : null}
          </div>

          <div className="sm:col-span-1">
            <label
              htmlFor="document"
              className="block text-sm text-gray-700 font-medium"
            >
              Upload *
            </label>

            <div className="grid grid-cols-1 gap-x-4 mt-2">
              {documentFile ? (
                <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                  <div className="space-y-4 text-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />

                    <div className="flex gap-2">
                      <h4 className="text-gray-500 text-xs font-light">
                        {documentFile.name}
                      </h4>

                      <button
                        type="button"
                        onClick={removeUpload}
                        title="Remove"
                        className="text-red-700 hover:text-red-500 transition focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-0"
                      >
                        <XMarkIcon className="h-4" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <label
                  htmlFor="document"
                  className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-0 cursor-pointer"
                >
                  <div className="space-y-1 flex flex-col items-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />

                    <div className="flex text-sm text-gray-600">
                      <input
                        id="document"
                        type="file"
                        ref={documentFileInputRef}
                        accept="image/*,application/pdf"
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        onChange={handleChange}
                        className="sr-only"
                      />
                      <h4 className="text-indigo-600 font-bold">
                        Upload document
                      </h4>
                    </div>

                    <p className="text-xs text-gray-500">
                      PDF, PNG, JPG or any images
                    </p>
                  </div>
                </label>
              )}
            </div>
          </div>

          <div className="pt-2">
            <div className="flex justify-end gap-2">
              <Button
                type="button"
                variant="secondary"
                onClick={() => setOpenModal(false)}
                text="Cancel"
              />

              <Button
                type="submit"
                loading={isSubmitting}
                text={documentFile ? 'Submit' : 'Send Request'}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
