import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'listo/src/utils/dates';
import { titleCase } from 'listo/src/utils/strings';
import { unionBy } from 'lodash';
import { useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Table from '../../components/Table/Table';
import { RouterOutput, trpc } from '../../lib/trpc';
import { SelectedFilter } from './payPeriodFilter';

function selectedFiltersReducer(
  state: SelectedFilter[],
  action: SelectedFilter,
) {
  const filters = unionBy([action, ...state], 'optionLabel');
  return filters;
}

function variables({
  sort,
  selectedFilters,
}: {
  sort: string;
  selectedFilters: SelectedFilter[];
}) {
  return {
    sort,
    filters: {
      status:
        selectedFilters
          .filter((data) => data?.optionValue)
          .map((data) => data?.optionValue) ?? [],
    },
  };
}

type PayPeriod = RouterOutput['a']['payPeriods']['list'][0];

const columnHelper = createColumnHelper<PayPeriod>();

const columns = [
  columnHelper.accessor('client.name', {
    header: `Client`,
  }),
  columnHelper.display({
    id: 'period',
    header: 'Period',
    cell: ({ row }) =>
      `${formatDate(row.original.periodStart)} - ${formatDate(
        row.original.periodEnd,
      )}`,
  }),

  columnHelper.display({
    id: 'status',
    header: 'Status',
    cell: ({ row }) => (
      <div className="flex items-center space-x-3 lg:pl-2">
        {titleCase(row.original.status)}
      </div>
    ),
  }),
  columnHelper.display({
    id: 'action',
    header: () => <span className="sr-only">Actions</span>,
    cell: ({ row }) => (
      <Link
        to={`/pay-periods/${row.original.id}`}
        className="text-indigo-600 hover:text-indigo-900"
      >
        View
      </Link>
    ),
  }),
];

const sortOptions = [
  { label: 'Asc periodStart (a-z)', value: 'periodStart.asc' },
  { label: 'Asc periodStart (z-a)', value: 'periodStart.desc' },
];

const filterOptions = [
  {
    name: 'Status',
    options: [
      { label: 'scheduled', value: 'SCHEDULED' },
      { label: 'pending_approval', value: 'PENDING_APPROVAL' },
      { label: 'approved', value: 'APPROVED' },
      { label: 'invoiced', value: 'INVOICED' },
      { label: 'distributing', value: 'DISTRIBUTING' },
      { label: 'complete', value: 'COMPLETE' },
    ],
  },
];

export function PayPeriods() {
  const [sort, setSort] = useState('periodStart.desc');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    selectedFiltersReducer,
    [],
  );
  const {
    data: payPeriods,
    isLoading,
    error,
  } = trpc.a.payPeriods.list.useQuery(variables({ sort, selectedFilters }));

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header
        title="Pay Periods"
        buttonRoute="/pay-periods/create"
        buttonText="Create"
      />
      <div className="m-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <Table
              data={payPeriods || []}
              columns={columns}
              error={error}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              isLoading={isLoading}
              tableType="PayPeriods"
              tableFilterProps={{
                sort: {
                  name: 'Sort',
                  options: sortOptions,
                },
                filters: filterOptions,
                currentFilters: selectedFilters,
                setCurrentFilters: dispatchSelectedFilters,
                sortValue: sort,
                setSortValue: setSort,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
