import {
  FieldErrors,
  FieldValues,
  Path,
  UnPackAsyncDefaultValues,
  UseFormRegister,
} from 'react-hook-form';
import { InfoBubble } from '../InfoBubble';

function Loading() {
  return (
    <div className="h-[36px]">
      <div className="w-full">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-200 rounded" />
          </div>
        </div>
      </div>
    </div>
  );
}

type Option = {
  label: string;
  value: string;
};

function selectDefault({
  defaultOption,
  defaultValue,
}: {
  defaultOption?: Option;
  defaultValue?: string;
}) {
  if (defaultValue) return defaultValue;
  if (defaultOption) return defaultOption.value;
  return undefined;
}

function Data<T extends FieldValues>({
  options,
  defaultOption,
  defaultValue,
  disabled,
  reactHookForm,
  selectProps,
}: {
  defaultOption?: Option;
  options: Option[];
  defaultValue?: string;
  disabled: boolean;
  selectProps?: React.SelectHTMLAttributes<HTMLSelectElement>;
  reactHookForm?: {
    register: UseFormRegister<T>;
    fieldName: Path<UnPackAsyncDefaultValues<T>>;
    errors: FieldErrors<T>;
  };
}) {
  return (
    <select
      disabled={disabled}
      defaultValue={selectDefault({ defaultValue, defaultOption })}
      {...reactHookForm?.register(reactHookForm?.fieldName)}
      {...selectProps}
      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
    >
      {defaultOption && (
        <option disabled hidden value={defaultOption.value}>
          {defaultOption.label}
        </option>
      )}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export function SelectInput<T extends FieldValues>({
  error,
  label,
  selectProps,
  loading = false,
  options,
  defaultValue,
  defaultOption,
  disabled = false,
  reactHookForm,
  infoTooltipId,
  infoTooltipContent,
}: {
  label?: string;
  selectProps?: React.SelectHTMLAttributes<HTMLSelectElement>;
  loading?: boolean;
  options: { label: string; value: string }[];
  defaultValue?: string;
  defaultOption?: { label: string; value: string } | undefined;
  disabled?: boolean;
  error?: string;
  reactHookForm?: {
    register: UseFormRegister<T>;
    fieldName: Path<UnPackAsyncDefaultValues<T>>;
    errors: FieldErrors<T>;
  };
  infoTooltipId?: string;
  infoTooltipContent?: string;
}) {
  const reactHookError = reactHookForm?.errors[reactHookForm?.fieldName]
    ?.message as string | undefined;

  return (
    <div>
      <label
        htmlFor="country"
        className="block text-sm font-medium text-gray-700"
      >
        {label}{' '}
        {infoTooltipId && infoTooltipContent && (
          <InfoBubble
            tooltipId={infoTooltipId}
            tooltipContent={infoTooltipContent}
          />
        )}
      </label>
      <div className="mt-1">
        {!loading ? (
          <Data
            defaultValue={defaultValue}
            defaultOption={defaultOption}
            reactHookForm={reactHookForm}
            options={options}
            disabled={disabled}
            selectProps={selectProps}
          />
        ) : (
          <Loading />
        )}

        {error || reactHookError ? (
          <p
            className="mt-2 text-sm text-red-600"
            id={`${label?.toLowerCase()}-error`}
          >
            {error || reactHookError}
          </p>
        ) : null}
      </div>
    </div>
  );
}
