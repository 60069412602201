import { Link } from 'react-router-dom';
import Header from '../../components/Header';

export function Integrations() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Integrations" />

      <div className="m-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th>Name</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-6 py-3 whitespace-nowrap left  font-medium">
                        Quickbooks
                      </td>
                      <td>
                        <Link
                          to="/quickbooks"
                          className="text-indigo-600 hover:text-indigo-900 ml-4"
                        >
                          Go
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 whitespace-nowrap left font-medium">
                        PingPong
                      </td>
                      <td>
                        <Link
                          to="/ping-pong"
                          className="text-indigo-600 hover:text-indigo-900 ml-4"
                        >
                          Go
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
