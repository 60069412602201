import { formatCentsToDollars } from 'listo/src/utils/currency';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

export function Mrr() {
  const { data: pricingData, isLoading, error } = trpc.a.clients.mrr.useQuery();

  if (isLoading) return <Loader />;
  if (error) return <div>Error</div>;
  if (pricingData === undefined) return <Loader />;

  return (
    <div>
      <div className="ml-8 flex">
        <div>MRR</div>
        <div>{formatCentsToDollars(pricingData.mrr, 'USD')}</div>
      </div>
      <div className="m-8 block">
        <div className=" overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Client Name
                </th>

                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  # of Payroll
                </th>

                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  # of Eors
                </th>

                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  # of Contractors
                </th>

                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Mrr
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {pricingData.clients.map((client) => (
                <tr key={client.clientId}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div className="flex items-center space-x-3 lg:pl-2">
                      {client.clientName}
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div className="flex items-center space-x-3 lg:pl-2">
                      {client.numberOfPayrolls}
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div className="flex items-center space-x-3 lg:pl-2">
                      {client.numberOfEors}
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div className="flex items-center space-x-3 lg:pl-2">
                      {client.numberOfContractors}
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div className="flex items-center space-x-3 lg:pl-2">
                      {formatCentsToDollars(client.totalFees, 'USD')}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
