import { formatCurrency } from 'listo/src/utils/currency';
import { round } from 'lodash';
import { HTMLProps } from 'react';

/**
 * Renders a billing period short name, eg. 'ANNUALLY' -> 'year'
 */
export function Period({ value }: { value?: string }) {
  switch (value) {
    case 'ANNUALLY':
      return <>year</>;
    case 'HOURLY':
      return <>hr</>;
    default:
      return <>month</>;
  }
}

/**
 * Renders currency value.  Provides a convenient flag when the value is in cents.
 */
export function Currency({
  value = 0,
  currencyCode,
  isCents = false,
  ...props
}: {
  currencyCode?: string | null;
  value: number;
  isCents?: boolean;
} & HTMLProps<any>) {
  if (currencyCode == null) return null;
  const dollars = isCents ? round(value / 100, 2) : value;
  return <span {...props}>{formatCurrency(dollars, currencyCode)}</span>;
}

/**
 * Renders a billing cycle block, eg. `COP1,232.12/month`
 */
export function BillingCycle({
  currency,
  isCents = false,
  frequency,
  amount = 0,
}: {
  currency?: string;
  isCents: boolean;
  amount?: number;
  frequency: string;
}) {
  return (
    <>
      <Currency
        currencyCode={currency ?? 'USD'}
        value={amount}
        isCents={isCents}
      />
      /
      <Period value={frequency} />{' '}
    </>
  );
}
