import { ReactElement } from 'react';
import { SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import { Footer, FooterProps } from './Footer';

export function Form({
  children,
  handleSubmit,
  onSubmit,
  primaryButtonText,
  secondaryButtonText,
  secondaryButtonOnClick,
  readOnly,
  loading,
}: {
  children: ReactElement;
  handleSubmit: UseFormHandleSubmit<any>;
  onSubmit: SubmitHandler<any>;
  readOnly?: boolean;
  loading?: boolean;
} & FooterProps) {
  return (
    <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
      {children}
      <Footer
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        secondaryButtonOnClick={secondaryButtonOnClick}
        readOnly={readOnly}
        loading={loading}
      />
    </form>
  );
}
