/* eslint-disable react/jsx-props-no-spreading */
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  centsToDollars,
  floatInDollarsToCents,
} from 'listo/src/utils/currency';
import { stringFromDateOrString } from 'listo/src/utils/dates';
import { titleCase } from 'listo/src/utils/strings';
import {
  EngagementTypeEnum,
  eorContractEdit,
  EorContractEdit,
} from 'listo/src/zodObjects/adminContracts';
import { countries, currencySelectOptions } from 'listo/src/countries';

import { Input } from 'ui/src/components/Input';
import { SelectInput } from 'ui/src/components/SelectInput';

import {
  BillingFrequencyEnum,
  DistributionMethod,
  DistributionMethodEnum,
} from 'listo/src/zodObjects/contracts';
import { Alert, buildErrors } from 'ui/src/components/Alert';
import { Button } from 'ui/src/components/Button';
import { ReactSelectInput } from 'ui/src/components/ReactSelectInput';
import { CurrencyInput } from 'ui/src/components/Currency/CurrencyInput';
import { DateInput } from 'ui/src/components/DateInput';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

function EditContract() {
  const { id } = useParams();
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const {
    handleSubmit,
    formState: { errors },
    ...contractForm
  } = useForm<EorContractEdit>({
    resolver: zodResolver(eorContractEdit),
  });

  const {
    data: contractInfo,
    isLoading,
    error,
  } = trpc.a.contracts.contract.useQuery(id!);

  const { data: clients, isLoading: clientsLoading } =
    trpc.a.clients.list.useQuery({});

  const updateContract = trpc.a.contracts.eorUpdate.useMutation({
    onSuccess: () => {
      utils.a.contracts.contract.invalidate(id as string).then(() => {
        navigate('/contracts');
      });
    },
  });

  const onSubmit: SubmitHandler<EorContractEdit> = (data) => {
    updateContract.mutate({
      contractData: {
        ...data,
        billingAmountInCents: floatInDollarsToCents(data.billingAmountInCents),
      },
      contractId: id!,
    });
  };

  if (isLoading) return <Loader />;

  if (error || !id) return <div>Error</div>;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Edit Contract" />
      <div className="min-w-full py-2 align-middle md:px-6 lg:px-8 my-8">
        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 mb-8">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="flex">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Worker:
              </h3>
              <Link to="#">
                <h3 className="text-lg font-medium leading-6 text-indigo-600 ml-4">
                  {titleCase(contractInfo.workerProfile.fullName || '')}
                </h3>
              </Link>
            </div>
          </div>
        </div>

        {Object.keys(errors).length > 0 ? (
          <div className="mb-8">
            <Alert
              heading="There are errors with your submission"
              items={buildErrors(Object.keys(errors), errors)}
            />
          </div>
        ) : null}

        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Contract Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Edit contract info.
                </p>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Client"
                      selectProps={{
                        defaultValue: contractInfo?.clientId,
                      }}
                      defaultOption={
                        { label: 'Select Client', value: '' } as const
                      }
                      loading={clientsLoading}
                      options={
                        clients?.map((client) => ({
                          label: client.name,
                          value: client.id,
                        })) || []
                      }
                      reactHookForm={{
                        ...contractForm,
                        fieldName: 'clientId',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Engagement Type"
                      selectProps={{
                        defaultValue: contractInfo?.engagementType,
                      }}
                      options={EngagementTypeEnum.options.map((value) => ({
                        value,
                        label: value,
                      }))}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <DateInput
                      label="Start Date"
                      inputProps={{
                        defaultValue: stringFromDateOrString(
                          contractInfo?.startDate || new Date(),
                        )?.split('T')?.[0],
                      }}
                      reactHookForm={{
                        ...contractForm,
                        fieldName: 'startDate',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Distribution Frequency"
                      selectProps={{
                        defaultValue: contractInfo?.distributionFrequency,
                      }}
                      options={[
                        { value: 'SEMI_MONTHLY', label: 'Semi Monthly' },
                        { value: 'MONTHLY', label: 'Monthly' },
                        { value: 'BI_WEEKLY', label: 'Bi-Weekly' },
                      ]}
                      reactHookForm={{
                        ...contractForm,
                        fieldName: 'distributionFrequency',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <ReactSelectInput
                      label="Distribution Currency"
                      options={currencySelectOptions}
                      defaultValue={contractInfo?.distributionCurrency ?? 'USD'}
                      fieldName="distributionCurrency"
                      control={contractForm.control}
                      errors={errors}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Distribution Method"
                      options={DistributionMethodEnum.options.map((value) => ({
                        value,
                        label: value,
                      }))}
                      selectProps={{
                        defaultValue:
                          (contractInfo?.distributionMethod as DistributionMethod) ??
                          'PING_PONG',
                      }}
                      reactHookForm={{
                        ...contractForm,
                        fieldName: 'distributionMethod',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <CurrencyInput
                      {...contractForm}
                      currencyField="billingCurrency"
                      amountField="billingAmountInCents"
                      errors={errors}
                      label="Contract Amount"
                      defaultValues={{
                        currency: contractInfo?.billingCurrency,
                        amount: centsToDollars(
                          contractInfo?.billingAmountInCents,
                        ),
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Billing Frequency"
                      selectProps={{
                        defaultValue: contractInfo?.billingFrequency,
                      }}
                      options={BillingFrequencyEnum.options.map((value) => ({
                        value,
                        label: value,
                      }))}
                      reactHookForm={{
                        ...contractForm,
                        fieldName: 'billingFrequency',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Country"
                      defaultValue={contractInfo?.isoCountryCode ?? ''}
                      defaultOption={{ label: 'Select', value: '' }}
                      options={countries.map((c) => ({
                        label: c.name,
                        value: c.alpha2,
                      }))}
                      reactHookForm={{
                        ...contractForm,
                        fieldName: 'isoCountryCode',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Job title"
                      inputType="text"
                      inputProps={{
                        defaultValue: contractInfo?.jobTitle,
                      }}
                      reactHookForm={{
                        ...contractForm,
                        fieldName: 'jobTitle',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Seniority Level"
                      inputType="text"
                      inputProps={{
                        defaultValue: contractInfo?.seniorityLevel,
                      }}
                      reactHookForm={{
                        ...contractForm,
                        fieldName: 'seniorityLevel',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Scope of Work"
                      inputType="text"
                      inputProps={{
                        defaultValue: contractInfo?.scopeOfWork,
                      }}
                      reactHookForm={{
                        ...contractForm,
                        fieldName: 'scopeOfWork',
                        errors,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              type="button"
              text="Cancel"
              variant="secondary"
              onClick={() => navigate(-1)}
            />

            <Button type="submit" text="Save" className="ml-3" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditContract;
