import { uniqBy } from 'lodash';

export const countries = [
  {
    name: 'Albania',
    alpha2: 'AL',
    alpha3: 'ALB',
    currencyCode: 'ALL',
    currencyName: 'Albanian Lek',
  },
  {
    name: 'Algeria',
    alpha2: 'DZ',
    alpha3: 'DZA',
    currencyCode: 'DZD',
    currencyName: 'Algerian Dinar',
  },
  {
    name: 'Angola',
    alpha2: 'AO',
    alpha3: 'AGO',
    currencyCode: 'AOA',
    currencyName: 'Angolan Kwanza',
  },
  {
    name: 'Argentina',
    alpha2: 'AR',
    alpha3: 'ARG',
    currencyCode: 'ARS',
    currencyName: 'Argentine Peso',
  },
  {
    name: 'Armenia',
    alpha2: 'AM',
    alpha3: 'ARM',
    currencyCode: 'AMD',
    currencyName: 'Armenian Dram',
  },
  {
    name: 'Australia',
    alpha2: 'AU',
    alpha3: 'AUS',
    currencyCode: 'AUD',
    currencyName: 'Australian Dollar',
  },
  {
    name: 'Austria',
    alpha2: 'AT',
    alpha3: 'AUT',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Azerbaijan',
    alpha2: 'AZ',
    alpha3: 'AZE',
    currencyCode: 'AZN',
    currencyName: 'Azerbaijani Manat',
  },
  {
    name: 'Bahamas',
    alpha2: 'BS',
    alpha3: 'BHS',
    currencyCode: 'BSD',
    currencyName: 'Bahamian Dollar',
  },
  {
    name: 'Bahrain',
    alpha2: 'BH',
    alpha3: 'BHR',
    currencyCode: 'BHD',
    currencyName: 'Bahraini Dinar',
  },
  {
    name: 'Bangladesh',
    alpha2: 'BD',
    alpha3: 'BGD',
    currencyCode: 'BDT',
    currencyName: 'Bangladeshi Taka',
  },
  {
    name: 'Belarus',
    alpha2: 'BY',
    alpha3: 'BLR',
    currencyCode: 'BYN',
    currencyName: 'Belarusian Ruble',
  },
  {
    name: 'Belgium',
    alpha2: 'BE',
    alpha3: 'BEL',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Belize',
    alpha2: 'BZ',
    alpha3: 'BLZ',
    currencyCode: 'BZD',
    currencyName: 'Belize Dollar',
  },
  {
    name: 'Bolivia',
    alpha2: 'BO',
    alpha3: 'BOL',
    currencyCode: 'BOB',
    currencyName: 'Boliviano',
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    alpha3: 'BIH',
    currencyCode: 'BAM',
    currencyName: 'Bosnia-Herzegovina Convertible Marka',
  },
  {
    name: 'Brazil',
    alpha2: 'BR',
    alpha3: 'BRA',
    currencyCode: 'BRL',
    currencyName: 'Brazilian Real',
  },
  {
    name: 'British Virgin Island',
    alpha2: 'VG',
    alpha3: 'VGB',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
  },
  {
    name: 'Bulgaria',
    alpha2: 'BG',
    alpha3: 'BGR',
    currencyCode: 'BGN',
    currencyName: 'Bulgarian Lev',
  },
  {
    name: 'Burkina Faso',
    alpha2: 'BF',
    alpha3: 'BFA',
    currencyCode: 'XOF',
    currencyName: 'West African CFA Franc',
  },
  {
    name: 'Cambodia',
    alpha2: 'KH',
    alpha3: 'KHM',
    currencyCode: 'KHR',
    currencyName: 'Cambodian Riel',
  },
  {
    name: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
    currencyCode: 'CAD',
    currencyName: 'Canadian Dollar ',
  },
  {
    name: 'Chile',
    alpha2: 'CL',
    alpha3: 'CHL',
    currencyCode: 'CLP',
    currencyName: 'Chilean Peso',
  },
  {
    name: 'China',
    alpha2: 'CN',
    alpha3: 'CHN',
    currencyCode: 'CNY',
    currencyName: 'Chinese Yuan ',
  },
  {
    name: 'Chad',
    alpha2: 'TD',
    alpha3: 'TCD',
    currencyCode: 'XAF',
    currencyName: 'Central African CFA franc',
  },
  {
    name: 'Colombia',
    alpha2: 'CO',
    alpha3: 'COL',
    currencyCode: 'COP',
    currencyName: 'Colombian Peso ',
  },
  {
    name: 'Costa Rica',
    alpha2: 'CR',
    alpha3: 'CRI',
    currencyCode: 'CRC',
    currencyName: 'Costa Rican Colon',
  },
  {
    name: 'Croatia',
    alpha2: 'HR',
    alpha3: 'HRV',
    currencyCode: 'HRK',
    currencyName: 'Kuna',
  },
  {
    name: 'Cyprus',
    alpha2: 'CY',
    alpha3: 'CYP',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Czech Republic',
    alpha2: 'CZ',
    alpha3: 'CZE',
    currencyCode: 'CZK',
    currencyName: 'Czech Koruna',
  },
  {
    name: 'Denmark',
    alpha2: 'DK',
    alpha3: 'DNK',
    currencyCode: 'DKK',
    currencyName: 'Danish Krone ',
  },
  {
    name: 'Dominican Republic',
    alpha2: 'DO',
    alpha3: 'DOM',
    currencyCode: 'DOP',
    currencyName: 'Dominican Peso',
  },
  {
    name: 'Ecuador',
    alpha2: 'EC',
    alpha3: 'ECU',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
  },
  {
    name: 'Egypt',
    alpha2: 'EG',
    alpha3: 'EGY',
    currencyCode: 'EGP',
    currencyName: 'Egyptian Pound',
  },
  {
    name: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
  },
  {
    name: 'Estonia',
    alpha2: 'EE',
    alpha3: 'EST',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Ethiopia',
    alpha2: 'ET',
    alpha3: 'ETH',
    currencyCode: 'ETB',
    currencyName: 'Ehtiopian Birr',
  },
  {
    name: 'Finland',
    alpha2: 'FI',
    alpha3: 'FIN',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'France',
    alpha2: 'FR',
    alpha3: 'FRA',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Georgia',
    alpha2: 'GE',
    alpha3: 'GEO',
    currencyCode: ' GEL',
    currencyName: 'Georgian Lari ',
  },
  {
    name: 'Germany',
    alpha2: 'DE',
    alpha3: 'DEU',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Ghana',
    alpha2: 'GH',
    alpha3: 'GHA',
    currencyCode: 'GHS',
    currencyName: 'Ghanaian Cedi',
  },
  {
    name: 'Greece',
    alpha2: 'GR',
    alpha3: 'GRC',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Guatemala',
    alpha2: 'GT',
    alpha3: 'GTM',
    currencyCode: 'GTQ',
    currencyName: 'Quetzal',
  },
  {
    name: 'Honduras',
    alpha2: 'HN',
    alpha3: 'HND',
    currencyCode: 'HNL',
    currencyName: 'Lempira',
  },
  {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    currencyCode: 'HKD',
    currencyName: 'Hong Kong Dollar',
  },
  {
    name: 'Hungary',
    alpha2: 'HU',
    alpha3: 'HUN',
    currencyCode: 'HUF',
    currencyName: 'Forint',
  },
  {
    name: 'India',
    alpha2: 'IN',
    alpha3: 'IND',
    currencyCode: 'INR',
    currencyName: 'Indian Rupee',
  },
  {
    name: 'Indonesia',
    alpha2: 'ID',
    alpha3: 'IDN',
    currencyCode: 'IDR',
    currencyName: 'Indonesian Rupiah ',
  },
  {
    name: 'Ireland',
    alpha2: 'IE',
    alpha3: 'IRL',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Israel',
    alpha2: 'IL',
    alpha3: 'ISR',
    currencyCode: 'ILS',
    currencyName: 'Israeli Shekel',
  },
  {
    name: 'Italy',
    alpha2: 'IT',
    alpha3: 'ITA',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Ivory Coast',
    alpha2: 'CI',
    alpha3: 'CIV',
    currencyCode: 'XOF',
    currencyName: 'West African CFA Franc',
  },
  {
    name: 'Jamaica',
    alpha2: 'JM',
    alpha3: 'JAM',
    currencyCode: 'JMD',
    currencyName: 'Jamaican Dollar',
  },
  {
    name: 'Japan',
    alpha2: 'JP',
    alpha3: 'JPN',
    currencyCode: 'JPY',
    currencyName: 'Yen',
  },
  {
    name: 'Kazakhstan',
    alpha2: 'KZ',
    alpha3: 'KAZ',
    currencyCode: 'KZT',
    currencyName: 'Kazakhstani Tenge',
  },
  {
    name: 'Kenya',
    alpha2: 'KE',
    alpha3: 'KEN',
    currencyCode: 'KES',
    currencyName: 'Kenyan Shilling',
  },
  {
    name: 'Kuwait',
    alpha2: 'KW',
    alpha3: 'KWT',
    currencyCode: 'KWD',
    currencyName: 'Kuwaiti Dinar',
  },
  {
    name: 'Latvia',
    alpha2: 'LV',
    alpha3: 'LVA',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Libya',
    alpha2: 'LY',
    alpha3: 'LBY',
    currencyCode: 'LYD',
    currencyName: 'Libyan Dinar',
  },
  {
    name: 'Lithuania',
    alpha2: 'LT',
    alpha3: 'LTU',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Luxembourg',
    alpha2: 'LU',
    alpha3: 'LUX',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Macau',
    alpha2: 'MO',
    alpha3: 'MAC',
    currencyCode: 'MOP',
    currencyName: 'Macanese Pataca',
  },
  {
    name: 'Macedonia',
    alpha2: 'MK',
    alpha3: 'MKD',
    currencyCode: 'MKD',
    currencyName: 'Macedonian Denar',
  },
  {
    name: 'Malawi',
    alpha2: 'MW',
    alpha3: 'MWI',
    currencyCode: 'MWK',
    currencyName: 'Malawian Kwacha',
  },
  {
    name: 'Malaysia',
    alpha2: 'MY',
    alpha3: 'MYS',
    currencyCode: 'MYR',
    currencyName: 'Malaysian Ringgit',
  },
  {
    name: 'Malta',
    alpha2: 'MT',
    alpha3: 'MLT',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Mauritius',
    alpha2: 'MU',
    alpha3: 'MUS',
    currencyCode: 'MUR',
    currencyName: 'Mauritiann Rupee',
  },
  {
    name: 'Mexico',
    alpha2: 'MX',
    alpha3: 'MEX',
    currencyCode: 'MXN',
    currencyName: 'Mexican Peso',
  },
  {
    name: 'Moldova',
    alpha2: 'MD',
    alpha3: 'MDA',
    currencyCode: 'MDL',
    currencyName: 'Moldovan Leu ',
  },
  {
    name: 'Mongolia',
    alpha2: 'MN',
    alpha3: 'MNG',
    currencyCode: 'MNT',
    currencyName: 'Mongolian Togrog or Turgik',
  },
  {
    name: 'Montenegro',
    alpha2: 'ME',
    alpha3: 'MNE',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Morocco',
    alpha2: 'MA',
    alpha3: 'MAR',
    currencyCode: 'MAD',
    currencyName: 'Moroccan Dirham',
  },
  {
    name: 'Myanmar',
    alpha2: 'MM',
    alpha3: 'MMR',
    currencyCode: 'MMK',
    currencyName: 'Burmese Kyat',
  },
  {
    name: 'Nepal',
    alpha2: 'NP',
    alpha3: 'NPL',
    currencyCode: 'NPR',
    currencyName: 'Nepalese Rupee',
  },
  {
    name: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
    currencyCode: 'NZD',
    currencyName: 'New Zealand Dollar',
  },
  {
    name: 'Nicaragua',
    alpha2: 'NI',
    alpha3: 'NIC',
    currencyCode: 'NIO',
    currencyName: 'Nicaraguan Cordoba',
  },
  {
    name: 'Niger',
    alpha2: 'NE',
    alpha3: 'NER',
    currencyCode: 'XOF',
    currencyName: 'West African CFA Franc',
  },
  {
    name: 'Nigeria',
    alpha2: 'NG',
    alpha3: 'NGA',
    currencyCode: 'NGN',
    currencyName: 'Nigerian Naira',
  },
  {
    name: 'Norway',
    alpha2: 'NO',
    alpha3: 'NOR',
    currencyCode: 'NOK',
    currencyName: 'Norwegian Kronar',
  },
  {
    name: 'Oman',
    alpha2: 'OM',
    alpha3: 'OMN',
    currencyCode: 'OMR',
    currencyName: 'Omani Rial',
  },
  {
    name: 'Pakistan',
    alpha2: 'PK',
    alpha3: 'PAK',
    currencyCode: 'PKR',
    currencyName: 'Pakistan Rupee',
  },
  {
    name: 'Panama',
    alpha2: 'PA',
    alpha3: 'PAN',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
  },
  {
    name: 'Paraguay',
    alpha2: 'PY',
    alpha3: 'PRY',
    currencyCode: 'PYG',
    currencyName: 'Paraguayan Guarani',
  },
  {
    name: 'Peru',
    alpha2: 'PE',
    alpha3: 'PER',
    currencyCode: 'PEN',
    currencyName: 'Peruvian Sol',
  },
  {
    name: 'Philippines',
    alpha2: 'PH',
    alpha3: 'PHL',
    currencyCode: 'PHP',
    currencyName: 'Philippine Peso',
  },
  {
    name: 'Poland',
    alpha2: 'PL',
    alpha3: 'POL',
    currencyCode: 'PLN',
    currencyName: 'Polish Zloty',
  },
  {
    name: 'Portugal',
    alpha2: 'PT',
    alpha3: 'PRT',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Puerto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
  },
  {
    name: 'Qatar',
    alpha2: 'QA',
    alpha3: 'QAT',
    currencyCode: 'QAR',
    currencyName: 'Qatari Riyal',
  },
  {
    name: 'Romania',
    alpha2: 'RO',
    alpha3: 'ROU',
    currencyCode: 'RON',
    currencyName: 'Romanian Leu',
  },
  {
    name: 'Russia',
    alpha2: 'RU',
    alpha3: 'RUS',
    currencyCode: 'RUB',
    currencyName: 'Russian Ruble',
  },
  {
    name: 'Rwanda',
    alpha2: 'RW',
    alpha3: 'RWA',
    currencyCode: 'RWF',
    currencyName: 'Rwandan Franc',
  },
  {
    name: 'Senegal',
    alpha2: 'SN',
    alpha3: 'SEN',
    currencyCode: 'XOF',
    currencyName: 'West African CFA Franc',
  },
  {
    name: 'Serbia',
    alpha2: 'RS',
    alpha3: 'SRB',
    currencyCode: 'RSD',
    currencyName: 'Serbian Dinar',
  },
  {
    name: 'Singapore',
    alpha2: 'SG',
    alpha3: 'SGP',
    currencyCode: 'SGD',
    currencyName: 'Singapore Dollar',
  },
  {
    name: 'Slovakia',
    alpha2: 'SK',
    alpha3: 'SVK',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Slovenia',
    alpha2: 'SI',
    alpha3: 'SVN',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
    currencyCode: 'ZAR',
    currencyName: 'South African Rand',
  },
  {
    name: 'South Korea',
    alpha2: 'KR',
    alpha3: 'KOR',
    currencyCode: 'KRW',
    currencyName: 'South Korean Won',
  },
  {
    name: 'Spain',
    alpha2: 'ES',
    alpha3: 'ESP',
    currencyCode: 'EUR',
    currencyName: 'Euro',
  },
  {
    name: 'Sri Lanka',
    alpha2: 'LK',
    alpha3: 'LKA',
    currencyCode: 'LKR',
    currencyName: 'Sri Lankan Rupee',
  },
  {
    name: 'Sweden',
    alpha2: 'SE',
    alpha3: 'SWE',
    currencyCode: 'SEK',
    currencyName: 'Swidish Krona',
  },
  {
    name: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
    currencyCode: 'CHF',
    currencyName: 'Swiss Franc',
  },
  {
    name: 'Taiwan',
    alpha2: 'TW',
    alpha3: 'TWN',
    currencyCode: 'TWD',
    currencyName: 'New Taiwan Dollar',
  },
  {
    name: 'Tanzania',
    alpha2: 'TZ',
    alpha3: 'TZA',
    currencyCode: 'TZS',
    currencyName: 'Tanzanian Shilling',
  },
  {
    name: 'Thailand',
    alpha2: 'TH',
    alpha3: 'THA',
    currencyCode: 'THB',
    currencyName: 'Thai Bhat',
  },
  {
    name: 'Trinidad & Tobago',
    alpha2: 'TT',
    alpha3: 'TTO',
    currencyCode: 'TTD',
    currencyName: 'Trinidad and Tobago Dollar',
  },
  {
    name: 'Tunisia',
    alpha2: 'TN',
    alpha3: 'TUN',
    currencyCode: 'TND',
    currencyName: 'Tunisian Dinar',
  },
  {
    name: 'Turkey',
    alpha2: 'TR',
    alpha3: 'TUR',
    currencyCode: 'TRY',
    currencyName: 'Turkish Lira',
  },
  {
    name: 'Ukraine',
    alpha2: 'UA',
    alpha3: 'UKR',
    currencyCode: 'UAH',
    currencyName: 'Ukrainian Hryvnia',
  },
  {
    name: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
    currencyCode: 'AED',
    currencyName: 'UAE Dirham',
  },
  {
    name: 'United Kingdom',
    alpha2: 'GB',
    alpha3: 'GBR',
    currencyCode: 'GBP',
    currencyName: 'Pound Sterling',
  },
  {
    name: 'United States',
    alpha2: 'US',
    alpha3: 'USA',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
  },
  {
    name: 'Uruguay',
    alpha2: 'UY',
    alpha3: 'URY',
    currencyCode: 'UYU',
    currencyName: 'Uruguayan Peso',
  },
  {
    name: 'Uzbekistan',
    alpha2: 'UZ',
    alpha3: 'UZB',
    currencyCode: 'UZS',
    currencyName: 'Uzbekistani Soum',
  },
  {
    name: 'Venezuela',
    alpha2: 'VE',
    alpha3: 'VEN',
    currencyCode: 'VEF',
    currencyName: 'Venezuelan Bolivar',
  },
  {
    name: 'Vietnam',
    alpha2: 'VN',
    alpha3: 'VNM',
    currencyCode: 'VND',
    currencyName: 'Vietnamese Dong',
  },
];

const currencies = countries.reduce((codes, next) => {
  codes[next.currencyCode] = next.currencyName;

  return codes;
}, {} as Record<string, string>);

export function currencyByCode(code: string): string | null {
  return currencies[code] ?? null;
}

export const currencySelectOptions = uniqBy(countries, 'currencyCode')
  .sort((a, b) => (a.currencyName > b.currencyName ? 1 : -1))
  .map((c) => ({
    label: `${c.currencyName} - ${c.currencyCode}`,
    value: c.currencyCode,
  }));

export function countryNameByIsoCode(code: string) {
  const country = countries.find((c) => c.alpha2 === code);
  return country ? country.name : code;
}

/**
 * converts a three-digit ISO code to two-digit.
 */
export function twoDigitIsoCode(threeDigitIsoCode: string) {
  if (threeDigitIsoCode.length === 2) return threeDigitIsoCode;
  const foundCountry = countries.find((c) => c.alpha3 === threeDigitIsoCode);
  if (!foundCountry)
    throw new Error(
      `No 2-digit ISO code could be found for ${threeDigitIsoCode}`,
    );
  return foundCountry.alpha2;
}

export const countryCodes = countries.map((c) => c.alpha2);

export function currencyByIsoCode(code: string) {
  const country = countries.find((c) => c.alpha2 === code);
  return country ? country.currencyCode : null;
}
