import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { trpc } from '../../lib/trpc';

export function Connected() {
  const [search] = useSearchParams();
  const code = search.get('code');
  const [itWorked, setItWorked] = useState(false);
  const { mutateAsync: getAccessToken, isLoading } =
    trpc.a.admins.connectPingPong.useMutation();
  const setNotification = useNotification((state) => state.setNotification);

  useEffect(() => {
    if (!code || isLoading) return;

    getAccessToken(code)
      .then(() => {
        setItWorked(true);
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Connected to Ping Pong',
        });
      })
      .catch((err) => {
        setItWorked(false);
        setNotification({
          type: 'error',
          title: 'Error',
          message: err.toString(),
        });
      });
  }, [code]);

  if (itWorked) return <div>it worked</div>;
  return <div>loading...</div>;
}
