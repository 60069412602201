import { FieldValues, Path, UnPackAsyncDefaultValues } from 'react-hook-form';
import { PingPongBankInfo } from 'listo/src/pingPong.shared-types';
import { currencyByIsoCode } from 'listo/src/countries';
import { useMemo } from 'react';
import { Autocomplete } from '../Autocomplete';
import { AppRouterReactType } from '../../hooks/trpc-types';
import { InputProperties } from '../ReactFormProps';

export function BankAccountNumber<
  T extends FieldValues,
  P extends Path<UnPackAsyncDefaultValues<T>>,
>({
  setValue,
  currency,
  countryCode,
  fieldName,
  trpc,
  label = 'Bank Name',
  span,
  onAccept,
  ...props
}: InputProperties<T, P> & {
  currency?: string | null;
  countryCode?: string | null;
  fieldName: P;
  setValue: (key: string, value: string) => void;
  onAccept?: (bankAccount: PingPongBankInfo) => void;
  trpc: AppRouterReactType;
  label?: string;
  span?: number;
}) {
  const { data: institutions, isLoading: institutionsLoading } =
    trpc.a.bankAccounts.pingPongInstitutions.useQuery();

  const { reactHookForm } = props;

  const institutionOptions = useMemo(() => {
    if (!countryCode) {
      return [];
    }
    const expectedCurrency = currencyByIsoCode(countryCode);
    const isOriginCountry = expectedCurrency === currency;
    if (
      currency == null ||
      !institutions?.institutions ||
      currency === 'USD' ||
      !isOriginCountry
    ) {
      return [];
    }

    const forCurrency = institutions.institutions[currency];
    if (!forCurrency) {
      return [];
    }

    return forCurrency.map((institution) => ({
      key: institution.bankCode ?? '',
      title: institution.bankName || 'No label',
      value: institution,
    }));
  }, [currency, institutions, countryCode]);

  return (
    (reactHookForm && (
      <div className={`sm:col-span-${span}`}>
        <Autocomplete
          fieldName={reactHookForm.fieldName}
          loading={institutionsLoading}
          label={label}
          updateValue={(value: string) =>
            setValue(reactHookForm.fieldName ?? '', value)
          }
          suggestionToValue={(suggestion) => (suggestion.bankName ?? '') as any}
          onAccept={(sel) => onAccept && onAccept(sel.value)}
          data={institutionOptions}
          inputProps={{
            label: 'Bank Name',
            inputProps: {
              autoComplete: 'off',
            },
            reactHookForm: {
              ...reactHookForm,
              fieldName,
            },
          }}
        />
      </div>
    )) || <span />
  );
}
