import { FieldValues, Path, UnPackAsyncDefaultValues } from 'react-hook-form';
import { Field } from '../Field';
import { InputProperties } from '../ReactFormProps';

export function Input<
  T extends FieldValues,
  P extends Path<UnPackAsyncDefaultValues<T>>,
>({
  label,
  inputProps,
  reactHookForm,
  inputType = 'text',
  children = [],
  infoTooltipId,
  infoTooltipContent,
}: InputProperties<T, P>) {
  return (
    <Field
      label={label}
      reactHookForm={reactHookForm}
      infoTooltipId={infoTooltipId}
      infoTooltipContent={infoTooltipContent}
    >
      <input
        type={inputType}
        {...reactHookForm?.register(reactHookForm?.fieldName, {
          setValueAs: reactHookForm?.format,
        })}
        {...inputProps}
        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
      {children}
    </Field>
  );
}
