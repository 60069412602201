import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { countryNameByIsoCode } from 'listo/src/countries';
import { truthyToYesNo } from 'listo/src/utils/strings';
import { PageLoader } from 'ui/src/components/PageLoader';

import Header from '../../components/Header';
import { RouterOutput, trpc } from '../../lib/trpc';
import Table from '../../components/Table/Table';

type Quotes = RouterOutput['a']['quotes']['list'][0];

const columnHelper = createColumnHelper<Quotes>();

function ActionButton({ quotes }: { quotes: Quotes }) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className="ml-4 text-indigo-600 hover:text-indigo-900"
      onClick={() => {
        navigate(`/quotes/${quotes.id}`);
      }}
    >
      View
    </button>
  );
}

const columns = [
  columnHelper.accessor('client.name', {
    header: `Client Name`,
  }),
  columnHelper.display({
    id: 'accepted',
    header: `Accepted`,
    cell: ({ row }) => (
      <div className="flex items-center space-x-3 lg:pl-2">
        {truthyToYesNo(row.original.accepted)}
      </div>
    ),
  }),
  columnHelper.display({
    id: 'upload',
    header: `Upload`,
    cell: ({ row }) => (
      <div className="flex items-center space-x-3 lg:pl-2">
        {truthyToYesNo(row.original.uploadId)}
      </div>
    ),
  }),
  columnHelper.display({
    id: 'country',
    header: `Country`,
    cell: ({ row }) => (
      <div className="flex items-center space-x-3 lg:pl-2">
        {countryNameByIsoCode(row.original.isoCountryCode)}
      </div>
    ),
  }),

  columnHelper.display({
    id: 'actions',
    header: () => <span className="sr-only">Actions</span>,
    cell: ({ row }) => <ActionButton quotes={row.original} />,
  }),
];

export function QuotesList() {
  const { data: quotes, isLoading, error } = trpc.a.quotes.list.useQuery();
  const [searchTerm, setSearchTerm] = useState<string>('');

  if (error) return <div>{error.message}</div>;
  if (isLoading) return <PageLoader />;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Quotes" buttonRoute="/quotes/create" buttonText="New" />
      <div className="m-8 flex flex-col overflow-x-auto">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <Table
            data={quotes || []}
            columns={columns}
            error={error}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isLoading={isLoading}
            tableType="Quotes"
          />
        </div>
      </div>
    </div>
  );
}
