import { zodResolver } from '@hookform/resolvers/zod';
import { formatDate } from 'listo/src/utils/dates';
import {
  createDistributionZod,
  CreateDistributionType,
} from 'listo/src/zodObjects/distributions';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui/src/components/Button';
import { Input } from 'ui/src/components/Input';
import { SelectInput } from 'ui/src/components/SelectInput';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

export function DistributionsCreate() {
  const navigate = useNavigate();
  const [clientId, setClientId] = useState('');

  const { mutate: createDistribution, isLoading: createDistributionLoading } =
    trpc.a.distributions.create.useMutation({
      onSuccess: () => navigate('/distributions'),
    });

  const { data: clients, isLoading: clientsLoading } =
    trpc.a.clients.list.useQuery({});

  const { data: payPeriods, isLoading: payPeriodsLoading } =
    trpc.a.payPeriods.list.useQuery(
      {
        filters: {
          clientId,
        },
      },
      {
        enabled: !!clientId,
      },
    );

  const { data: workers, isLoading: workersLoading } =
    trpc.a.workers.workers.useQuery(
      {
        filters: {
          clientId,
        },
      },
      {
        enabled: !!clientId,
      },
    );

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<CreateDistributionType>({
    resolver: zodResolver(createDistributionZod),
  });

  useEffect(() => {
    const c = watch('clientId');
    if (c) {
      setClientId(c);
    }
  }, [watch('clientId')]);

  const onSubmit: SubmitHandler<CreateDistributionType> = (data) => {
    createDistribution(data);
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Create Distribution" />
      <div className="align-middle sm:m-8 m-2">
        <div className="bg-white shadow sm:rounded-lg px-4 sm:px-6 lg:px-8 py-8">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div className="flex justify-between">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Distribution
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Enter distribution details
                    </p>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-2">
                    <SelectInput
                      label="Client"
                      loading={clientsLoading}
                      defaultOption={
                        { label: 'Select Client', value: '' } as const
                      }
                      options={
                        clients?.map((client) => ({
                          label: client.name,
                          value: client.id,
                        })) || []
                      }
                      reactHookForm={{
                        register,
                        fieldName: 'clientId',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <SelectInput
                      label="Pay Period"
                      loading={payPeriodsLoading}
                      defaultOption={
                        { label: 'Select PayPeriod', value: '' } as const
                      }
                      options={
                        payPeriods?.map((payPeriod) => ({
                          label: `Distribute Date: ${formatDate(
                            payPeriod.distributeDate,
                          )}`,
                          value: payPeriod.id,
                        })) || []
                      }
                      reactHookForm={{
                        register,
                        fieldName: 'payPeriodId',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <SelectInput
                      label="Worker"
                      loading={workersLoading}
                      defaultOption={
                        { label: 'Select Worker', value: '' } as const
                      }
                      options={
                        workers?.workers?.map((worker) => ({
                          label: `${worker.firstName} ${worker.lastName}`,
                          value: worker.id,
                        })) || []
                      }
                      reactHookForm={{
                        register,
                        fieldName: 'workerProfileId',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <Input
                      inputType="number"
                      label="Amount"
                      reactHookForm={{
                        register,
                        fieldName: 'amountInCents',
                        errors,
                      }}
                    />
                  </div>
                </div>
                <div className="mt-8 pt-4">
                  <div className="flex justify-end">
                    <Button
                      text="Cancel"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate('/distributions');
                      }}
                      variant="secondary"
                    />
                    <Button
                      loading={createDistributionLoading}
                      text="Save"
                      type="submit"
                      className="ml-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
