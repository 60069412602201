import { formatCentsToDollars } from 'listo/src/utils/currency';
import { formatDate } from 'listo/src/utils/dates';
import { useParams } from 'react-router-dom';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { PageLoader } from 'ui/src/components/PageLoader';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

export function InvoiceShow() {
  const { invoiceId } = useParams();
  const setNotification = useNotification((state) => state.setNotification);

  const {
    data: invoice,
    isLoading,
    error,
  } = trpc.a.invoices.invoice.useQuery(invoiceId as string);

  const { mutate: sendInvoice, isLoading: isSendingInvoice } =
    trpc.a.invoices.sendInvoice.useMutation({
      onSuccess: () => {
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Email sent via Stripe',
        });
      },
    });

  if (isLoading) return <PageLoader />;
  if (error) return <div>{error.message}</div>;
  if (invoice === undefined) return <PageLoader />;

  return (
    <div className="bg-gray-100 min-h-screen pb-8">
      <Header title="Invoice" />
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-3">
          <section aria-labelledby="invoice-info">
            <div className="bg-white shadow sm:rounded-lg p-6">
              <div>
                <div className="px-4 py-5 sm:px-6">
                  <div className="flex items-center justify-between">
                    <h2
                      id="invoice information"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Invoice Information
                    </h2>
                  </div>

                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Invoice data
                  </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 border-b border-gray-200 pb-4 sm:pb-4">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Client
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {invoice.client.name}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Invoice Total
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatCentsToDollars(invoice.amountInCents, 'USD')}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Stripe Invoice Id
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {invoice.stripeInvoiceId}
                      </dd>
                    </div>
                    <div id="status" className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Stripe Status
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {invoice.stripeInvoiceStatus}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Invoice Date
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatDate(invoice.createdAt)}
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="mt-8 max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-7xl">
        <div className="sm:rounded-lg grid grid-cols-3 gap-4">
          <div className="col-span-1 flex flex-col items-center">
            <ActionPanel
              title="Send Invoice"
              content="Send the invoice out for collection via Stripe."
            >
              <Button
                text="Send"
                onClick={() => sendInvoice(invoice.id)}
                loading={isSendingInvoice}
              />
            </ActionPanel>
          </div>
        </div>
      </div>
    </div>
  );
}
