import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OnboardAdminType, onboardAdminZod } from 'listo/src/zodObjects/admins';

import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

export function CreateAdmin() {
  const navigate = useNavigate();
  const { handleSubmit, register } = useForm<OnboardAdminType>({
    resolver: zodResolver(onboardAdminZod),
  });

  const onboardAdmin = trpc.a.admins.onboardAdmin.useMutation({
    onSuccess: () => {
      navigate('/admins');
    },
  });

  const onSubmit: SubmitHandler<OnboardAdminType> = (data) => {
    onboardAdmin.mutate({
      ...data,
    });
  };

  return (
    <div className="min-h-full">
      <Header title="Create Admin" />
      <div className="max-w-lg mx-auto pt-10 pb-12 px-4 lg:pb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-6">
            <div>
              <h1 className="text-lg leading-6 font-medium text-gray-900">
                Onboard Admin
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Add a new admin to Listo and send an invitation email.
              </p>
            </div>

            <div>
              <label
                htmlFor="user-email"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="firstName"
                  {...register('firstName', {
                    required: true,
                  })}
                  name="firstName"
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="user-email"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="lastName"
                  {...register('lastName', {
                    required: true,
                  })}
                  name="lastName"
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="user-email"
                className="block text-sm font-medium text-gray-700"
              >
                User Email
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="email"
                  {...register('email', {
                    required: true,
                  })}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue=""
                />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => {
                  navigate('/admins');
                }}
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                Create new admin
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
