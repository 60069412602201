import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { PingPongConnectStatus } from 'listo/src/pingPong.shared-types';
import useLocalStorage from 'ui/src/hooks/useLocalStorage';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

export function PingPong() {
  const [state] = useState(Math.random().toString(36).slice(2));
  const [search] = useSearchParams();
  const code = search.get('code');
  const stateParam = search.get('state');
  const setNotification = useNotification((s) => s.setNotification);
  const [inFlightStateParam, updateCurrentStateRequest] = useLocalStorage(
    'pingPongStateKey',
    null,
  );

  const {
    refetch: refreshPingPongStatus,
    data: pingPongStatus,
    isLoading: isLoadingStatus,
  } = trpc.a.admins.getPingPongStatus.useQuery(state);

  const { mutateAsync: getAccessToken, isLoading: isGettingAccessToken } =
    trpc.a.admins.connectPingPong.useMutation({
      onSuccess: () => {
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Connected to Ping Pong',
        });
      },
      onError: (error) => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: error.message,
        });
      },
    });

  if (code && stateParam && stateParam === inFlightStateParam) {
    updateCurrentStateRequest(null);
    (async () => {
      try {
        await getAccessToken(code);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        await refreshPingPongStatus();
      }
    })();
  }

  if (!pingPongStatus) {
    return null;
  }

  let content: string;
  switch (pingPongStatus.status) {
    case PingPongConnectStatus.NOT_CONNECTED:
      content = 'click here to oauth with ping pong and get back a fancy code';
      break;
    case PingPongConnectStatus.INVALID_TOKEN:
      content = `Ping Pong connection is no longer working: ${pingPongStatus.message}`;
      break;
    case PingPongConnectStatus.VERIFIED:
      content = 'Ping Pong has been connected and is working';
      break;
    default:
      content = '';
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Ping Pong" />
      <div className="m-8 flex flex-col">
        <div className="sm:rounded-lg grid grid-cols-3 gap-4">
          <div className="col-span-2 flex flex-col items-center">
            <ActionPanel title="Connect To Ping Pong" content={content}>
              <div className="space-x-2">
                <Button
                  text="Connect"
                  loading={isGettingAccessToken || isLoadingStatus}
                  className="pr-3"
                  onClick={() => {
                    updateCurrentStateRequest(state);
                    window.location.href = pingPongStatus.authUrl;
                  }}
                />

                {pingPongStatus.status === PingPongConnectStatus.VERIFIED && (
                  <Button
                    text="Test"
                    loading={isGettingAccessToken || isLoadingStatus}
                    onClick={() => {
                      refreshPingPongStatus().then(({ data }) => {
                        if (data) {
                          const { status, message } = data;
                          if (status === PingPongConnectStatus.VERIFIED) {
                            setNotification({
                              type: 'success',
                              title: 'PingPong verified',
                              message: '',
                            });
                          } else {
                            setNotification({
                              type: 'error',
                              title: `PingPong verification failed`,
                              message: `Error: ${message}`,
                            });
                          }
                        }
                      });
                    }}
                  />
                )}
              </div>
            </ActionPanel>
          </div>
        </div>
      </div>
    </div>
  );
}
