import { countryNameByIsoCode } from 'listo/src/countries';

import { RouterOutput } from '../../lib/trpc';

export function EmployeesAggregate({
  aggregates,
}: {
  aggregates: RouterOutput['a']['dashboard']['stats']['employeeAggregates'];
}) {
  return (
    <div className="flex flex-wrap justify-around overflow-hidden overflow-y-scroll scrollbar-hide h-96">
      {aggregates.map((item) => (
        <div
          key={item.isoCountryCode}
          className="overflow-hidden rounded-lg bg-white p-2 m-2 cursor-pointer"
        >
          <dd className=" text-center text-2xl font-semibold tracking-tight text-indigo-900">
            {item.count}
          </dd>
          <dt className="text-center text-sm font-medium text-gray-500">
            <p>{countryNameByIsoCode(item.isoCountryCode as string)}</p>
          </dt>
        </div>
      ))}
    </div>
  );
}
