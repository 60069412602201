import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { authed } = useAuth();
  const location = useLocation();

  if (authed === undefined) return null;

  return authed === true ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}
