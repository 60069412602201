import { z } from 'zod';

export const createDistributionZod = z.object({
  clientId: z.string().uuid(),
  payPeriodId: z.string().uuid('Please Select Pay Period'),
  workerProfileId: z.string().uuid(),
  amountInCents: z.preprocess(
    (val) => (typeof val === 'string' ? parseFloat(val) : val),
    z.number().min(1),
  ),
});

export const distributionEdit = z.object({
  amountInCents: z.preprocess(
    (val) => (typeof val === 'string' ? parseFloat(val) : val),
    z.number().min(1),
  ),
  clientId: z.string().uuid(),
  payPeriodId: z.string().optional().nullish(),
  workerProfileId: z.string().uuid(),
});

export type DistributionEdit = z.infer<typeof distributionEdit>;

export type CreateDistributionType = z.infer<typeof createDistributionZod>;
