/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { FieldValues, Path, UnPackAsyncDefaultValues } from 'react-hook-form';
import { dateTimeOf, formatIsoLocalDate } from 'listo/src/utils/dates';
import { DateTime } from 'api/src/lib/luxonUtc';
import {
  isBusinessDay,
  minusBusinessDays,
  plusBusinessDays,
} from 'listo/src/utils/business-days';
import { useState } from 'react';
import { arrayOf } from 'listo/src/utils/urls';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { InputProperties } from '../ReactFormProps';
import { Input } from '../Input';
import { Card } from '../Card/Card';

const oneMonth = { month: 1 };
const oneDay = { day: 1 };
const oneWeek = { day: 7 };

type DateShortcut = {
  shortcut: string | string[];
  handler: (date: DateTime) => DateTime | undefined;
  ctrl?: boolean;
  shift?: boolean;
  description: string;
};

/**
 * Date input that has keyboard shortcuts for entering dates
 */
export function DateInput<
  T extends FieldValues,
  P extends Path<UnPackAsyncDefaultValues<T>>,
>({ inputProps: passedInputProps, ...props }: InputProperties<T, P>) {
  const inputProps = passedInputProps ?? {};
  const [isExpanded, setExpanded] = useState(false);

  const shortcuts: DateShortcut[] = [
    {
      shortcut: 't',
      handler: () => DateTime.now(),
      description: 'today',
    },

    {
      shortcut: ['+', '='],
      description: 'add 1 day',
      handler: (d) => d.plus(oneDay),
    },
    {
      shortcut: ['+', '='],
      description: 'add business day',
      ctrl: true,
      handler: (d) => plusBusinessDays(d, 1),
    },
    {
      shortcut: '-',
      description: 'subtract 1 day',
      handler: (d) => d.minus(oneDay),
    },
    {
      shortcut: '-',
      description: 'subtract business day',
      ctrl: true,
      handler: (d) => minusBusinessDays(d, 1),
    },
    {
      shortcut: 'z',
      description: 'ensure business day (forward)',
      handler: (d) => (isBusinessDay(d) ? d : plusBusinessDays(d, 1)),
    },
    {
      shortcut: 'z',
      description: 'ensure business day (back)',
      shift: true,
      handler: (d) => (isBusinessDay(d) ? d : minusBusinessDays(d, 1)),
    },
    {
      shortcut: ['[', 'f'],
      description: 'first day of month',
      handler: (d) => d.set({ day: 1 }),
    },
    {
      shortcut: [']', 'l'],
      description: 'last day of month',
      handler: (d) => d.set({ day: 1, month: d.month + 1 }).minus(oneDay),
    },
    {
      shortcut: 'w',
      description: 'add week',
      handler: (d) => d.plus(oneWeek),
    },
    {
      shortcut: 'w',
      description: 'subtract week',
      shift: true,
      handler: (d) => d.minus(oneWeek),
    },
    {
      shortcut: 'm',
      description: 'add month',
      handler: (d) => d.plus(oneMonth),
    },
    {
      shortcut: 'm',
      description: 'subtract month',
      shift: true,
      handler: (d) => d.minus(oneMonth),
    },
  ];

  const {
    getValues = () => undefined,
    setValue,
    fieldName,
  } = props.reactHookForm ?? {};
  if (setValue && fieldName) {
    const modifyDate = (mutation: (last: DateTime) => DateTime | undefined) => {
      const currentValues = getValues() ?? { [fieldName]: '' };
      const workingDate =
        dateTimeOf(currentValues[fieldName]) ?? DateTime.local();
      const modifiedDate = mutation(workingDate) ?? workingDate;
      setValue(fieldName, formatIsoLocalDate(modifiedDate));
    };
    inputProps.onBlur = () => setExpanded(false);
    inputProps.onKeyUp = (event) => {
      if (event.key === '?') {
        setExpanded(!isExpanded);
        return;
      }

      const matchingShortcut = shortcuts.find(
        ({ shift = false, ctrl = false, shortcut }) =>
          arrayOf(shortcut).includes(event.key.toLowerCase()) &&
          ctrl === event.ctrlKey &&
          shift === event.shiftKey,
      );
      if (matchingShortcut) {
        modifyDate(matchingShortcut.handler);
        event.preventDefault();
      }
    };
  }
  return (
    <Input {...props} inputProps={inputProps} inputType="date">
      {isExpanded ? (
        <Card className="absolute mt-1">
          Shortcuts:
          <table>
            <tbody>
              {shortcuts.map(({ shift, ctrl, description, shortcut }) => (
                <tr key={description}>
                  <td>
                    {ctrl || shift ? (
                      <div className="flex w-7 h-7 text-center text-sm bg-gray-100  px-2 py-1 rounded-md">
                        {ctrl ? <ChevronUpIcon className="stroke-2" /> : '⇧'}
                      </div>
                    ) : (
                      <span />
                    )}
                  </td>
                  <td>
                    <div className="w-7 h-7 text-center leading-normal text-sm bg-gray-100 px-2 py-1 rounded-md">
                      {arrayOf(shortcut)[0]}
                    </div>
                  </td>
                  <td className="pl-2 text-xs">{description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      ) : null}
    </Input>
  );
}
