/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef, useState } from 'react';
import { arrayOf } from 'listo/src/utils/urls';
import { FieldValues, Path, UnPackAsyncDefaultValues } from 'react-hook-form';
import { IndexOptions } from 'flexsearch-ts';
import { useFlexSearch } from '../../hooks/useFlexSearch';
import { Input } from '../Input';
import { ButtonSpinner } from '../Spinner/Spinner';
import { InputProperties } from '../ReactFormProps';

export interface Suggestion<D> {
  value: D;
  key: string;
  title: string;
  subtitle?: string;
}

interface Props<
  T extends FieldValues,
  D,
  P extends Path<UnPackAsyncDefaultValues<T>>,
> extends Omit<React.HTMLProps<unknown>, 'data'> {
  data?: Suggestion<D>[];
  updateValue: (update: any) => void;
  fieldName: P;
  indexOptions?: IndexOptions<D>;
  label: string;
  inputProps?: Partial<InputProperties<T, P>>;
  classes?: Record<string, string>;
  suggestionToValue?: (suggestion: D) => T[P];
  onAccept?: (suggestion: Suggestion<D>) => void;
  loading?: boolean;
}

export function Autocomplete<
  T extends FieldValues,
  P extends Path<UnPackAsyncDefaultValues<T>>,
  D,
>({
  updateValue,
  suggestionToValue = (suggestion: D) => suggestion as T[P],
  defaultValue,
  onAccept,
  data,
  inputProps,
  indexOptions,
  loading = false,
}: Props<T, D, P>) {
  /**
   * Used to watch outside clicks, and close the suggestions
   */
  const ref = useRef<HTMLDivElement>(null);
  const [queryValue, setQueryValue] = useState('');
  const [cursor, setCursor] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const suggestions = useFlexSearch({
    query: queryValue,
    data: data ?? [],
    tokenizer: (item) => [
      item.key,
      arrayOf(item.title, item.subtitle, item.key).join(' '),
    ],
    indexOptions,
  });

  function handleAcceptOption(option: Suggestion<D>) {
    if (option) {
      const rawValue = suggestionToValue(option.value);
      setShowOptions(false);
      updateValue(rawValue);
      if (onAccept) onAccept(option);
    }
  }

  function handleInput(event: React.ChangeEvent<HTMLInputElement>): void {
    setCursor(-1);
    setQueryValue(event.target.value);
    setShowOptions(true);
  }

  function moveCursorDown() {
    if (cursor < suggestions.length - 1) {
      setCursor((c) => c + 1);
    }
  }

  function moveCursorUp() {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  }

  function handleNav(e: React.KeyboardEvent) {
    function acceptSuggestion() {
      const suggestion = suggestions[cursor];
      if (suggestion) {
        handleAcceptOption(suggestion);
      }
    }
    switch (e.key) {
      case 'ArrowUp':
        return moveCursorUp();
      case 'ArrowDown':
        return moveCursorDown();
      case 'Enter':
        acceptSuggestion();
        return e.preventDefault();
      case 'Tab':
        return acceptSuggestion();
      default:
        return null;
    }
  }

  // Register event listener
  useEffect(() => {
    function handleClick(e: Event) {
      if (!ref.current) return;
      const clickTarget = e.target as Element;
      if (!ref.current?.contains(clickTarget)) {
        setShowOptions(false);
        setCursor(-1);
      } else if (
        !clickTarget.classList.contains('menu-option') &&
        !showOptions
      ) {
        setShowOptions(true);
      }
    }

    document.addEventListener('click', handleClick);
    document.addEventListener('focusin', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('focusin', handleClick);
    };
  }, []);

  const { onFocus, onKeyDown, ...innerProps } = inputProps?.inputProps ?? {};

  return (
    <div ref={ref}>
      <Input
        {...(inputProps ?? {})}
        inputProps={{
          ...innerProps,
          onFocus: onFocus ?? ((e) => setShowOptions(true)),
          autoComplete: 'off',
          onChange: handleInput,
          onKeyDown: onKeyDown ?? handleNav,
          value: defaultValue,
        }}
      >
        <div className="relative nowrap text-ellipses">
          <ul
            className={`absolute mt-0.5 bg-white w-full rounded-lg shadow-lg ${
              !showOptions && 'hidden'
            } select-none`}
          >
            {(() => {
              if (suggestions.length > 0) {
                return suggestions.map((option, i, arr) => (
                  <AutocompleteItem
                    cursor={cursor}
                    i={i}
                    array={arr}
                    key={suggestionToValue(option.value)}
                    item={option}
                    onAccept={() => onAccept && onAccept(option)}
                  />
                ));
              }
              if (loading) {
                return (
                  <li className="flex bg-white px-4 py-2 text-indigo-600 border-transparent outline-none">
                    <ButtonSpinner />
                  </li>
                );
              }
              return <span />;
            })()}
          </ul>
        </div>
      </Input>
    </div>
  );
}

function AutocompleteItem<T>({
  cursor,
  item,
  i,
  array,
  onAccept,
}: {
  cursor: number;
  item: Suggestion<T>;
  i: number;
  array: Suggestion<T>[];
  onAccept: () => void;
}) {
  const p1 = '3';
  const p2 = '2';
  let className =
    'menu-option whitespace-nowrap overflow-hidden overflow-ellipses px-4 hover:bg-gray-100 text-xs ';

  if (i === 0 && array.length !== 1) {
    className += `pt-${p2} pb-${p2} rounded-t-lg`;
  } else if (i === array.length) {
    className += `pt-${p1} pb-${p2} rounded-b-lg`;
  } else if (i === 0 && array.length === 1) {
    className += `py-${p2} rounded-lg`;
  } else {
    className += `py-${p1}`;
  }

  if (cursor === i) {
    className += ' bg-gray-100';
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <li className={className} onClick={() => onAccept()}>
      {item.title}
    </li>
  );
}
