import { useState, useEffect, useReducer, useMemo } from 'react';
import { unionBy } from 'lodash';
import { useDebounce } from 'ui/src/hooks/useDebounce';

import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { RouterOutput, trpc } from '../../lib/trpc';
import Header from '../../components/Header';
import { SelectedFilter } from './credentialsFilter';
import { PaginateContext } from '../../components/Table/Pagination';
import Table from '../../components/Table/Table';

const pageSize = 25;

function selectedFiltersReducer(
  state: SelectedFilter[],
  action: SelectedFilter,
) {
  const filters = unionBy([action, ...state], 'optionLabel');
  return filters;
}

type Credential =
  RouterOutput['a']['credentials']['paginate']['credentials'][0];

const sortOptions = [
  { label: 'Asc Email (a-z)', value: 'email.asc' },
  { label: 'Desc Email (z-a)', value: 'email.desc' },
  { label: 'created desc', value: 'createdAt.desc' },
  { label: 'created asc', value: 'createdAt.asc' },
];

const filterOptions = [
  {
    name: 'Filters',
    options: [{ label: 'Password Not Set', value: 'passwordNotHash' }],
  },
];

const columnHelper = createColumnHelper<Credential>();

function TableActionBtn({ id }: { id: string }) {
  const navigate = useNavigate();

  return (
    <div className="text-right">
      <button
        type="button"
        onClick={() => {
          navigate(`/credentials/${id}`);
        }}
        className="text-indigo-600 hover:text-indigo-900  ml-4"
      >
        View
      </button>
    </div>
  );
}

const columns = [
  columnHelper.accessor('email', {
    header: () => `Email`,
  }),
  columnHelper.display({
    id: 'actions',
    header: () => <span className="sr-only">Actions</span>,
    cell: (props) => <TableActionBtn id={props.row.original.id} />,
  }),
];

function variables({
  page,
  searchTerm,
  sort,
  selectedFilters,
}: {
  page: number;
  searchTerm: string | undefined;
  sort: string | undefined;
  selectedFilters: SelectedFilter[];
}) {
  return {
    page,
    limit: pageSize,
    searchTerm,
    sort,
    filters: {
      passwordNotHash: !selectedFilters.find(
        (data) => data.optionValue === 'passwordNotHash',
      ),
    },
  };
}

export function Credentials() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState('createdAt.desc');

  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    selectedFiltersReducer,
    [],
  );
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 275);

  const { data, isLoading, error } = trpc.a.credentials.paginate.useQuery(
    variables({ page, searchTerm: debouncedSearchTerm, sort, selectedFilters }),
  );

  const paginationData = useMemo(
    () => ({
      setPage,
      currentPage: page,
      nextPage: data?.nextPage,
      prevPage: data?.prevPage,
      total: data?.total,
      pageSize: data?.pageSize,
    }),
    [page, data],
  );

  useEffect(() => {
    if (page !== 0) setPage(0);
  }, [searchTerm, sort]);

  if (error) return <div>error</div>;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Credentials" />
      <div className="m-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden">
              <PaginateContext.Provider value={paginationData}>
                <Table
                  data={data?.credentials || []}
                  columns={columns}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  error={error}
                  isLoading={isLoading}
                  tableType="Credentials"
                  tableFilterProps={{
                    sort: {
                      name: 'Sort',
                      options: sortOptions,
                    },
                    filters: filterOptions,
                    currentFilters: selectedFilters,
                    setCurrentFilters: dispatchSelectedFilters,
                    sortValue: sort,
                    setSortValue: setSort,
                  }}
                />
              </PaginateContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
