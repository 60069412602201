import {
  FieldError,
  FieldValues,
  Path,
  UnPackAsyncDefaultValues,
  UseFormRegister,
} from 'react-hook-form';
import { classNames } from 'listo/src/utils/strings';

export function TextInput<T extends FieldValues>({
  cols,
  register,
  fieldName,
  label,
  error,
  defaultValue,
}: {
  cols: number;
  register: UseFormRegister<T>;
  fieldName: Path<UnPackAsyncDefaultValues<T>>;
  label: string;
  error?: FieldError;
  defaultValue?: string;
}) {
  return (
    <div className={classNames(cols ? `sm:col-span-${cols}` : 'sm:col-span-3')}>
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          id={fieldName}
          defaultValue={defaultValue}
          type="text"
          {...register(fieldName)}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
        {error ? (
          <p className="mt-2 text-sm text-red-600" id={`${fieldName}-error`}>
            {error.message}
          </p>
        ) : null}
      </div>
    </div>
  );
}
