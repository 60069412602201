import { zodResolver } from '@hookform/resolvers/zod';
import { countries, currencySelectOptions } from 'listo/src/countries';
import { floatInDollarsToCents } from 'listo/src/utils/currency';
import {
  AdminContractsType,
  adminContractsZod,
} from 'listo/src/zodObjects/contracts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui/src/components/Button';
import { Input } from 'ui/src/components/Input';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { SelectInput } from 'ui/src/components/SelectInput';
import { DateInput } from 'ui/src/components/DateInput';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

export function CreateContract() {
  const navigate = useNavigate();
  const setNotification = useNotification((state) => state.setNotification);

  const { mutate: createContract, isLoading: createContractLoading } =
    trpc.a.contracts.create.useMutation({
      onSuccess: () => navigate('/contracts'),
      onError: (error) => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: error.toString(),
        });
      },
    });

  const { data: clients, isLoading: clientsLoading } =
    trpc.a.clients.list.useQuery({});

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AdminContractsType>({
    resolver: zodResolver(adminContractsZod),
  });

  const onSubmit: SubmitHandler<AdminContractsType> = (data) => {
    createContract({
      ...data,
      billingAmountInCents: floatInDollarsToCents(data.billingAmountInCents),
    });
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Create Contract" />
      <div className="min-w-full py-2 align-middle md:px-6 lg:px-8 my-8">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Contract Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">Contract info.</p>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Client"
                      defaultOption={
                        { label: 'Select Client', value: '' } as const
                      }
                      loading={clientsLoading}
                      options={
                        clients?.map((client) => ({
                          label: client.name,
                          value: client.id,
                        })) || []
                      }
                      reactHookForm={{
                        register,
                        fieldName: 'clientId',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Engagement Type"
                      options={[
                        { value: 'EOR', label: 'EOR' },
                        { value: 'PAYROLL', label: 'Payroll' },
                        { value: 'CONTRACTOR', label: 'Contractor' },
                      ]}
                      reactHookForm={{
                        register,
                        fieldName: 'engagementType',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Country"
                      options={countries.map((country) => ({
                        label: country.name,
                        value: country.alpha2,
                      }))}
                      reactHookForm={{
                        register,
                        fieldName: 'isoCountryCode',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Email"
                      reactHookForm={{
                        register,
                        fieldName: 'email',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="First Name"
                      reactHookForm={{
                        register,
                        fieldName: 'firstName',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Last Name"
                      reactHookForm={{
                        register,
                        fieldName: 'lastName',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <DateInput
                      label="Start Date"
                      inputType="date"
                      reactHookForm={{
                        register,
                        fieldName: 'startDate',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Job Title"
                      reactHookForm={{
                        register,
                        fieldName: 'jobTitle',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Seniority Level"
                      reactHookForm={{
                        register,
                        fieldName: 'seniorityLevel',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Scope of Work"
                      reactHookForm={{
                        register,
                        fieldName: 'scopeOfWork',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Billing Frequency"
                      options={[
                        { value: 'HOURLY', label: 'Hourly' },
                        { value: 'MONTHLY', label: 'Monthly' },
                      ]}
                      reactHookForm={{
                        register,
                        fieldName: 'billingFrequency',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Billing Amount"
                      inputProps={{
                        step: '0.01',
                      }}
                      reactHookForm={{
                        register,
                        fieldName: 'billingAmountInCents',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Distribution Currency"
                      options={currencySelectOptions}
                      selectProps={{
                        defaultValue: 'USD',
                      }}
                      reactHookForm={{
                        register,
                        fieldName: 'distributionCurrency',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <SelectInput
                      label="Distribution Frequency"
                      options={[
                        { value: 'SEMI_MONTHLY', label: 'Semi Monthly' },
                        { value: 'MONTHLY', label: 'Monthly' },
                      ]}
                      reactHookForm={{
                        register,
                        fieldName: 'distributionFrequency',
                        errors,
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Employement Type (optional)"
                      reactHookForm={{
                        register,
                        fieldName: 'employmentType',
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Contract Term (optional)"
                      reactHookForm={{
                        register,
                        fieldName: 'contractTerm',
                        errors,
                      }}
                    />
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex justify-end">
                    <Button
                      loading={createContractLoading}
                      text="Save"
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
