/* eslint-disable react/style-prop-object */
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import ReactSelect, { Options } from 'react-select';
import { useState } from 'react';
import { PingPongFoundStatus } from 'listo/src/pingPong.shared-types';
import { useNavigate } from 'react-router-dom';
import { Currency } from 'ui/src/components/Currency/Currency';
import { trpc } from '../../lib/trpc';
import { Spinner } from '../../components/Spinner/Spinner';
import { usePingPongCreateRecipient } from '../../hooks/usePingPong';

type DefaultOption = {
  label?: string | null;
  value?: Record<string, unknown>;
};
export function SendViaPingPong({
  distributionId,
  onChange,
  onPaid,
}: {
  distributionId: string;
  onChange: () => unknown;
  onPaid: (confirmation: string) => unknown;
}) {
  const setNotification = useNotification((state) => state.setNotification);

  const [accountOptions, setAccountSelectOptions] = useState<
    Options<DefaultOption>
  >([]);

  const navigate = useNavigate();
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const { createPingPongRecipient, createRecipientLoading } =
    usePingPongCreateRecipient();

  const { data: pingPongInfo, refetch: refetchPingPongStatus } =
    trpc.a.distributions.getPingPongInfo.useQuery(distributionId, {
      onSuccess({ accounts }) {
        const options = accounts.map((item) => ({
          value: item,
          label: `${item.bankName}${item.isDefault ? ' (default)' : ''}`,
        }));
        setAccountSelectOptions(options);
        const foundAccount =
          options.find((e) => e.value.isDefault) ?? options[0];
        setSelectedAccount(
          selectedAccount
            ? options.find((e) => e.value === foundAccount?.value)
            : foundAccount,
        );
      },
    });

  const { mutate: send, isLoading: sending } =
    trpc.a.distributions.sendViaPingPong.useMutation({
      onSuccess: (data) => {
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Sent Via Ping Pong',
        });
        if (data.externalIdentifier) {
          onPaid(data.externalIdentifier);
        }
      },
      onError: (e: any) => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: e.toString(),
        });
      },
    });

  if (!pingPongInfo) {
    return <Spinner />;
  }

  const {
    workerProfileId,
    distributionAmount,
    distributionCurrency,
    billingAmount,
    billingCurrency,
    invoicedAmount,
    listoCostAmount,
    forex,
  } = pingPongInfo;
  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="sm:flex sm:items-start justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Send with Ping Pong
            </h3>
          </div>
        </div>
        {forex != null && (
          <div className="pt-2">
            <table className="text-sm pt-2">
              <tbody>
                <tr>
                  <td>Billing amount:</td>
                  <td>
                    <Currency
                      value={billingAmount}
                      currencyCode={billingCurrency}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Distribution amount:</td>
                  <td className="font-semibold">
                    <Currency
                      value={distributionAmount}
                      currencyCode={distributionCurrency}
                    />
                  </td>
                </tr>
                {billingCurrency !== 'USD' && (
                  <tr>
                    <td>Exchange Drift:</td>
                    <td className="font-semibold">
                      <Currency
                        className={
                          invoicedAmount > listoCostAmount
                            ? 'text-black'
                            : 'text-red'
                        }
                        value={invoicedAmount - listoCostAmount}
                        currencyCode="USD"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        {(forex != null && (
          <div className="flex flex-row items-stretch space-x-2 pt-3">
            <ReactSelect
              className="flex-1 react-select"
              value={selectedAccount}
              options={accountOptions}
              onChange={(value) => setSelectedAccount(value)}
              getOptionValue={(option) => option.value}
              placeholder="Choose a bank account"
            />

            <Button
              text={
                selectedAccount?.value?.pingPongStatus?.found ===
                PingPongFoundStatus.FOUND
                  ? 'Send payment'
                  : 'Sync'
              }
              loading={sending || createRecipientLoading}
              onClick={async () => {
                if (
                  selectedAccount?.value?.pingPongStatus?.found ===
                  PingPongFoundStatus.FOUND
                ) {
                  send({ distributionId });
                } else {
                  await createPingPongRecipient({
                    bankAccountId: selectedAccount?.value?.id,
                  });
                  onChange();
                  refetchPingPongStatus();
                }
              }}
            />

            {workerProfileId &&
              selectedAccount &&
              selectedAccount?.value?.pingPongStatus?.found !==
                PingPongFoundStatus.FOUND && (
                <Button
                  text="Edit Bank"
                  onClick={async () => {
                    navigate(
                      `/worker-profiles/${workerProfileId}/bank-accounts/${selectedAccount?.value?.id}`,
                    );
                  }}
                />
              )}
          </div>
        )) || <div>Error calculating rate</div>}
      </div>
    </div>
  );
}
