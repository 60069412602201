import create from 'zustand';

type NotifyFunction = (title: string, message: string) => void;
type NotificationState = {
  show: boolean;
  content: { type: string; title: string; message: string };
  setNotification: (content: {
    type: string;
    title: string;
    message: string;
  }) => void;
  notifyError: NotifyFunction;
  notifySuccess: NotifyFunction;
  hide: () => void;
  autoDismissMs: number;
};

const useNotificationBase = create<NotificationState>((set) => {
  const notifyByType =
    (type: 'error' | 'success') => (title: string, message: string) =>
      set(() => ({
        content: { type, title, message },
        show: true,
      }));
  return {
    show: false,
    setShow: (show: boolean) => set(() => ({ show })),
    content: { type: 'success', title: '', message: '' },
    hide: () => set(() => ({ show: false })),
    setNotification: (content: {
      type: string;
      title: string;
      message: string;
    }) => set(() => ({ content, show: true })),
    notifyError: notifyByType('error'),
    notifySuccess: notifyByType('success'),
    autoDismissMs: 2500,
  };
});

export function useNotification<R = [NotifyFunction, NotifyFunction]>(
  stateGetter?: (state: NotificationState) => R,
): R {
  if (stateGetter) {
    return useNotificationBase(stateGetter);
  } else {
    return useNotificationBase((s) => [s.notifySuccess, s.notifyError]) as R;
  }
}
