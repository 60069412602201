import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { trpc } from '../../lib/trpc';

export function MarkAsDistributed({ payPeriodId }: { payPeriodId: string }) {
  const setNotification = useNotification((state) => state.setNotification);
  const utils = trpc.useContext();

  const { mutate: markAsDistributed } =
    trpc.a.payPeriods.markDistributed.useMutation({
      onSuccess: () => {
        utils.a.payPeriods.payPeriod.invalidate(payPeriodId);
        setNotification({
          title: 'Success',
          message: 'Pay period marked as distributed. Email sent',
          type: 'success',
        });
      },
    });

  return (
    <ActionPanel
      title="Mark as Distributed"
      content="Updates the status of the pay period to distributed. Sends and email to the client indicating that the payments are on their way."
    >
      <Button
        text="Distributed"
        onClick={() => markAsDistributed({ payPeriodId })}
      />
    </ActionPanel>
  );
}
