import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import useSidebar from '../../hooks/useSidebar';

interface HeaderProps {
  title: string;
  buttonText?: string;
  buttonRoute?: string;
}

export function Header({ title, buttonText, buttonRoute }: HeaderProps) {
  const { sidebarIsOpen, setSidebarIsOpen } = useSidebar();
  const navigate = useNavigate();

  return (
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="py-6 flex items-center justify-between lg:border-t lg:border-gray-200">
          <div className="flex-1 min-w-0">
            <div className="flex items-center">
              <div>
                <div className="flex items-center">
                  <button
                    type="button"
                    className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                    onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3CenterLeftIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </button>

                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    {title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {buttonText && buttonRoute && (
            <div className="mt-0 flex space-x-3 ml-4">
              <button
                onClick={() => {
                  navigate(buttonRoute);
                }}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
