import { useEffect } from 'react';
import { APP_URL } from '../lib/constants';

export function impersonate(jwt: string, redirectionURL?: string) {
  const frame = document.getElementById('appIframe') as HTMLIFrameElement;

  if (frame && frame.contentWindow) {
    frame.contentWindow.postMessage(
      {
        event: 'impersonate',
        data: {
          jwt,
          redirectionURL,
        },
      },
      APP_URL,
    );
  }
}

function listenForImpersonationSuccessRedirect(event: MessageEvent) {
  if (event.origin === APP_URL && event.data.event === 'app.redirect')
    window.location.href = event.data.data.redirectionURL || APP_URL;
}

export default function useImpersonate() {
  useEffect(() => {
    window.addEventListener('message', listenForImpersonationSuccessRedirect);

    return () => {
      window.removeEventListener(
        'message',
        listenForImpersonationSuccessRedirect,
      );
    };
  }, []);
}
