import Header from '../../components/Header';
import { Mrr } from './Mrr';

export function Internal() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="MRR" />
      <div className="m-8 flex flex-col">
        <Mrr />
      </div>
    </div>
  );
}
