import { zodResolver } from '@hookform/resolvers/zod';
import {
  CreatePayPeriodType,
  createPayPeriodZod,
} from 'listo/src/zodObjects/payPeriods';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'ui/src/components/Button';
import { SelectInput } from 'ui/src/components/SelectInput';
import { DateInput } from 'ui/src/components/DateInput';
import { useEffect } from 'react';
import { dateTimeOf, formatIsoLocalDate } from 'listo/src/utils/dates';
import { plusBusinessDays } from 'listo/src/utils/business-days';

import { DateTime } from 'luxon';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

export function PayPeriodCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { data: clients } = trpc.a.clients.list.useQuery({});

  const utils = trpc.useContext();

  const { mutate: createPayPeriod, isLoading: createPayPeriodLoading } =
    trpc.a.payPeriods.create.useMutation({
      onSuccess: () => {
        utils.a.payPeriods.list.invalidate();
        navigate('/pay-periods');
      },
    });

  const generateDefaultValuesFromURLParams = () => {
    let normalizedObj = {};
    const formattedSearchParams = searchParams.entries();
    const copiedPayPeriodArray = Array.from(formattedSearchParams);

    copiedPayPeriodArray.forEach(([key, value]) => {
      normalizedObj = Object.assign(normalizedObj, { [key]: value });
    });

    return normalizedObj;
  };

  const defaultValues = generateDefaultValuesFromURLParams();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    ...payPeriodForm
  } = useForm<CreatePayPeriodType>({
    resolver: zodResolver(createPayPeriodZod),
    defaultValues,
  });

  const periodEnd = watch('periodEnd');

  const { setValue } = payPeriodForm;
  useEffect(() => {
    const setIfPresent = (
      field: keyof CreatePayPeriodType,
      value: DateTime,
    ) => {
      const formattedDate = formatIsoLocalDate(value);
      if (formattedDate) {
        setValue(field, formattedDate);
      }
    };
    const periodEndDate = dateTimeOf(periodEnd);
    if (periodEndDate) {
      setIfPresent('approvalDate', plusBusinessDays(periodEndDate, 1));
      setIfPresent('invoiceDate', plusBusinessDays(periodEndDate, 2));
      setIfPresent('processDate', plusBusinessDays(periodEndDate, 4));
      setIfPresent('distributeDate', plusBusinessDays(periodEndDate, 7));
    }
  }, [periodEnd]);

  const onSubmit: SubmitHandler<CreatePayPeriodType> = (data) =>
    createPayPeriod(data);

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Create Pay Period" />
      <div className="align-middle sm:m-8 m-2">
        <div className="bg-white shadow sm:rounded-lg px-4 sm:px-6 lg:px-8 py-8">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div className="flex justify-between">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Pay Period
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Enter pay period details
                    </p>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="Client"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Client
                    </label>
                    <Controller
                      name="clientIds"
                      control={control}
                      render={({ field }) => (
                        <Select
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          options={
                            clients?.map((client) => ({
                              label: client.name,
                              value: client.id,
                            })) || []
                          }
                          isMulti
                        />
                      )}
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <DateInput
                      label="Period Start"
                      reactHookForm={{
                        fieldName: 'periodStart',
                        ...payPeriodForm,
                        errors,
                      }}
                      cols={2}
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <DateInput
                      label="Period End"
                      reactHookForm={{
                        fieldName: 'periodEnd',
                        ...payPeriodForm,
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <DateInput
                      label="Approval Date"
                      reactHookForm={{
                        ...payPeriodForm,
                        fieldName: 'approvalDate',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <DateInput
                      label="Invoice Date"
                      reactHookForm={{
                        ...payPeriodForm,
                        fieldName: 'invoiceDate',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <DateInput
                      label="Process Date"
                      reactHookForm={{
                        ...payPeriodForm,
                        fieldName: 'processDate',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <DateInput
                      label="Distribute Date"
                      reactHookForm={{
                        ...payPeriodForm,
                        fieldName: 'distributeDate',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <SelectInput
                      label="Pay Period Type"
                      defaultOption={
                        { label: 'Select Pay Period Type', value: '' } as const
                      }
                      options={[
                        { label: 'Monthly', value: 'MONTHLY' },
                        { label: 'Semi-Monthly', value: 'SEMI_MONTHLY' },
                      ]}
                      reactHookForm={{
                        ...payPeriodForm,
                        fieldName: 'payPeriodType',
                        errors,
                      }}
                    />
                  </div>
                </div>
                <div className="mt-8 pt-4">
                  <div className="flex justify-end">
                    <Button
                      text="Cancel"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate('/distributions');
                      }}
                      variant="secondary"
                    />
                    <Button
                      loading={createPayPeriodLoading}
                      text="Save"
                      type="submit"
                      className="ml-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
