import { z } from 'zod';

export const chargeTypes = [
  'EOR_WAGES',
  'EOR_FEE',
  'CONTRACTOR_WAGES',
  'CONTRACTOR_FEE',
  'BACKGROUND_CHECK',
  'MISC',
] as const;

export const invoiceForm = z.object({
  payPeriodId: z.string().optional(),
  externalProvider: z.enum(['STRIPE', 'QUICKBOOKS']),
  lineItems: z.array(
    z.object({
      chargeType: z.enum(chargeTypes),
      contractId: z.string().uuid(),
      description: z.string(),
      amountInCents: z.preprocess(
        (val) => parseFloat(val as string),
        z.number().min(1),
      ),
    }),
  ),
});

export type InvoiceForm = z.infer<typeof invoiceForm>;
