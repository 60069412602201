import { DocumentIcon } from '@heroicons/react/24/outline';
import { UploadedFile } from '../../types/files';
import Button from '../Button';

type FilePreviewProps = {
  file: UploadedFile;
  readOnly?: boolean;
  cancel?: React.MouseEventHandler<HTMLButtonElement>;
};

export function FilePreview({ file, cancel, readOnly }: FilePreviewProps) {
  const handleCancel: React.MouseEventHandler<HTMLButtonElement> = (e) =>
    cancel && cancel(e);
  const download = () => {
    const { url } = file;
    window.open(url, '_blank');
  };

  return (
    <div className="flex">
      <div className="bg-white rounded ring-1 ring-black ring-opacity-5 mr-4 inset-0 flex items-end p-4 pointer-events-none">
        <DocumentIcon className="h-6 w-6 text-gray-400" />
      </div>
      <div className="mr-2">
        <h4 className="text-md font-bold">{file.fileName}</h4>
        <p className="text-sm text-gray-400">{file.mimeType}</p>
      </div>
      {!readOnly && (
        <Button text="Cancel" className="h-38-px" onClick={handleCancel} />
      )}
      {readOnly && (
        <Button text="Download" className="h-38-px" onClick={download} />
      )}
    </div>
  );
}
