import { TrashIcon } from '@heroicons/react/24/outline';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { formatCentsToDollars } from 'listo/src/utils/currency';
import { titleCase } from 'listo/src/utils/strings';
import { unionBy } from 'lodash';
import { useMemo, useReducer, useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AlertModal from 'ui/src/components/AlertModal';
import { useDebounce } from 'ui/src/hooks/useDebounce';

import { formatDate } from 'listo/src/utils/dates';
import Header from '../../components/Header';
import Table from '../../components/Table/Table';
import { RouterOutput, trpc } from '../../lib/trpc';
import { SelectedFilter } from './DistributionFilters';
import { PaginateContext } from '../../components/Table/Pagination';

type Distribution =
  RouterOutput['a']['distributions']['list']['distributions'][0];

const columnHelper = createColumnHelper<Distribution>();

function TableViewBtn({ distribution }: { distribution: Distribution }) {
  const navigate = useNavigate();
  return (
    <>
      <button
        type="button"
        className="text-indigo-600 hover:text-indigo-900"
        onClick={() => {
          navigate(`${distribution.id}`);
        }}
      >
        View
      </button>
      &nbsp;&nbsp;
      {distribution.status === 'GENERATED' && (
        <button
          type="button"
          className="text-indigo-600 hover:text-indigo-900"
          onClick={() => {
            navigate(`${distribution.id}/${distribution.clientId}/edit`);
          }}
        >
          Edit
        </button>
      )}
    </>
  );
}

function TableDeleteBtn({
  distribution: dist,
}: {
  distribution: Distribution;
}) {
  const [distribution, setDistribution] = useState<
    RouterOutput['a']['distributions']['list']['distributions'][0] | undefined
  >();
  const [open, setOpen] = useState(false);
  const utils = trpc.useContext();

  const { mutate: deleteDistribution } =
    trpc.a.distributions.delete.useMutation({
      onSuccess: () => {
        setOpen(false);
        utils.a.distributions.list.invalidate().catch(() => {});
      },
    });

  return (
    <>
      <AlertModal
        open={open}
        setOpen={setOpen}
        title="Delete?"
        description={`Are you sure you want to delete distribution for ${titleCase(
          distribution?.workerProfile?.fullName,
        )}? This action cannot be undone.`}
        onConfirm={() => {
          if (distribution) deleteDistribution(distribution.id);
        }}
      />
      <TrashIcon
        data-testid="delete-distribution"
        className="h-6 text-gray-400 cursor-pointer w-5 m-2"
        aria-hidden="true"
        onClick={() => {
          setDistribution(dist);
          setOpen(true);
        }}
      />
    </>
  );
}

const columns: ColumnDef<Distribution, string>[] = [
  columnHelper.display({
    id: 'worker',
    header: 'Worker',
    cell: ({ row: { original: distribution } }) => (
      <>
        <div>
          <Link
            className="text-indigo-900"
            to={`/contracts/${distribution.contractId}`}
          >
            {titleCase(distribution.workerProfile.fullName ?? '')}
          </Link>
        </div>
        <div className="text-xs">
          {titleCase(distribution.client?.name ?? '')}
        </div>
      </>
    ),
  }),
  {
    accessorFn: (row) =>
      row?.payPeriod
        ? `${formatDate(row.payPeriod.periodStart)} - ${formatDate(
            row.payPeriod.periodEnd,
          )}`
        : '',
    id: 'payPeriod',
    header: 'Pay Period',
    cell: (info) => info.getValue(),
  },
  columnHelper.display({
    id: 'amountInCents',
    header: () => <span>Amount</span>,
    cell: ({ row: { original } }) => (
      <>
        <div>
          {formatCentsToDollars(original.amountInCents, original.currency)}
        </div>
        <div className="text-xs">{original.contract.distributionCurrency}</div>
      </>
    ),
  }),
  columnHelper.display({
    id: 'payMethod',
    header: () => <span>Pay Method</span>,
    cell: ({ row: { original } }) => {
      switch (original.contract.distributionMethod) {
        case 'PING_PONG':
          return <div>Ping Pong</div>;
        default:
          return <div>{titleCase(original.contract.distributionMethod)}</div>;
      }
    },
  }),
  columnHelper.display({
    id: 'actions',
    header: () => <span className="sr-only">Actions</span>,
    cell: (props) => (
      <div className="flex justify-end">
        <TableViewBtn distribution={props.row.original} />
        <TableDeleteBtn distribution={props.row.original} />
      </div>
    ),
  }),
];

const sortOptions = [
  { label: 'created (Desc)', value: 'createdAt.desc' },
  { label: 'created (Asc)', value: 'createdAt.asc' },
];

function selectedFiltersReducer(
  state: SelectedFilter[],
  action: SelectedFilter,
) {
  const filters = unionBy([action, ...state], 'optionLabel');
  return filters;
}

function variables({
  searchTerm,
  sort,
  selectedFilters,
  page,
}: {
  searchTerm: string | undefined;
  sort: string;
  selectedFilters: SelectedFilter[];
  page?: number;
}) {
  const clientFilters = selectedFilters
    .filter((filter) => !!filter.optionValue && filter.filterName === 'Clients')
    .map((filter) => filter.optionValue as string);
  const statusFilters = selectedFilters
    .filter((filter) => !!filter.optionValue && filter.filterName === 'Status')
    .map((filter) => filter.optionValue as string);

  return {
    page,
    limit: 10,
    searchTerm,
    sort,
    filters: {
      clients: clientFilters ?? [],
      status: statusFilters ?? [],
    },
  };
}

export function Distributions() {
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterByTerm, setFilterByTerm] = useState('');
  const [sort, setSort] = useState('createdAt.desc');

  const debouncedSearchTerm = useDebounce<string>(searchTerm, 275);
  const debouncedFilterTerm = useDebounce<string>(filterByTerm, 275);
  const [urlParams] = useSearchParams();

  // Read filters from querystring values.  Currently only supports a single filter per key.
  const urlFilters = ['clients', 'status']
    .filter((p) => urlParams.has(p))
    .map((paramName) => ({
      optionValue: urlParams.get(paramName),
      optionLabel: '',
      filterName: titleCase(paramName),
    }));

  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    selectedFiltersReducer,
    urlFilters,
  );

  const { data, isLoading, error } = trpc.a.distributions.list.useQuery(
    variables({ page, searchTerm: debouncedSearchTerm, sort, selectedFilters }),
  );

  const { data: clients } = trpc.a.clients.listByName.useQuery({
    searchTerm: debouncedFilterTerm,
  });

  const [searchParams] = useSearchParams();

  const filterByClientId = () => {
    const clientId = searchParams.get('clientId');
    if (clientId) {
      const clientInfo = clients?.find((client) => client.id === clientId);
      dispatchSelectedFilters({
        filterName: 'Clients',
        optionLabel: clientInfo?.name ?? '',
        optionValue: clientId,
      });
    }
  };

  const paginationData = useMemo(
    () => ({
      setPage,
      currentPage: page,
      nextPage: data?.nextPage,
      prevPage: data?.prevPage,
      total: data?.total,
      pageSize: data?.pageSize,
    }),
    [page, data],
  );

  useEffect(() => {
    filterByClientId();
  }, []);

  function getClientsOptions() {
    if (!clients) {
      return [];
    }
    return clients.map((client) => ({
      label: client.name,
      value: client.id,
    }));
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header
        title="Distributions"
        buttonRoute="/distributions/create"
        buttonText="Create"
      />
      <div className="m-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <PaginateContext.Provider value={paginationData}>
              <Table
                data={data?.distributions || []}
                columns={columns}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                error={error}
                isLoading={isLoading}
                tableType="Distributions"
                tableFilterProps={{
                  sort: {
                    name: 'Sort',
                    options: sortOptions,
                  },
                  filters: [
                    {
                      name: 'Clients',
                      options: getClientsOptions(),
                    },
                    {
                      name: 'Status',
                      options: [{ label: 'Not Sent', value: 'GENERATED' }],
                    },
                  ],
                  currentFilters: selectedFilters,
                  setCurrentFilters: dispatchSelectedFilters,
                  sortValue: sort,
                  setSortValue: setSort,
                  filterByTermValue: filterByTerm,
                  setFilterByTermValue: setFilterByTerm,
                }}
              />
            </PaginateContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
}
