import { zodResolver } from '@hookform/resolvers/zod';
import { countries } from 'listo/src/countries';
import {
  UpdateBankInfoType,
  updateBankInfoZod,
} from 'listo/src/zodObjects/bankAccounts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { Input } from 'ui/src/components/Input';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { SelectInput } from 'ui/src/components/SelectInput';
import { PingPongFoundStatus } from 'listo/src/pingPong.shared-types';
import { BankAccountNumber } from 'ui/src/components/BankAccountNumber';
import { Field } from 'ui/src/components/Field';
import { requiresIban } from 'listo/src/utils/currency';
import { useEffect, useMemo } from 'react';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';
import { BankAccountExtraFields } from './BankAccountExtraFields';
import { Spinner } from '../../components/Spinner/Spinner';
import { usePingPongCreateRecipient } from '../../hooks/usePingPong';

export default function EditBankNumber() {
  const { bankAccountId } = useParams();
  const { workerProfileId } = useParams();
  const setNotification = useNotification((state) => state.setNotification);
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const { data: pingPongStatus, refetch: pingPongRefresh } =
    trpc.a.bankAccounts.getPingPongStatus.useQuery(bankAccountId as string);

  const { mutate: updateBankAccount, isLoading: updateLoading } =
    trpc.a.bankAccounts.updateBankAccount.useMutation({
      onSuccess: () => {
        utils.a.bankAccounts.bankAccount.invalidate();
        pingPongRefresh();
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Bank account information updated',
        });
      },
    });

  const { createRecipientLoading, createPingPongRecipient } =
    usePingPongCreateRecipient();

  const {
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors, dirtyFields },
    reset,
    resetField,
  } = useForm<UpdateBankInfoType>({
    resolver: zodResolver(updateBankInfoZod),
    defaultValues: {
      country: 'US',
      externalData: {},
    },
  });

  const {
    data: bankAccount,
    isLoading,
    error,
  } = trpc.a.bankAccounts.bankAccount.useQuery(
    { bankAccountId: bankAccountId as string },
    {
      onSuccess: ({ externalData, ...value }) =>
        reset({
          ...value,
          externalData: externalData ?? {},
        } as UpdateBankInfoType),
    },
  );

  const country = watch('country');
  const externalData = watch('externalData');
  const onSubmit: SubmitHandler<UpdateBankInfoType> = (data) => {
    updateBankAccount({
      fields: data,
      workerProfileId: workerProfileId as string,
      bankAccountId,
    });
  };
  const isIbanCountry = useMemo(() => requiresIban(country), [country]);

  // Resets the default to pull from iban field.  We're getting rid of iban eventually, so this should be
  // deleted at some point
  // todo:ericm delete this at some point
  useEffect(() => {
    if (bankAccount) {
      const { iban, number } = bankAccount;
      if (!dirtyFields.number) {
        const newValue = isIbanCountry
          ? externalData?.iban ?? iban ?? number
          : number;
        resetField('number', {
          defaultValue: newValue,
          keepDirty: false,
          keepTouched: false,
        });
      }
    }
  }, [isIbanCountry]);

  if (isLoading) return <Loader />;
  if (error) return <div>{error.message}</div>;
  if (bankAccount === undefined) return <Loader />;

  if (bankAccount === null)
    return (
      <div>Unable to update bank account information, please try again.</div>
    );

  const isPingPongFound = pingPongStatus?.found === PingPongFoundStatus.FOUND;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Edit Bank Account" />
      <div className="mx-8 my-10">
        <div className="bg-white shadow sm:rounded-lg px-4 py-6">
          <div className="flex justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Bank Account Information
            </h3>
            <div>
              <Button
                text="Worker Profile"
                onClick={() => {
                  navigate(`/workerProfiles/${workerProfileId}`);
                }}
              />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <SelectInput
                  label="Country"
                  defaultValue={bankAccount.country ?? ''}
                  defaultOption={{ label: 'Select', value: '' }}
                  options={countries.map((c) => ({
                    label: c.name,
                    value: c.alpha2,
                  }))}
                  reactHookForm={{
                    register,
                    fieldName: 'country',
                    errors,
                  }}
                />
              </div>
              <div className="sm:col-span-2">
                <Field label="Currency">
                  <input
                    type="text"
                    disabled
                    className="block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={pingPongStatus?.distributionCurrency}
                  />
                </Field>
              </div>
              <div className="sm:col-span-2">
                <SelectInput
                  label="Business or Personal"
                  defaultValue={bankAccount.entityType ?? ''}
                  defaultOption={{ label: 'Select', value: '' }}
                  options={[
                    {
                      label: 'Business',
                      value: 'COMPANY',
                    },
                    {
                      label: 'Personal',
                      value: 'PERSONAL',
                    },
                  ]}
                  reactHookForm={{
                    register,
                    fieldName: 'entityType',
                    errors,
                  }}
                />
              </div>
              <div className="sm:col-span-2">
                <SelectInput
                  label="Checking or Savings"
                  defaultValue={bankAccount.type ?? ''}
                  defaultOption={{ label: 'Select', value: '' }}
                  options={[
                    {
                      label: 'Checking',
                      value: 'CHECKING',
                    },
                    {
                      label: 'Savings',
                      value: 'SAVINGS',
                    },
                  ]}
                  reactHookForm={{
                    register,
                    fieldName: 'type',
                    errors,
                  }}
                />
              </div>
              <BankAccountNumber
                fieldName="bankName"
                currency={pingPongStatus?.distributionCurrency}
                countryCode={country}
                reactHookForm={{ register, fieldName: 'bankName', errors }}
                setValue={(value) => setValue('bankName', value)}
                span={2}
                onAccept={(accept) => {
                  if (accept.bankName) {
                    setValue('bankName', accept.bankName);
                  }

                  const { ...currentExternalDataValues } =
                    getValues('externalData') ?? {};
                  setValue('externalData', {
                    ...currentExternalDataValues,
                    bankCode: accept.bankCode,
                  });
                }}
                trpc={trpc}
              />
              <div className="sm:col-span-2">
                <Input
                  inputType="text"
                  label="Beneficiary Name"
                  inputProps={{
                    defaultValue: bankAccount.ownerName ?? '',
                  }}
                  reactHookForm={{
                    register,
                    fieldName: 'ownerName',
                    errors,
                  }}
                />
              </div>
              <div className="sm:col-span-2">
                <Input
                  inputType="text"
                  label={isIbanCountry ? 'IBAN' : 'Account Number'}
                  inputProps={{
                    defaultValue: '',
                  }}
                  reactHookForm={{
                    register,
                    fieldName: 'number',
                    errors,
                  }}
                />
              </div>
              <div className="sm:col-span-2">
                <Input
                  inputType="text"
                  label="Routing Number"
                  inputProps={{
                    defaultValue: bankAccount.routingNumber ?? '',
                  }}
                  reactHookForm={{
                    register,
                    fieldName: 'routingNumber',
                    errors,
                  }}
                />
              </div>
              {country && (
                <BankAccountExtraFields
                  isoCode={country}
                  workerProfileId={workerProfileId ?? null}
                  onChange={(data) => setValue('externalData', data)}
                  values={externalData}
                />
              )}
            </div>
            <div className="flex justify-end mt-6">
              <Button type="submit" text="Update" loading={updateLoading} />
            </div>
          </form>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-2">
            {(pingPongStatus && (
              <ActionPanel
                title={
                  isPingPongFound
                    ? 'Created in Ping Pong'
                    : 'Create in Ping Pong'
                }
                content={
                  isPingPongFound
                    ? 'This record has been created in PingPong. Some changes may need to be done in their UI'
                    : 'Creates the bank account as a recipient within Ping Pong.'
                }
              >
                <Button
                  text="Add"
                  loading={createRecipientLoading}
                  onClick={() =>
                    createPingPongRecipient({
                      bankAccountId: bankAccount.id,
                    })
                  }
                />
              </ActionPanel>
            )) || <Spinner />}
          </div>
        </div>
      </div>
    </div>
  );
}
