import { Input } from 'ui/src/components/Input';
import { useForm, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { ButtonSpinner } from 'ui/src/components/Spinner/Spinner';
import isEqual from 'lodash/isEqual';
import { trpc } from '../../lib/trpc';

/**
 * These fields are already handled by the Listo UI, so we don't display them a second time.
 */
const IGNORED_PING_PONG_FIELDS = [
  'email',
  'currency',
  'amount',
  'country',
  'bankName',
  'accountName',
  'accountNo',
  'routingNo',
  'cardType',
  'swiftOrBic',
];

/**
 * Displays any extra bank fields required by PingPong
 */
export function BankAccountExtraFields({
  values = {},
  workerProfileId,
  isoCode,
  onChange,
}: {
  values?: SimpleJson;
  isoCode: string;
  workerProfileId: string | null;
  onChange: (data: SimpleJson) => void;
}) {
  const { data: extraFields, isLoading: loadingExtraFields } =
    trpc.a.bankAccounts.getRequiredPingPongFields.useQuery({
      country: isoCode,
      workerProfileId: workerProfileId ?? undefined,
      includeOptional: true,
    });

  const {
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<SimpleJson>({
    values,
  });

  const formValues = useWatch({
    exact: false,
    control,
  });

  useEffect(() => {
    // When the parent component updates values, propagate those here.
    const current = getValues();

    Object.entries(values ?? {}).forEach(([key, value]) => {
      if (!isEqual(value, current[key])) {
        setValue(key, value, {
          shouldTouch: false,
          shouldDirty: false,
          shouldValidate: false,
        });
      }
    });
  }, [values]);

  // Watches all values on this form, and passes them to the parent as a single record.
  useEffect(() => {
    const current = formValues;

    const changedFields = Object.entries(current ?? {})
      .filter(([key, value]) => !isEqual(value, values[key] ?? ''))
      .map(([k]) => k);
    if (changedFields.length > 0) {
      onChange(current);
    }
  }, [formValues]);

  if (loadingExtraFields) {
    return (
      <div className="text-indigo-700 align-bottom h-full">
        <ButtonSpinner />
      </div>
    );
  }
  return (
    <>
      {extraFields?.bankRequiredFields
        ?.filter(({ name }) => !IGNORED_PING_PONG_FIELDS.includes(name))
        ?.map((row) => (
          <div className="sm:col-span-2" key={row.name}>
            <Input
              inputType="text"
              label={row.label}
              infoTooltipId={row.label}
              inputProps={{
                required: row.required,
              }}
              reactHookForm={{
                register,
                fieldName: row.name,
                errors,
              }}
            />
          </div>
        ))}
    </>
  );
}

export type SimpleJson = Record<string, string | number | boolean | undefined>;
