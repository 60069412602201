import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../Button';

function ErrorModal({
  title,
  description,
  open,
  setOpen,
  confirmButtonText = 'Confirm',
  loadingConfirm,
  onConfirm,
}: {
  title: string;
  description: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  confirmButtonText?: string;
  loadingConfirm?: boolean;
  onConfirm: () => void;
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20">
          <div className="flex min-h-full items-end justify-center p-8 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="flex">
                  <div>
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>{' '}
                  &nbsp;
                  <div>
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{description}</p>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-4">
                  <Button
                    type="button"
                    onClick={() => setOpen(false)}
                    variant="secondary"
                    text="Cancel"
                  />

                  <Button
                    type="button"
                    className="bg-red-600 hover:bg-red-700 focus:ring-red-500"
                    onClick={onConfirm}
                    loading={loadingConfirm}
                    text={confirmButtonText}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function SuccessModal({
  title,
  description,
  open,
  setOpen,
  confirmButtonText = 'Ok',
  loadingConfirm,
  onConfirm,
}: {
  title: string;
  description: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  confirmButtonText?: string;
  loadingConfirm?: boolean;
  onConfirm: () => void;
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{description}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Button
                    type="button"
                    onClick={onConfirm}
                    loading={loadingConfirm}
                    text={confirmButtonText}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function AlertModal({
  modalType = 'error',
  title,
  description,
  open,
  setOpen,
  confirmButtonText = 'Confirm',
  loadingConfirm,
  onConfirm,
}: {
  modalType?: 'error' | 'success';
  title: string;
  description: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  confirmButtonText?: string;
  loadingConfirm?: boolean;
  onConfirm: () => void;
}) {
  if (modalType === 'success') {
    return (
      <SuccessModal
        {...{
          modalType,
          title,
          description,
          open,
          setOpen,
          confirmButtonText,
          loadingConfirm,
          onConfirm,
        }}
      />
    );
  }

  return (
    <ErrorModal
      {...{
        modalType,
        title,
        description,
        open,
        setOpen,
        confirmButtonText,
        loadingConfirm,
        onConfirm,
      }}
    />
  );
}
