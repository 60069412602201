import { useState } from 'react';

/**
 * Can be used to track hovered state.  Includes the mouseEnter/mouseLeave event handlers you can plug
 * into the appropriate component(s)
 */
export function useHovered(): [
  boolean,
  { onMouseEnter: VoidFunction; onMouseLeave: VoidFunction },
] {
  const [hovered, setHovered] = useState(false);
  return [
    hovered,
    {
      onMouseEnter: () => setHovered(true),
      onMouseLeave: () => setHovered(false),
    },
  ];
}
