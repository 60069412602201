import { MinusIcon as MinusIconMini } from '@heroicons/react/20/solid';
import { dollarsToCents } from 'listo/src/utils/currency';
import { classNames } from 'listo/src/utils/strings';
import { priceOverrideTypeFormOptions } from 'listo/src/zodObjects/clients';
import {
  FieldArrayWithId,
  FieldErrors,
  FieldValues,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { Input } from 'ui/src/components/Input';
import { SelectInput } from 'ui/src/components/SelectInput';
import { get } from 'lodash';

function LineItem<T extends FieldValues>({
  handleSetCountryOptions,
  control,
  index,
  register,
  errors,
  remove,
  getValues,
  setValue,
}: {
  handleSetCountryOptions: any;
  control: any;
  index: number;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  remove: any;
  getValues: UseFormGetValues<T>;
  setValue: UseFormSetValue<T>;
}) {
  const options = useWatch({ control, name: 'availableCountryOptions' });

  return (
    <div
      className={classNames(
        'grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6 mt-2 sm:py-4',
        index % 2 === 0 ? '' : 'bg-gray-50',
      )}
    >
      <div className="sm:col-span-1">
        <SelectInput
          label="Override Type"
          defaultOption={priceOverrideTypeFormOptions[0]}
          options={priceOverrideTypeFormOptions}
          reactHookForm={{
            register,
            fieldName: `lineItems.${index}.overrideType` as any,
            errors,
          }}
        />
      </div>
      <div className="sm:col-span-2">
        <SelectInput
          label="Country"
          defaultOption={{ label: 'Select a Country', value: '' } as const}
          loading={false}
          options={(
            get(options, getValues(`lineItems.${index}.overrideType` as any)) ||
            []
          ).filter(
            (option: any) =>
              option.available !== false ||
              getValues(`lineItems.${index}.countryCode` as any) ===
                option.value,
          )}
          selectProps={{
            onChange: ({ target: { value } }) => {
              setValue(`lineItems.${index}.countryCode` as any, value as any);
              handleSetCountryOptions(
                getValues(`lineItems.${index}.overrideType` as any),
              );
            },
          }}
          reactHookForm={{
            register,
            fieldName: `lineItems.${index}.countryCode` as any,
            errors,
          }}
        />
      </div>

      <div className="sm:col-span-1">
        <Input
          label="Override Price"
          inputType="text"
          inputProps={{
            placeholder: '0.00',
            onChange: (e) => {
              setValue(
                `lineItems.${index}.amountInCents` as any,
                dollarsToCents(Number(e.target.value)) as any,
              );
              setValue(
                `lineItems.${index}.amount` as any,
                e.target.value as any,
              );
            },
          }}
          reactHookForm={{
            register,
            fieldName: `lineItems.${index}.amount` as any,
            errors,
          }}
        />
      </div>

      <div className="flex sm:col-span-1">
        <div className="flex items-end justify-between">
          <button
            type="button"
            className="ml-4 inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => remove(index)}
          >
            <MinusIconMini className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}

function OverrideRows<T extends FieldValues>({
  handleSetCountryOptions,
  control,
  fields,
  clientId,
  remove,
  register,
  errors,
  getValues,
  setValue,
}: {
  handleSetCountryOptions: any;
  // TYPE?
  control: any;
  fields: FieldArrayWithId<
    {
      lineItems: {
        overrideType: string;
        amountInCents: number;
        countryCode: string;
      }[];
      clientId: string;
    },
    'lineItems',
    'id'
  >[];
  clientId?: string;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  getValues: UseFormGetValues<T>;
  setValue: UseFormSetValue<T>;
}) {
  if (!clientId) return <h1>Select a client before creating line items</h1>;

  return (
    <>
      {fields.map((item, index) => (
        <LineItem
          handleSetCountryOptions={handleSetCountryOptions}
          control={control}
          key={item.id}
          index={index}
          getValues={getValues}
          register={register}
          errors={errors}
          remove={remove}
          setValue={setValue}
        />
      ))}
    </>
  );
}

export default OverrideRows;
