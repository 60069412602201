import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { createContext, useContext } from 'react';

interface PaginateContextType {
  currentPage: number;
  setPage?: (page: number) => void;
  nextPage?: number;
  prevPage?: number;
  total?: number;
  pageSize?: number;
}

export const PaginateContext = createContext<PaginateContextType>({
  currentPage: 1,
});

function PageNumbers({
  totalPages,
  currentPage,
  prevPage,
  setPage,
}: {
  totalPages: number;
  currentPage: number;
  prevPage?: number;
  setPage: (page: number) => void;
}) {
  return (
    <>
      {prevPage === 0 && (
        <button
          type="button"
          onClick={() => setPage(currentPage - 1)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          {currentPage}
        </button>
      )}
      {prevPage !== undefined && prevPage > 0 && (
        <>
          <button
            type="button"
            onClick={() => setPage(currentPage - 2)}
            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            {currentPage - 1}
          </button>
          <button
            type="button"
            onClick={() => setPage(currentPage - 1)}
            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            {currentPage}
          </button>
        </>
      )}
      <button
        type="button"
        aria-current="true"
        className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {currentPage + 1}
      </button>
      {totalPages > currentPage + 1 && (
        <button
          type="button"
          onClick={() => setPage(currentPage + 1)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          {currentPage + 2}
        </button>
      )}

      {totalPages > currentPage + 2 && (
        <button
          type="button"
          onClick={() => setPage(currentPage + 2)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          {currentPage + 3}
        </button>
      )}
    </>
  );
}

export default function Pagination() {
  const { nextPage, setPage, prevPage, total, pageSize, currentPage } =
    useContext(PaginateContext);

  const totalPages =
    total && pageSize ? Math.ceil(total / pageSize) : undefined;

  const currentResultsUpto =
    total && total < (currentPage + 1) * (pageSize ?? 25)
      ? total
      : (currentPage + 1) * (pageSize ?? 25);

  const onNextPage = () => {
    if (nextPage && setPage) {
      setPage(nextPage);
    }
  };

  const onPreviousPage = () => {
    if (prevPage !== null && prevPage !== undefined && setPage) {
      setPage(prevPage);
    }
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          {total && setPage && pageSize && (
            <div>
              <p className="text-sm text-gray-700">
                Showing{' '}
                <span className="font-medium">
                  {currentPage * (pageSize ?? 25) + 1}
                </span>{' '}
                to <span className="font-medium">{currentResultsUpto}</span> of{' '}
                <span className="font-medium">{total}</span> results
              </p>
            </div>
          )}
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              type="button"
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={onPreviousPage}
              disabled={currentPage === 0}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {totalPages && setPage && (
              <PageNumbers
                totalPages={totalPages}
                currentPage={currentPage}
                setPage={setPage}
                prevPage={prevPage}
              />
            )}
            <button
              type="button"
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={onNextPage}
              disabled={currentPage + 1 === totalPages}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
