import UploadBox from '../../components/UploadBox';
import { trpc } from '../../lib/trpc';

export function UploadContract({
  clientId,
  contractId,
}: {
  clientId: string;
  contractId: string;
}) {
  const utils = trpc.useContext();
  const updateContract = trpc.a.contracts.addCustomContract.useMutation({
    onSuccess: () => {
      utils.a.contracts.contract.invalidate(contractId);
    },
  });

  return (
    <div className="lg:col-start-1 lg:col-span-1">
      <section aria-labelledby="timeline-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="sm:px-6 px-4 py-5">
            <h2
              id="timeline-title"
              className="text-lg font-medium text-gray-900 leading-6"
            >
              Upload Custom Contract
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Upload a PDF contract. This contract will be used for signature
              in-place of the Listo contract.
            </p>
          </div>

          <div className="p-4">
            <UploadBox
              clientId={clientId}
              allowedTypes={['.pdf']}
              readOnly={false}
              listoAssetType="customContracts"
              onFileUploaded={(f) => {
                updateContract.mutate({
                  id: contractId,
                  customContractId: f.id,
                });
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
