import { capitalize, startCase, toLower, upperFirst, values } from 'lodash';

const CONTAINS_LOWER = /[a-z]/;
const REPLACE_CHARACTERS = /[_\-]/g;

const NOT_ALPHA_NUMERIC = /[^a-zA-Z0-9]/gim;

export function titleCase(string?: string | null) {
  if (!string) return '';

  const baseString = CONTAINS_LOWER.test(string) ? string : toLower(string);
  return baseString
    .replaceAll(REPLACE_CHARACTERS, ' ')
    .replace(/\w+/g, upperFirst);
}

export function firstCharacters(string?: string | null, length = 2) {
  if (!string) return '';
  return string
    .split(' ')
    .map((word) => word[0])
    .join('');
}

export function splitNames(fullName: string | null | undefined) {
  if (!fullName) return {};
  const [firstName, lastName] = fullName.split(' ');

  return { firstName, lastName };
}

export function truthyToYesNo(value?: unknown) {
  return value ? 'Yes' : 'No';
}

export function getShortId(id: string) {
  return id.split('-')[0];
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function removeEnd(value: string, toRemove: string): string {
  if (!value || toRemove.length === 0) return value;
  while (value.endsWith(toRemove)) {
    value = value.substring(0, value.length - toRemove.length);
  }

  return value;
}
export function removeStart(value: string, toRemove: string): string {
  if (!value || toRemove.length === 0) return value;
  while (value.startsWith(toRemove)) {
    value = value.substring(toRemove.length);
  }

  return value;
}
export function removeSurrounding(value: string, toRemove: string): string {
  return removeEnd(removeStart(value, toRemove), toRemove);
}

export function stripNonAlphNumeric(input: unknown): string {
  return (input || '').toString().replaceAll(NOT_ALPHA_NUMERIC, '');
}
