import { classNames } from 'listo/src/utils/strings';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

const TABS = [
  { prettyName: 'Client Info', key: 'info' },
  { prettyName: 'Price Overrides', key: 'price-overrides' },
];

export default function ClientTabs() {
  const { id } = useParams();
  const { pathname } = useLocation();

  const {
    data: client,
    isLoading,
    error,
  } = trpc.a.clients.client.useQuery(id as string);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;

  const currentTab = TABS.find((tab) => pathname.includes(tab.key))?.key;
  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Client" />
      <div className="min-w-full py-2 align-middle md:px-6 lg:px-8 my-8">
        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={
                TABS.find((tab) => tab.key === currentTab)?.prettyName
              }
            >
              {TABS.map((tab) => (
                <option key={tab.prettyName}>{tab.prettyName}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {TABS.map((tab) => (
                  <Link
                    key={tab.prettyName}
                    to={`/clients/${client.id}/${tab.key}`}
                    className={classNames(
                      tab.key === currentTab
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                    )}
                  >
                    {tab.prettyName}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
