import { zodResolver } from '@hookform/resolvers/zod';
import { ClientFields, clientFields } from 'listo/src/zodObjects/clients';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import AlertModal from 'ui/src/components/AlertModal';
import { trpc } from '../../lib/trpc';

export function EditClient() {
  const { id } = useParams();
  if (!id) throw new Error('No id provided');

  const {
    data: client,
    isLoading,
    error,
  } = trpc.a.clients.client.useQuery(id as string);

  const utils = trpc.useContext();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { mutate: updateClient } = trpc.a.clients.update.useMutation({
    onSuccess: () => {
      utils.a.clients.client.invalidate(id as string).then(() => {
        navigate('/clients');
      });
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ClientFields>({ resolver: zodResolver(clientFields) });

  const onSubmit: SubmitHandler<ClientFields> = (data) => {
    updateClient({ fields: data, id: id as string });
  };

  const { mutate: deleteClients } = trpc.a.clients.delete.useMutation({
    onSuccess: () => {
      utils.a.clients.list.invalidate().then(() => {
        navigate('/clients');
      });
    },
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;

  return (
    <div>
      <div className="min-w-full py-2 align-middle md:px-6 lg:px-8 my-8">
        <AlertModal
          open={open}
          setOpen={setOpen}
          title={`Delete ${client.name}?`}
          description={`Are you sure you want to delete ${client.name}? This action cannot be undone.`}
          onConfirm={() => {
            deleteClients(client.id);
          }}
        />
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Client Information
              </h3>
              <p className="mt-1 text-sm text-gray-500">Edit client info.</p>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    defaultValue={client.name}
                    {...register('name')}
                  />
                  {errors.name ? (
                    <p className="mt-2 text-sm text-red-600" id="name-error">
                      {errors.name.message}
                    </p>
                  ) : null}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Disposition
                  </label>
                  {client.disposition ? (
                    <select
                      id="disposition"
                      defaultValue={client.disposition}
                      {...register('disposition')}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="ACTIVE">Active</option>
                      <option value="INACTIVE">Inactive</option>
                    </select>
                  ) : null}
                </div>

                <div className="col-span-3">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="block text-sm font-medium text-gray-700">
                    Actions
                  </label>
                  <div className="mt-1 flex items-center">
                    <button
                      type="button"
                      className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(true)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
