import { z } from 'zod';

export const PayPeriodTypes = z.enum(['MONTHLY', 'SEMI_MONTHLY']);

export const createPayPeriodZod = z.object({
  clientIds: z.array(
    z.object({
      value: z.string(),
      label: z.string(),
    }),
  ),
  periodStart: z.string().min(1, { message: 'Period start is required' }),
  periodEnd: z.string().min(1, { message: 'Period end is required' }),
  approvalDate: z.string().min(1, { message: 'Approval date is required' }),
  invoiceDate: z.string().min(1, { message: 'Invoice date is required' }),
  processDate: z.string().min(1, { message: 'Process date is required' }),
  distributeDate: z.string().min(1, { message: 'Distribute date is required' }),
  payPeriodType: PayPeriodTypes,
});

export type CreatePayPeriodType = z.infer<typeof createPayPeriodZod>;
