import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import ConfirmDialog from '../../components/ConfirmDialog';
import { trpc } from '../../lib/trpc';

export function DeletePayPeriod({ payPeriodId }: { payPeriodId: string }) {
  const setNotification = useNotification((state) => state.setNotification);
  const utils = trpc.useContext();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const { mutate: deletePayPeriod } = trpc.a.payPeriods.delete.useMutation({
    onSuccess: () => {
      utils.a.payPeriods.list.invalidate();
      setNotification({
        title: 'Success',
        message: 'Pay period Deleted Successfully',
        type: 'success',
      });
      navigate('/pay-periods');
    },
  });

  const handleSubmit = useCallback(() => {
    deletePayPeriod(payPeriodId);
  }, [payPeriodId]);

  return (
    <ActionPanel title="Delete Pay Period" content="Delete the Pay Period">
      <Button text="Delete" onClick={() => setOpen(true)} />
      <ConfirmDialog
        title="Delete Pay Period"
        description="Are you sure you want to delete? This cannot be undone."
        actionLabel="Delete"
        open={open}
        setOpen={setOpen}
        handleSubmit={handleSubmit}
      />
    </ActionPanel>
  );
}
