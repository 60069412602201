import { createContext, useContext, useState } from 'react';

interface SidebarContext {
  sidebarIsOpen: boolean;
  setSidebarIsOpen: (value: boolean) => void;
}

const sidebarContext = createContext<SidebarContext>({
  sidebarIsOpen: false,
  setSidebarIsOpen: () => {},
});

function useSidebar() {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  return {
    sidebarIsOpen,
    setSidebarIsOpen,
  };
}

export function SidebarProvider({ children }: { children: React.ReactNode }) {
  const sidebar = useSidebar();

  return (
    <sidebarContext.Provider value={sidebar}>
      {children}
    </sidebarContext.Provider>
  );
}

export default function SidebarConsumer() {
  return useContext(sidebarContext);
}
