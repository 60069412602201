import { MinusIcon as MinusIconMini } from '@heroicons/react/20/solid';
import {
  formatCentsToDollars,
  formatPaymentAmount,
  centsToDollars,
  dollarsToCents,
} from 'listo/src/utils/currency';
import { classNames, titleCase } from 'listo/src/utils/strings';
import { chargeTypes } from 'listo/src/zodObjects/invoices';
import { useEffect, useState } from 'react';
import {
  FieldArrayWithId,
  FieldErrors,
  FieldValues,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { Input } from 'ui/src/components/Input';
import { SelectInput } from 'ui/src/components/SelectInput';
import { trpc } from '../../lib/trpc';

function LineItem<T extends FieldValues>({
  clientId,
  payPeriodId,
  index,
  register,
  errors,
  remove,
  getValues,
  setValue,
}: {
  clientId: string;
  payPeriodId?: string;
  index: number;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  remove: any;
  getValues: UseFormGetValues<T>;
  setValue: UseFormSetValue<T>;
}) {
  const [engagementType, setEngagementType] = useState<
    'CONTRACTOR' | 'EOR' | undefined
  >();
  const {
    refetch,
    isLoading,
    data: contractz,
  } = trpc.a.contracts.list.useQuery(
    { clientId, engagementType },
    {
      enabled: false,
    },
  );

  const { mutateAsync: calculateLineItem } =
    trpc.a.invoices.calculateLineItemForPeriod.useMutation();

  useEffect(() => {
    refetch();
  }, [engagementType]);

  return (
    <div
      className={classNames(
        'grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6 mt-2 sm:py-4',
        index % 2 === 0 ? '' : 'bg-gray-50',
      )}
    >
      <div className="sm:col-span-1">
        <SelectInput
          label="Charge Type"
          defaultOption={{ label: 'Select a Charge Type', value: '' } as const}
          selectProps={{
            onChange: ({ target: { value } }) => {
              if (value.includes('CONTRACTOR')) {
                setEngagementType('CONTRACTOR');
                return;
              }
              if (value.includes('EOR')) {
                setEngagementType('EOR');
                return;
              }
              setEngagementType(undefined);
            },
          }}
          options={chargeTypes.map((c) => ({
            value: c,
            label: titleCase(c),
          }))}
          reactHookForm={{
            register,
            fieldName: `lineItems.${index}.chargeType` as any,
            errors,
          }}
        />
      </div>
      <div className="sm:col-span-2">
        <SelectInput
          label="Contract"
          defaultOption={{ label: 'Select a Contract', value: '' } as const}
          loading={isLoading}
          options={
            contractz?.map((c) => ({
              value: c.id,
              label: `${titleCase(
                c.workerProfile.fullName,
              )} - ${formatPaymentAmount(
                c.billingAmountInCents,
                c.billingFrequency,
                c.billingCurrency,
              )}`,
            })) || []
          }
          selectProps={{
            onChange: ({ target: { value } }) => {
              calculateLineItem({
                contractId: value,
                chargeType: getValues().lineItems[index].chargeType,
                payPeriodId,
              })
                .then((res) => {
                  setValue(
                    `lineItems.${index}.amountInCents` as any,
                    (res.amountInCents / 100) as any,
                  );
                  setValue(
                    `lineItems.${index}.description` as any,
                    res.description as any,
                  );
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error('error ', err);
                });
            },
          }}
          reactHookForm={{
            register,
            fieldName: `lineItems.${index}.contractId` as any,
            errors,
          }}
        />
      </div>

      <div className="sm:col-span-2">
        <Input
          label="Description"
          inputType="text"
          reactHookForm={{
            register,
            fieldName: `lineItems.${index}.description` as any,
            errors,
          }}
        />
      </div>

      <div className="sm:col-span-1">
        <div className="flex items-center justify-between">
          <Input
            label="Amount"
            inputType="text"
            inputProps={{
              step: '0.01',
              defaultValue: centsToDollars(
                Number(getValues().lineItems[index].amountInCents),
              ),
              onChange: (e) => {
                setValue(
                  `lineItems.${index}.amountInCents` as any,
                  dollarsToCents(Number(e.target.value)) as any,
                );
              },
            }}
          />

          <button
            type="button"
            className="ml-4 inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => remove(index)}
          >
            <MinusIconMini className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}

function LineItems<T extends FieldValues>({
  fields,
  clientId,
  payPeriodId,
  remove,
  register,
  errors,
  getValues,
  setValue,
}: {
  fields: FieldArrayWithId<
    {
      lineItems: {
        contractId: string;
        description: string;
        amountInCents: number;
      }[];
      periodStart: Date;
      periodEnd: Date;
    },
    'lineItems',
    'id'
  >[];
  clientId?: string;
  payPeriodId?: string;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  getValues: UseFormGetValues<T>;
  setValue: UseFormSetValue<T>;
}) {
  if (!clientId) return <h1>Select a client before creating line items</h1>;

  return (
    <>
      {fields.map((item, index) => (
        <LineItem
          clientId={clientId}
          payPeriodId={payPeriodId}
          key={item.id}
          index={index}
          getValues={getValues}
          register={register}
          errors={errors}
          remove={remove}
          setValue={setValue}
        />
      ))}
    </>
  );
}

export function calculateTotal(
  lineItems: {
    contractId: string;
    description: string;
    amountInCents: number;
  }[],
) {
  const totalInCents = lineItems.reduce((acc, item) => {
    const floatBecauseTypescriptLiedToMe =
      typeof item.amountInCents === 'string'
        ? parseFloat(item.amountInCents)
        : item.amountInCents;

    return acc + floatBecauseTypescriptLiedToMe;
  }, 0);

  return formatCentsToDollars(totalInCents, 'USD');
}

export default LineItems;
