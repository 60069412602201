import { useCallback, useState } from 'react';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { useNavigate } from 'react-router-dom';

import { trpc } from '../../lib/trpc';
import ConfirmDialog from '../../components/ConfirmDialog';

export default function DeleteContract({ contractId }: { contractId: string }) {
  const utils = trpc.useContext();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const { mutate } = trpc.a.contracts.delete.useMutation({
    onSuccess: () => {
      utils.a.contracts.list.invalidate();
      navigate('/contracts');
    },
  });

  const handleSubmit = useCallback(() => {
    mutate(contractId);
  }, [contractId]);

  return (
    <div className="col-span-1 flex flex-col items-center">
      <ActionPanel
        title="Delete Contract"
        content="Delete the Contract with a worker"
      >
        <Button
          loading={false}
          className="m-auto"
          text="Delete"
          onClick={() => setOpen(true)}
        />
        <ConfirmDialog
          title="Delete Contract"
          description="Are you sure you want to delete? This cannot be undone."
          actionLabel="Delete"
          open={open}
          setOpen={setOpen}
          handleSubmit={handleSubmit}
        />
      </ActionPanel>
    </div>
  );
}
