import { DateTime } from 'listo/src/luxonUtc';

export const APP_URL = import.meta.env.VITE_APP_URL;
export const ADMIN_URL = import.meta.env.VITE_ADMIN_URL;
export const API_URL = import.meta.env.VITE_API_URL;
export const BUNDLE_VERSION = DateTime.now().toISO();
export const IS_PROD = import.meta.env.PROD;
export enum DocumentTypes {
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT',
  PAYSTUBS = 'PAYSTUBS',
  MISCELLANEOUS = 'MISCELLANEOUS',
}
