import { useState, useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatDate } from 'listo/src/utils/dates';
import {
  floatInDollarsToCents,
  centsToDollars,
} from 'listo/src/utils/currency';
import { titleCase } from 'listo/src/utils/strings';
import {
  distributionEdit,
  DistributionEdit,
} from 'listo/src/zodObjects/distributions';

import { Input } from 'ui/src/components/Input';
import { SelectInput } from 'ui/src/components/SelectInput';

import { Alert, buildErrors } from 'ui/src/components/Alert';
import { Button } from 'ui/src/components/Button';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

function EditContract() {
  const { id, client } = useParams();
  const navigate = useNavigate();

  const { data: clients, isLoading: clientsLoading } =
    trpc.a.clients.list.useQuery({});

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<DistributionEdit>({
    resolver: zodResolver(distributionEdit),
  });
  const {
    data: distribution,
    isLoading,
    error,
  } = trpc.a.distributions.distribution.useQuery({
    distributionId: id as string,
  });
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    setValue('workerProfileId', distribution?.workerProfileId || '');
    setValue('payPeriodId', distribution?.payPeriodId);
  }, [distribution]);
  useEffect(() => {
    if (client) {
      setClientId(client);
    }
  }, [distribution]);

  useEffect(() => {
    const c = watch('clientId');
    if (c) {
      setClientId(c);
    }
  }, [watch('clientId')]);

  const { data: payPeriods, isLoading: payPeriodsLoading } =
    trpc.a.payPeriods.list.useQuery(
      {
        filters: {
          clientId,
        },
      },
      {
        enabled: !!clientId,
      },
    );

  const { data: workers, isLoading: workersLoading } =
    trpc.a.workers.workers.useQuery(
      {
        filters: {
          clientId,
        },
      },
      {
        enabled: !!clientId,
      },
    );

  const updateDistribution = trpc.a.distributions.update.useMutation({
    onSuccess: () => {
      navigate('/distributions');
    },
  });

  const onSubmit: SubmitHandler<DistributionEdit> = (data) => {
    const distributionData = { ...data };
    if (!distributionData.payPeriodId) {
      delete distributionData.payPeriodId;
    }
    updateDistribution.mutate({
      distributionData: {
        ...distributionData,
        amountInCents: floatInDollarsToCents(data.amountInCents),
      },
      distributionId: id!,
    });
  };

  if (isLoading || workersLoading || clientsLoading) return <Loader />;

  if (error || !id) return <div>Error</div>;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Edit Distribution" />
      <div className="min-w-full py-2 align-middle md:px-6 lg:px-8 my-8">
        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 mb-8">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="flex">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Worker:
              </h3>
              <Link to="#">
                <h3 className="text-lg font-medium leading-6 text-indigo-600 ml-4">
                  {titleCase(distribution.workerProfile.fullName)}
                </h3>
              </Link>
            </div>
          </div>
        </div>

        {Object.keys(errors).length > 0 ? (
          <div className="mb-8">
            <Alert
              heading="There are errors with your submission"
              items={buildErrors(Object.keys(errors), errors)}
            />
          </div>
        ) : null}

        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Distribution Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Edit Distribution info.
                </p>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="grid grid-cols-6 gap-6">
                  <div className="sm:col-span-2">
                    <SelectInput
                      label="Client"
                      defaultValue={distribution?.clientId || ''}
                      loading={clientsLoading}
                      defaultOption={
                        { label: 'Select Client', value: '' } as const
                      }
                      options={
                        clients?.map((clientInfo) => ({
                          label: clientInfo.name,
                          value: clientInfo.id,
                        })) || []
                      }
                      reactHookForm={{
                        register,
                        fieldName: 'clientId',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <SelectInput
                      label="Pay Period"
                      loading={payPeriodsLoading}
                      defaultValue={distribution.payPeriodId || ''}
                      defaultOption={
                        { label: 'Select PayPeriod', value: '' } as const
                      }
                      options={
                        payPeriods?.map((payPeriod) => ({
                          label: `Distribute Date: ${formatDate(
                            payPeriod.distributeDate,
                          )}`,
                          value: payPeriod.id,
                        })) || []
                      }
                      reactHookForm={{
                        register,
                        fieldName: 'payPeriodId',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <SelectInput
                      label="Worker"
                      defaultValue={distribution.workerProfile.id}
                      loading={workersLoading}
                      defaultOption={
                        { label: 'Select Worker', value: '' } as const
                      }
                      options={
                        workers?.workers?.map((worker) => ({
                          label: `${worker.firstName} ${worker.lastName}`,
                          value: worker.id,
                        })) || []
                      }
                      reactHookForm={{
                        register,
                        fieldName: 'workerProfileId',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <Input
                      inputType="number"
                      inputProps={{
                        defaultValue: centsToDollars(
                          distribution?.amountInCents,
                        ),
                        inputMode: 'decimal',
                        step: 0.01,
                      }}
                      label="Amount"
                      reactHookForm={{
                        register,
                        fieldName: 'amountInCents',
                        errors,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              type="button"
              text="Cancel"
              variant="secondary"
              onClick={() => navigate(-1)}
            />

            <Button type="submit" text="Save" className="ml-3" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditContract;
