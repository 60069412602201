import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import AlertModal from 'ui/src/components/AlertModal';
import { Button } from 'ui/src/components/Button';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

export function Credential() {
  const { credentialId } = useParams();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  const {
    data: credential,
    isLoading,
    error,
  } = trpc.a.credentials.credential.useQuery(credentialId as string);

  const { mutate: resetCredential } =
    trpc.a.credentials.resetPassword.useMutation({
      onSuccess: ({ newPassword: n }) => {
        setConfirmModalOpen(false);
        setNewPassword(n);
        setResetModalOpen(true);
      },
    });

  if (isLoading) return <Loader />;
  if (error) return <div>{error.toString()}</div>;
  if (credential === undefined) return <Loader />;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Credential" />
      <div className="px-4 py-5 sm:px-6">
        <AlertModal
          open={confirmModalOpen}
          setOpen={setConfirmModalOpen}
          title="Set Random Password?"
          description={`Are you sure you set the password for ${credential.email}?`}
          onConfirm={() => {
            resetCredential(credential.id);
          }}
        />
        <AlertModal
          modalType="success"
          open={resetModalOpen}
          setOpen={() => null}
          title="New Password"
          description={`The new password for ${credential.email} is ${newPassword}`}
          onConfirm={() => setResetModalOpen(false)}
        />
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <ActionPanel
            title="Reset Password"
            content="This will set the users password to a random value. If the person cannot receive their email from the reset password feature you can reset and send it directly to them."
          >
            <Button
              text="Reset Password"
              onClick={() => setConfirmModalOpen(true)}
            />
          </ActionPanel>
        </div>
      </div>
    </div>
  );
}
