import { countryNameByIsoCode } from 'listo/src/countries';
import { formatDate } from 'listo/src/utils/dates';
import { titleCase, truthyToYesNo } from 'listo/src/utils/strings';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { BillingCycle } from 'ui/src/components/Currency/Currency';
import Header from '../../components/Header';
import TableLoading from '../../components/TableLoading';
import { RouterOutput, trpc } from '../../lib/trpc';
import DeleteContract from './DeleteContract';
import { Invitations } from './Invitations';
import { UploadContract } from './UploadContract';
import { impersonate } from '../../hooks/useImpersonate';
import Impersonate from '../../components/Impersonate';
import { APP_URL } from '../../lib/constants';

function CustomContract({
  contract,
}: {
  contract: RouterOutput['a']['contracts']['contract'];
}) {
  const setNotification = useNotification((state) => state.setNotification);
  const utils = trpc.useContext();
  const { mutate: executeContract, isLoading: executeContractLoading } =
    trpc.a.contracts.executeContract.useMutation({
      onSuccess: () => {
        utils.a.contracts.contract.invalidate(contract.id);
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Contract status updated to "EXECUTED"',
        });
      },
    });

  const { mutate: sendWorkerResetEmail, isLoading: resetPasswordLoading } =
    trpc.a.workers.sendWorkerResetEmail.useMutation({
      onSuccess: () => {
        setNotification({
          type: 'success',
          title: 'Success',
          message: `Password reset email sent to ${contract.worker.credential.email}`,
        });
      },
    });

  return (
    <section aria-labelledby="custom-contract-actions">
      <div className="sm:rounded-lg grid grid-cols-3 gap-4">
        {contract.status !== 'EXECUTED' ? (
          <div className="col-span-1 flex flex-col items-center">
            <ActionPanel
              title="Execute Contract"
              content='Updates the contract status to "EXECUTED" This manually overrides the required signatures.'
            >
              <Button
                text="Execute"
                loading={executeContractLoading}
                onClick={() => executeContract(contract.id)}
              />
            </ActionPanel>
          </div>
        ) : null}
        <div className="col-span-1 flex flex-col items-center">
          <ActionPanel
            title="Reset Password"
            content="Sends a password reset email to the worker."
          >
            <Button
              loading={resetPasswordLoading}
              className="m-auto"
              text="Send Email"
              onClick={() => {
                if (contract.workerId) sendWorkerResetEmail(contract.workerId);
              }}
            />
          </ActionPanel>
        </div>
        <div className="col-span-1 flex flex-col items-center">
          <Invitations contractId={contract.id} />
        </div>
        <DeleteContract contractId={contract.id} />
      </div>
      <Impersonate />
    </section>
  );
}

export function ViewContract() {
  const { id } = useParams();
  const contract = trpc.a.contracts.contract.useQuery(id as string);
  const impersonateContract = trpc.a.contracts.impersonate.useMutation({
    onSuccess: (jwt: string) => impersonate(jwt, `${APP_URL}/contracts/${id}`),
  });

  const navigate = useNavigate();

  const onImpersonate = () => {
    if (id) {
      impersonateContract.mutate({ contractId: id });
    }
  };

  if (contract.error) return <h1>error</h1>;
  if (contract.isLoading || !contract.data)
    return (
      <div className="bg-gray-100 min-h-screen">
        <Header title="Contract" />
        <TableLoading />
      </div>
    );

  const benis = (contract.data?.unstructuredData as any)?.benefits;

  const bankAccountsWithANumber =
    contract.data.workerProfile.bankAccounts.filter((f) => f.number);

  return (
    <div className="bg-gray-100 min-h-screen pb-8">
      <Header title="Contract" />
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-3">
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg p-6">
              <div>
                <div className="flex justify-between px-4 py-5 sm:px-6">
                  <div>
                    <div className="flex items-center ">
                      <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Contract Information
                      </h2>
                    </div>

                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Contract details and overview.
                    </p>
                  </div>

                  <div className="space-x-1">
                    <Button text="View in platform" onClick={onImpersonate} />
                    <Button
                      onClick={() =>
                        navigate(`/contracts/${contract.data?.id}/edit`)
                      }
                      text="Edit"
                    />
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 border-b border-gray-200 pb-4 sm:pb-4">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Worker Name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {titleCase(
                          contract.data.worker.workerProfile?.fullName,
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Worker Email
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {contract.data.worker.credential.email}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Engagement Type
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {contract.data.engagementType}
                      </dd>
                    </div>
                    <div id="status" className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Status
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {contract.data.status}
                      </dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Country
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {countryNameByIsoCode(contract.data.isoCountryCode)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Benefits
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {truthyToYesNo(benis)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Payment Terms
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <BillingCycle
                          currency={contract.data.billingCurrency}
                          isCents
                          frequency={contract.data.billingFrequency}
                          amount={contract.data.billingAmountInCents}
                        />
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Distribution Currency
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {contract.data.distributionCurrency ?? 'UNKNOWN'} via{' '}
                        <span className="italic">
                          {contract.data.distributionMethod}
                        </span>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Job Title
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {contract.data.jobTitle}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Seniority Level
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {contract.data.seniorityLevel}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Start Date
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatDate(contract.data.startDate)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Type
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {contract.data.contractType}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Client Signature
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {contract.data.clientSignature}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Worker Signature
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {contract.data.workerSignature}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        Scope of Work
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {contract.data.scopeOfWork}
                      </dd>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 mt-8">
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Worker Profile
                      </h2>
                      <div className="mt-1 grid grid-cols-1 gap-4">
                        <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400">
                          <div className="flex-shrink-0">
                            {/* <img
                                  className="h-10 w-10 rounded-full"
                                  src="{person.imageUrl}"
                                  alt=""
                                /> */}
                          </div>
                          <div className="min-w-0 flex-1">
                            <Link
                              to={`/workerProfiles/${contract.data.worker.workerProfileId}`}
                              className="focus:outline-none"
                            >
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900">
                                {titleCase(
                                  contract.data.worker.workerProfile
                                    ?.fullName || '',
                                )}
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Documents
                      </h2>
                      <div className="mt-1 grid grid-cols-1 gap-4">
                        <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400">
                          <div className="flex-shrink-0">
                            {/* <img
                                  className="h-10 w-10 rounded-full"
                                  src="{person.imageUrl}"
                                  alt=""
                                /> */}
                          </div>
                          <div className="min-w-0 flex-1">
                            <Link to="documents" className="focus:outline-none">
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900">
                                Number of Documents
                              </p>
                              <p className="truncate text-sm text-gray-500">
                                {contract.data.documents?.length || 0}
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Payments
                      </h2>
                      <div className="mt-1 grid grid-cols-1 gap-4">
                        <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400">
                          <div className="flex-shrink-0">
                            {/* <img
                                  className="h-10 w-10 rounded-full"
                                  src="{person.imageUrl}"
                                  alt=""
                                /> */}
                          </div>
                          <div className="min-w-0 flex-1">
                            <Link to="payments" className="focus:outline-none">
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900">
                                Number of Payments
                              </p>
                              <p className="truncate text-sm text-gray-500">
                                {contract.data.paystubs.length}
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Quotes
                      </h2>
                      <div className="mt-1 grid grid-cols-1 gap-4">
                        <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400">
                          <div className="flex-shrink-0">
                            {/* <img
                                  className="h-10 w-10 rounded-full"
                                  src="{person.imageUrl}"
                                  alt=""
                                /> */}
                          </div>
                          {/* <div className="min-w-0 flex-1">
                            <Link to="quotes" className="focus:outline-none">
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900">
                                Quote
                              </p>
                              <p className="truncate text-sm text-gray-500">
                                {truthyToYesNo(contract.data.quote)}
                              </p>
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Bank Account Information
                      </h2>
                      <div className="mt-1 grid grid-cols-1 gap-4">
                        <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400">
                          <div className="flex-shrink-0">
                            {/* <img
                                  className="h-10 w-10 rounded-full"
                                  src="{person.imageUrl}"
                                  alt=""
                                /> */}
                          </div>
                          <div className="min-w-0 flex-1">
                            <Link
                              to={`/worker-profiles/${contract.data.workerProfileId}/bank-accounts`}
                              className="focus:outline-none"
                            >
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900">
                                Bank Accounts
                              </p>
                              <p className="truncate text-sm text-gray-500">
                                {truthyToYesNo(
                                  !!bankAccountsWithANumber.length,
                                )}
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Client
                      </h2>
                      <div className="mt-1 grid grid-cols-1 gap-4">
                        <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400">
                          <div className="flex-shrink-0">
                            {/* <img
                                  className="h-10 w-10 rounded-full"
                                  src="{person.imageUrl}"
                                  alt=""
                                /> */}
                          </div>
                          <div className="min-w-0 flex-1">
                            <Link
                              to={`/clients/${contract.data.clientId}/edit`}
                              className="focus:outline-none"
                            >
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900">
                                {contract.data.client.name}
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {contract.data.customContractUploadId || contract.data.uploadId ? (
          <div>
            <Button
              text="View Contract"
              onClick={() => {
                if (
                  contract.data?.upload?.url ||
                  contract.data?.customContractUpload?.url
                )
                  window.open(
                    contract.data?.upload?.url ||
                      contract.data?.customContractUpload?.url,
                    '_blank',
                  );
              }}
            />
          </div>
        ) : (
          <UploadContract
            clientId={contract.data.clientId}
            contractId={contract.data.id}
          />
        )}
      </div>
      <div className="mt-8 max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-7xl">
        <CustomContract contract={contract.data} />
      </div>
    </div>
  );
}
