import { zodResolver } from '@hookform/resolvers/zod';
import {
  CreateClientType,
  createClientZod,
} from 'listo/src/zodObjects/clients';
import { countries } from 'listo/src/countries';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

export function CreateClient() {
  const navigate = useNavigate();
  const utils = trpc.useContext();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateClientType>({ resolver: zodResolver(createClientZod) });

  const { mutate } = trpc.a.clients.create.useMutation({
    onSuccess: () => {
      utils.a.clients.list.invalidate();
      navigate('/clients');
    },
  });

  return (
    <div className="min-h-full">
      <Header title="Onboard Client" />
      <div className="max-w-lg mx-auto pt-10 pb-12 px-4 lg:pb-16">
        <form
          onSubmit={handleSubmit(mutate as SubmitHandler<CreateClientType>)}
        >
          <div className="space-y-6">
            <div>
              <h1 className="text-lg leading-6 font-medium text-gray-900">
                Client
              </h1>
            </div>

            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="clientName"
                  {...register('client.name')}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {errors?.client?.name ? (
                <p className="mt-2 text-sm text-red-600" id="clientName-error">
                  {errors.client.name.message}
                </p>
              ) : null}
            </div>

            <div>
              <label
                htmlFor="address1"
                className="block text-sm font-medium text-gray-700"
              >
                Address 1
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="clientAddress1"
                  {...register('client.address1')}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {errors?.client?.address1 ? (
                <p className="mt-2 text-sm text-red-600" id="clientName-error">
                  {errors.client.address1.message}
                </p>
              ) : null}
            </div>

            <div>
              <label
                htmlFor="address2"
                className="block text-sm font-medium text-gray-700"
              >
                Address 2
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="clientAddress2"
                  {...register('client.address2')}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {errors?.client?.address2 ? (
                <p className="mt-2 text-sm text-red-600" id="clientName-error">
                  {errors.client.address2.message}
                </p>
              ) : null}
            </div>

            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="clientCity"
                  {...register('client.city')}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {errors?.client?.city ? (
                <p className="mt-2 text-sm text-red-600" id="clientName-error">
                  {errors.client.city.message}
                </p>
              ) : null}
            </div>

            <div>
              <label
                htmlFor="zoneCode"
                className="block text-sm font-medium text-gray-700"
              >
                State / Providence
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="clientZoneCode"
                  {...register('client.zoneCode')}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {errors?.client?.zoneCode ? (
                <p className="mt-2 text-sm text-red-600" id="clientName-error">
                  {errors.client.zoneCode.message}
                </p>
              ) : null}
            </div>

            <div>
              <label
                htmlFor="postalCode"
                className="block text-sm font-medium text-gray-700"
              >
                Postal Code
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="postalCode"
                  {...register('client.postalCode')}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {errors?.client?.postalCode ? (
                <p className="mt-2 text-sm text-red-600" id="clientName-error">
                  {errors.client.postalCode.message}
                </p>
              ) : null}
            </div>

            <div>
              <label
                htmlFor="postalCode"
                className="block text-sm font-medium text-gray-700"
              >
                Country
              </label>

              <select
                defaultValue="select"
                className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                id="isoCountryCode"
                {...register('client.isoCountryCode')}
              >
                <option disabled hidden value="Select Client">
                  Select
                </option>
                {countries.map((c) => (
                  <option key={c.alpha2} value={c.alpha2}>
                    {c.name}
                  </option>
                ))}
              </select>

              {errors?.client?.isoCountryCode ? (
                <p className="mt-2 text-sm text-red-600" id="clientName-error">
                  {errors.client.isoCountryCode.message}
                </p>
              ) : null}
            </div>

            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Entity
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Contracts are issued from an entity. Select the appropriate
                corporation type where possible. If uknown, select unknown.
              </p>
            </div>

            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="entity.name"
                  {...register('entity.name')}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {errors?.entity?.name ? (
                <p className="mt-2 text-sm text-red-600" id="entity.name-error">
                  {errors.entity.name.message}
                </p>
              ) : null}
            </div>

            <div>
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Corporation Type
              </label>
              <div className="mt-1">
                <select
                  id="entity.corporationType"
                  {...register('entity.corporationType')}
                  defaultValue="default"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="default" disabled hidden>
                    Select Corporation Type
                  </option>
                  <option value="individual">
                    Not Incorporated / Individual
                  </option>
                  <option value="llc">LLC</option>
                  <option value="s-corp">S-corp</option>
                  <option value="c-corp">C-corp</option>
                  <option value="sole-proprietorship">
                    Sole Proprietorship
                  </option>
                  <option value="unknown">Unknown</option>
                </select>
                {errors?.entity?.corporationType ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="entity.corporationType-error"
                  >
                    {errors.entity.corporationType.message}
                  </p>
                ) : null}
              </div>
            </div>

            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Tax Id Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="entity.taxIdNumber"
                  {...register('entity.taxIdNumber')}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                />
                {errors?.entity?.taxIdNumber ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="entity.taxIdNumber-error"
                  >
                    {errors.entity.taxIdNumber.message}
                  </p>
                ) : null}
              </div>
            </div>

            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                User
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                The email addresses of this user will be used to send the client
                onboarding emails.
              </p>
            </div>

            <div>
              <label
                htmlFor="user-email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="email"
                  {...register('user.email', {
                    required: true,
                  })}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-gray-300 rounded-md"
                />
                {errors?.user?.email ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="entity.user.email-error"
                  >
                    {errors.user.email.message}
                  </p>
                ) : null}
              </div>
            </div>

            <div>
              <label
                htmlFor="user-email"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="user.firstName"
                  {...register('user.firstName', {
                    required: true,
                  })}
                  name="user.firstName"
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-gray-300 rounded-md"
                />
                {errors?.user?.firstName ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="entity.user.firstName-error"
                  >
                    {errors.user.firstName.message}
                  </p>
                ) : null}
              </div>
            </div>

            <div>
              <label
                htmlFor="user-email"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="user.lastName"
                  {...register('user.lastName', {
                    required: true,
                  })}
                  name="user.lastName"
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-gray-300 rounded-md"
                />
                {errors?.user?.lastName ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="entity.user.lastName-error"
                  >
                    {errors.user.lastName.message}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => {
                  navigate('/clients');
                }}
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                Create new client
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
