import { PlusIcon as PlusIconOutline } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { classNames } from 'listo/src/utils/strings';
import {
  priceOverrideTypes,
  PricingOverrideForm,
  pricingOverrideForm,
} from 'listo/src/zodObjects/clients';
import { countryOptions } from 'listo/src/zodObjects/pricing';
import { useEffect } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { trpc } from '../../lib/trpc';
import OverrideRows from './OverrideRows';

export default function PayOverrides() {
  const { id: clientId } = useParams();
  if (!clientId) throw new Error('No id provided');
  const { data: overrides } = trpc.a.clients.priceOverrides.useQuery(clientId);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<PricingOverrideForm>({
    resolver: zodResolver(pricingOverrideForm),
    defaultValues: {
      clientId,
      lineItems: [],
      // Used to make sure there's no duplicates
      availableCountryOptions: {
        eorFee: countryOptions,
        contractorFee: countryOptions,
      },
    },
  });

  const setNotification = useNotification((state) => state.setNotification);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lineItems',
  });

  const handleSetCountryOptions = (overrideType: string) => {
    const currentCountryOptions = getValues('availableCountryOptions');
    const lineItems = getValues('lineItems');
    const existingEorCountryCodes = lineItems
      .filter((override) => override.overrideType === overrideType)
      .map((override) => override.countryCode);
    setValue(
      `availableCountryOptions.${overrideType}` as any,
      currentCountryOptions.eorFee.map((option) =>
        (existingEorCountryCodes ?? []).includes(option.value)
          ? { ...option, available: false }
          : { ...option, available: true },
      ),
    );
  };

  useEffect(() => {
    setValue('lineItems', overrides ?? []);
    const existingEorCountryCodes = overrides
      ?.filter((override) => override.overrideType === 'eorFee')
      .map((override) => override.countryCode);
    setValue(
      'availableCountryOptions.eorFee',
      countryOptions.map((option) =>
        (existingEorCountryCodes ?? []).includes(option.value)
          ? { ...option, available: false }
          : { ...option, available: true },
      ),
    );
  }, [overrides]);

  const { mutate: savePriceOverrides, isLoading: savePriceOverridesLoading } =
    trpc.a.clients.savePriceOverrides.useMutation({
      onSuccess: () => {
        navigate('/clients');
        setNotification({
          type: 'success',
          title: 'Overrides Updated',
          message: 'Pay Overrides have been successfully updated',
        });
      },
    });

  const onSubmit: SubmitHandler<PricingOverrideForm> = (data) => {
    savePriceOverrides(data);
  };

  // TODO: add line item errors
  const onError = () => {
    setNotification({
      type: 'error',
      title: 'Invalid',
      message: 'Please ensure data is valid',
    });
  };
  return (
    <div>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <OverrideRows
          handleSetCountryOptions={handleSetCountryOptions}
          control={control}
          fields={fields}
          clientId={clientId}
          getValues={getValues}
          setValue={setValue}
          remove={remove}
          register={register}
          errors={errors}
        />
        <button
          disabled={!clientId}
          type="button"
          className={classNames(
            'inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
          )}
          onClick={() =>
            append({
              // default value of empty string
              countryCode: '',
              overrideType: priceOverrideTypes.eorFee,
              amount: '',
              amountInCents: 0,
            })
          }
        >
          <PlusIconOutline className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="pt-4">
          <div className="flex justify-end">
            <Button
              loading={savePriceOverridesLoading}
              text="Save"
              type="submit"
              className="ml-3"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
