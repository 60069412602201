/* eslint-disable @typescript-eslint/naming-convention */
export enum RecipientAccountStatus {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  // note: I've seen both APPROVED and AVAILABLE used in the PingPong documentation.
  APPROVED = 'APPROVED',
  AVAILABLE = 'AVAILABLE',
}
export enum PingPongConnectStatus {
  NOT_CONNECTED,
  VERIFIED,
  INVALID_TOKEN,
}

export interface PingPongAccountStatusInfo {
  status?: RecipientAccountStatus;
  found: PingPongFoundStatus;
}

export enum PingPongFoundStatus {
  // The account is no longer located in PingPong backend
  NOT_FOUND,
  // The account was found in PingPong backend
  FOUND,
  // An account was found, but the account number is a mismatch (someone may have updated bank info within Listo)
  WRONG_ACCOUNT,
  // Unable to determine the status
  UNKNOWN,
}

export interface PingPongBankInfo {
  bankCode?: string;
  bankName?: string;
}

export interface PingPongInstitutionResponse {
  beneficiaryBankMap: Record<string, PingPongBankInfo[]>;
}

export type PingPongInstitutionMap = Record<string, PingPongBankInfo[]>;
