import { z } from 'zod';

export const passwordResetZod = z.object({
  email: z.string().email(),
});

export const setPasswordZod = z
  .object({
    resetToken: z.string(),
    password: z.string().min(1, 'Password must be at least 8 characters long'),
    passwordConfirm: z.string().min(1),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Passwords don't match",
    path: ['passwordConfirm'],
  });

export type SetPasswordType = z.infer<typeof setPasswordZod>;

export type PasswordResetType = z.infer<typeof passwordResetZod>;
