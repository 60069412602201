/* eslint-disable react/no-unstable-nested-components */
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UnPackAsyncDefaultValues,
  UseFormRegister,
} from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactSelect from 'react-select';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { currencySelectOptions } from 'listo/src/countries';
import { InfoBubble } from '../InfoBubble';
import { useHovered } from '../../hooks/useHovered';
import { useFocused } from '../../hooks/useFocused';

/**
 * Allows editing a currency and amount at the same time.
 */
export function CurrencyInput<
  T extends FieldValues,
  C extends Path<UnPackAsyncDefaultValues<T>>,
  A extends Path<UnPackAsyncDefaultValues<T>>,
>({
  cols = 2,
  currencyField,
  amountField,
  register,
  errors,
  label,
  control,
  infoTooltipId,
  infoTooltipContent,
  formatAmount = parseFloat,
  amountError,
  defaultValues = {},
}: {
  currencyField: C;
  amountField: A;
  cols?: number;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  label: string;
  infoTooltipId?: string;
  control: Control<T>;
  infoTooltipContent?: string;
  formatAmount?: (amount: string) => number;
  amountError?: string;
  defaultValues?: {
    currency?: T[C];
    amount?: T[A];
  };
}) {
  const reactHookError = errors[amountField]?.message as string | undefined;

  const borderStyles = {
    shared: '',
    unfocused: 'ring-gray-200 ring-1',
    focusedClasses: 'ring-2 ring-indigo-500',
  };
  const [borderClasses, setBorderClasses] = useState(borderStyles.unfocused);
  const [isCurrencyFocused, currencyFocusHandlers] = useFocused();
  const [isAmountFocused, amountFocusHandlers] = useFocused();
  const [isHover, hoverHandlers] = useHovered();
  const intl = useIntl();
  const isFocused = () => isAmountFocused || isCurrencyFocused;

  useEffect(
    () =>
      setBorderClasses(
        isFocused() ? borderStyles.focusedClasses : borderStyles.unfocused,
      ),
    [isCurrencyFocused, isAmountFocused],
  );

  /// Finds the currency symbol given a 3-digit currency code
  const getCurrencySymbol = useCallback((currency: string) => {
    const formatted = intl.formatNumber(0, {
      style: 'currency',
      currency,
      maximumFractionDigits: 0,
      currencyDisplay: 'symbol',
    });
    return formatted.replace('0', '').trim();
  }, []);

  return (
    <div className={`col-span-${cols} sm:col-span-${cols}`}>
      <div className="w-full">
        {label ? (
          <label
            htmlFor={label.toLowerCase()}
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            {label}
            {infoTooltipId && infoTooltipContent && (
              <InfoBubble
                tooltipId={infoTooltipId}
                tooltipContent={infoTooltipContent}
              />
            )}
          </label>
        ) : null}
        <div
          className={`flex flex-row ${borderClasses} ${borderStyles.shared} shadow-sm rounded-md`}
        >
          <Controller
            name={currencyField}
            control={control}
            defaultValue={defaultValues.currency ?? ('USD' as T[C])}
            render={({ field }) => (
              <div {...hoverHandlers} className="cursor-pointer">
                <ReactSelect
                  id={currencyField}
                  name={currencyField}
                  {...currencyFocusHandlers}
                  getOptionValue={({ value }) => value}
                  onChange={(value) => {
                    hoverHandlers.onMouseLeave();
                    field.onChange(value?.value);
                  }}
                  defaultValue={{
                    value: field.value?.toString() ?? 'USD',
                    label: field.value?.toString() ?? 'USD',
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                    /// Needed to shrink the drop-down
                    DropdownIndicator: () =>
                      !isHover ? null : (
                        <ChevronDownIcon
                          height={18}
                          width={18}
                          className="p-0.5 absolute right-0"
                        />
                      ),
                  }}
                  classNames={{
                    control: () =>
                      `sm:text-sm font-bold rounded-l-md hover:bg-gray-100`,
                  }}
                  menuPortalTarget={document.body}
                  formatOptionLabel={(data, meta) =>
                    meta.context === 'value'
                      ? getCurrencySymbol(data.value)
                      : data.label
                  }
                  options={currencySelectOptions}
                  styles={{
                    container(base) {
                      return {
                        ...base,
                        width: '60px',
                      };
                    },
                    control({
                      backgroundColor,
                      borderColor,
                      borderRadius,
                      borderStyle,
                      borderWidth,
                      boxShadow,
                      ...base
                    }) {
                      // eslint-disable-next-line no-param-reassign
                      delete base['&:hover'];
                      return { ...base };
                    },
                    singleValue(base) {
                      return {
                        ...base,
                        textAlign: 'center',
                      };
                    },
                    menuPortal(base) {
                      return { ...base, zIndex: 9999, width: 300 };
                    },
                    input(baseStyles) {
                      return {
                        ...baseStyles,
                        textAlign: 'center',
                        'input[type = Text]': {
                          ':focus': {
                            outline: 'none !important',
                            border: 'none !important',
                            boxShadow: 'none !important',
                          },
                        },
                      };
                    },
                  }}
                />
              </div>
            )}
          />
          <div
            className={`inline-block w-0.5 self-stretch ${
              isFocused() ? 'bg-indigo-500' : 'bg-gray-200'
            }`}
          />
          <input
            type="number"
            {...amountFocusHandlers}
            {...register(amountField, {
              setValueAs: formatAmount,
            })}
            id={amountField}
            defaultValue={defaultValues.amount}
            inputMode="decimal"
            step="0.01"
            className="block w-full rounded-r-md sm:text-sm bg-transparent"
            style={{ outline: 'none', borderWidth: 0, boxShadow: 'none' }}
          />
        </div>
        {amountError || reactHookError ? (
          <p
            className="mt-2 text-sm text-red-600"
            id={`${label?.toLowerCase()}-error`}
          >
            {amountError || reactHookError}
          </p>
        ) : null}
      </div>
    </div>
  );
}
