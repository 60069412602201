import axios from 'axios';
import { AssetTypes } from 'listo/src/zodObjects/assets';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { trpc, RouterOutput } from '../../lib/trpc';
import { Maybe, Nullable } from '../../types/common';

type UploaderProps = {
  children: React.ReactNode;
  listoAssetType: AssetTypes;
  allowedTypes?: string[];
  setLoading?: (loading: boolean) => void;
  setUploadedFile: (file: RouterOutput['a']['assets']['uploadSuccess']) => void;
  onPreviewLoad?: (preview: Maybe<string>) => void;
  onError?: (error: unknown) => void;
  clientId: string;
};

export function Uploader({
  children,
  allowedTypes,
  onPreviewLoad,
  onError,
  listoAssetType = 'invoices',
  setLoading,
  setUploadedFile,
  clientId,
}: UploaderProps) {
  const { mutateAsync: getSignedUrlForUpload } =
    trpc.a.assets.getSignedUrl.useMutation();

  const { mutateAsync: uploadFileSuccess } =
    trpc.a.assets.uploadSuccess.useMutation();

  const uploaderInputRef = useRef<Nullable<HTMLInputElement>>(null);
  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  const handleClick = () => {
    uploaderInputRef?.current?.click();
  };

  const handleKeyPress = () => {};
  const accept = !allowedTypes ? '*' : allowedTypes.join(',');

  const handleSubmit: ChangeEventHandler<HTMLInputElement> = async (event) => {
    try {
      if (setLoading) {
        setLoading(true);
      }

      const fileList = event.target.files;

      if (!fileList) return;

      const [selectedFile] = fileList;

      setFile(selectedFile);

      const fileName = selectedFile?.name;
      const mimeType = selectedFile?.type;
      if (mimeType && fileName) {
        const { signedUrl, cloudStorageKey } = await getSignedUrlForUpload({
          listoAssetType,
          mimeType,
          clientId,
        });

        await axios.put(signedUrl, selectedFile, {
          headers: {
            'Content-Type': selectedFile?.type,
          },
        });

        const upload = await uploadFileSuccess({
          clientId,
          fileName,
          mimeType,
          cloudStorageKey,
        });

        setUploadedFile(upload);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    } finally {
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (onPreviewLoad && file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
      onPreviewLoad(preview);
    }
  }, [file]);

  return (
    <>
      <input
        hidden
        ref={uploaderInputRef}
        type="file"
        name="uploader"
        accept={accept}
        onChange={handleSubmit}
      />
      <div
        role="button"
        tabIndex={0}
        className="uploader"
        onKeyPress={handleKeyPress}
        onClick={handleClick}
      >
        {children}
      </div>
    </>
  );
}
