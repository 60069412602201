import {
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UnPackAsyncDefaultValues,
  Control,
} from 'react-hook-form';
import ReactSelect from 'react-select';
import { useState } from 'react';
import { isEqual } from 'lodash';
import { InfoBubble } from '../InfoBubble';
import { useFlexSearch } from '../../hooks/useFlexSearch';

function Loading() {
  return (
    <div className="h-[36px]">
      <div className="w-full">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-200 rounded" />
          </div>
        </div>
      </div>
    </div>
  );
}

type Option<D> = {
  label: string;
  value: D;
};

export function isOption<D>(value: unknown): value is Option<D> {
  if (value == null) return false;
  if (typeof value === 'object') {
    return Object.hasOwn(value as object, 'label');
  }
  return false;
}

export function ReactSelectInput<
  T extends FieldValues,
  P extends Path<UnPackAsyncDefaultValues<T>>,
>({
  error,
  label,
  loading = false,
  options,
  control,
  defaultValue,
  fieldName,
  hideIcons,
  errors,
  infoTooltipId,
  infoTooltipContent,
  getValue,
  optionOf,
  tokenizer,
}: {
  label?: string;
  loading?: boolean;
  options?: Option<T[P]>[] | null;
  defaultValue?: T[P] | Option<T[P]>[];
  error?: string;
  control?: Control<T>;
  fieldName: P;
  errors: FieldErrors<T>;
  infoTooltipId?: string;
  infoTooltipContent?: string;
  getValue?: (value: any) => T[P];
  optionOf?: (value: any) => any;
  hideIcons?: boolean;
  tokenizer?: (item: Option<T[P]>) => [string, string];
}) {
  const reactHookError = errors[fieldName]?.message as string | undefined;

  const getValueFn =
    getValue ?? ((value: any) => (isOption(value) ? value.value : value));
  const optionOfFn =
    optionOf ??
    ((value: any) => (isOption(value) ? value : { label: value, value }));

  const [query, setQuery] = useState('');

  const searchResults = useFlexSearch({
    query,
    data: options ?? [],
    showAll: true,
    tokenizer:
      tokenizer ?? ((item) => [String(item.value), [item.label, item.value]]),
  });

  const isOptionEqual = (source: Option<T[P]>, value: unknown) => {
    if (isOption(value)) {
      return isEqual(source.value, value.value);
    }
    return source.value === value;
  };

  const getLabel = (source: unknown) => {
    if (typeof source === 'string') {
      return source;
    }
    return isOption(source) ? source.label : undefined;
  };

  const findOption = (value: unknown) =>
    options?.find((o) => isOptionEqual(o, value));

  return (
    <div className="appearance-none remove-all react-select">
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium text-gray-700"
      >
        {label}{' '}
        {infoTooltipId && infoTooltipContent && (
          <InfoBubble
            tooltipId={infoTooltipId}
            tooltipContent={infoTooltipContent}
          />
        )}
      </label>
      <div className="mt-1">
        {!loading ? (
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { value, onChange } }) => (
              <ReactSelect
                options={searchResults}
                onBlur={() => {}}
                isSearchable
                filterOption={() => true}
                onInputChange={(newQuery) => setQuery(newQuery)}
                blurInputOnSelect={false}
                defaultInputValue={getLabel(defaultValue)}
                isLoading={options == null || loading}
                value={findOption(value) ?? value}
                onChange={(change) => onChange(getValueFn(change))}
                components={
                  hideIcons
                    ? {
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }
                    : {
                        IndicatorSeparator: () => null,
                      }
                }
                defaultValue={
                  findOption(defaultValue) ?? optionOfFn(defaultValue)
                }
                getOptionValue={getValueFn}
              />
            )}
            rules={{ required: true }}
          />
        ) : (
          <Loading />
        )}

        {error || reactHookError ? (
          <p
            className="mt-2 text-sm text-red-600"
            id={`${label?.toLowerCase()}-error`}
          >
            {error || reactHookError}
          </p>
        ) : null}
      </div>
    </div>
  );
}
