import { zodResolver } from '@hookform/resolvers/zod';

import { countries } from 'listo/src/countries';
import {
  AdminWorkerProfileType,
  adminWorkerProfileZod,
} from 'listo/src/zodObjects/workerProfile';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { Button } from 'ui/src/components/Button';
import Form from '../../components/Form';
import { SelectInput } from '../../components/Form/SelectInput';
import { TextInput } from '../../components/Form/TextInput';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

export function ViewWorkerProfile() {
  const { workerProfileId } = useParams();
  const setNotification = useNotification((state) => state.setNotification);
  const utils = trpc.useContext();

  const {
    data: workerProfile,
    isLoading,
    error,
  } = trpc.a.workers.workerProfile.useQuery({
    workerProfileId: workerProfileId as string,
  });

  const { mutate, isLoading: updatingWorkingLoading } =
    trpc.a.workers.updateWorkerProfile.useMutation({
      onSuccess: () => {
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Worker profile updated',
        });
        utils.a.workers.workerProfile.invalidate({
          workerProfileId: workerProfileId as string,
        });
      },
    });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AdminWorkerProfileType>({
    resolver: zodResolver(adminWorkerProfileZod),
  });
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<AdminWorkerProfileType> = (data) => {
    mutate({
      workerProfileId: workerProfileId as string,
      fields: data,
    });
  };

  const { data: contracts } =
    trpc.a.contracts.listContractsByWorkerProile.useQuery({
      workerProfileId: workerProfileId as string,
    });

  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;
  if (workerProfile === undefined) return <Loader />;

  if (!workerProfile) return <div>Worker not found</div>;
  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Worker Profile" />
      <section aria-labelledby="custom-contract-actions" className="m-8">
        <div className="bg-white shadow sm:rounded-lg p-6">
          <Form
            handleSubmit={handleSubmit as any}
            onSubmit={onSubmit}
            primaryButtonText="Update"
            secondaryButtonText=""
            secondaryButtonOnClick={() => null}
            loading={updatingWorkingLoading}
          >
            <>
              <p>Edit Worker Profile</p>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <TextInput
                  cols={3}
                  register={register}
                  fieldName="firstName"
                  label="First Name"
                  defaultValue={workerProfile.firstName ?? ''}
                  error={errors.firstName}
                />
                <TextInput
                  cols={3}
                  register={register}
                  fieldName="lastName"
                  label="Last Name"
                  defaultValue={workerProfile.lastName ?? ''}
                  error={errors.lastName}
                />
                <TextInput
                  cols={3}
                  register={register}
                  fieldName="phone"
                  label="Phone"
                  defaultValue={workerProfile.phone ?? ''}
                  error={errors.phone}
                />
                <TextInput
                  cols={3}
                  register={register}
                  fieldName="address1"
                  label="Address 1"
                  defaultValue={workerProfile.address1 ?? ''}
                  error={errors.address1}
                />
                <TextInput
                  cols={3}
                  register={register}
                  fieldName="address2"
                  label="Address 2"
                  defaultValue={workerProfile.address2 ?? ''}
                  error={errors.address2}
                />
                <TextInput
                  cols={3}
                  register={register}
                  fieldName="city"
                  label="City"
                  defaultValue={workerProfile.city ?? ''}
                  error={errors.city}
                />
                <TextInput
                  cols={3}
                  register={register}
                  fieldName="zoneCode"
                  label="Zone Code"
                  defaultValue={workerProfile.zoneCode ?? ''}
                  error={errors.zoneCode}
                />
                <TextInput
                  cols={3}
                  register={register}
                  fieldName="postalCode"
                  label="Postal Code"
                  defaultValue={workerProfile.postalCode ?? ''}
                  error={errors.postalCode}
                />
                <TextInput
                  cols={3}
                  register={register}
                  fieldName="taxIdNumber"
                  label="Tax Id Number"
                  defaultValue={workerProfile.taxIdNumber ?? ''}
                  error={errors.taxIdNumber}
                />

                <SelectInput
                  cols={3}
                  register={register}
                  fieldName="isoCountryCode"
                  label="Country"
                  defaultValue={workerProfile.isoCountryCode ?? ''}
                  defaultOption={{ label: 'Select', value: '' }}
                  options={countries.map((c) => ({
                    label: c.name,
                    value: c.alpha2,
                  }))}
                  error={errors.isoCountryCode}
                />
              </div>
            </>
          </Form>
          <div>
            <p>Contracts</p>
            <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 mt-8">
              {contracts?.results?.map((contract: any) => (
                <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400">
                  <p>
                    {contract.name}-{contract.isoCountryCode}
                  </p>
                  <Button
                    text="Go To Contract"
                    onClick={() => {
                      navigate(`/contracts/${contract.id}`);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
