import { useEffect, useMemo, useState } from 'react';
import FlexSearch, { IndexOptions } from 'flexsearch-ts';
import { arrayOf } from 'listo/src/utils/urls';

export interface UseFlexSearchOpts<D> {
  query: string;
  data: D[];
  showAll?: boolean;
  tokenizer: (source: D) => [string, string | unknown[]];
  indexOptions?: IndexOptions<D>;
  loading?: boolean;
  searchOptions?: {
    limit?: number;
    suggest?: boolean;
    where?: { [key: string]: string };
    field?: string | string[];
    bool?: 'and' | 'or' | 'not';
  };
}

/**
 * Use FlexSearch full-text index.
 */
export function useFlexSearch<D>({
  query,
  indexOptions = {
    tokenize: 'reverse',
  },
  searchOptions,
  data,
  tokenizer,
  showAll = false,
  loading,
}: UseFlexSearchOpts<D>) {
  const [index] = useState<FlexSearch.Index>(
    () =>
      // @ts-ignore Broken type defs
      FlexSearch(indexOptions) as FlexSearch.Index,
  );

  const [store, updateStore] = useState({} as Record<string, D>);

  useEffect(() => {
    const storeData = {} as Record<string, D>;
    data.forEach((record) => {
      const [id, tokens] = tokenizer(record);
      storeData[id] = record;
      if (!index.contain(id)) {
        const tokenString = arrayOf(tokens)
          .filter(Boolean)
          .map(String)
          .join(' ');
        index.add(id, tokenString);
      }
    });
    updateStore(storeData);
  }, [data]);

  return useMemo<D[]>(() => {
    if (!index || loading) return [];

    if (!query?.trim()) {
      return showAll ? data : [];
    }

    const results = index.search(query, searchOptions ?? {});
    return results.map((value) => store[value]).filter(Boolean) as D[];
  }, [query, loading, showAll]);
}
