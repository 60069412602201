import { useEffect, useState, useReducer, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { unionBy } from 'lodash';

import AlertModal from 'ui/src/components/AlertModal';
import { useDebounce } from 'ui/src/hooks/useDebounce';
import { titleCase } from 'listo/src/utils/strings';
import { RouterOutput, trpc } from '../../lib/trpc';
import Table from '../../components/Table/Table';

import Header from '../../components/Header';

import { SelectedFilter } from './WorkerFilter';
import Impersonate from '../../components/Impersonate';
import { impersonate } from '../../hooks/useImpersonate';
import { PaginateContext } from '../../components/Table/Pagination';

const pageSize = 25;

type Worker = RouterOutput['a']['workers']['workers']['workers'][0];

const sortOptions = [
  { label: 'Asc Name (a-z)', value: 'fullName.asc' },
  { label: 'Desc Name (z-a)', value: 'fullName.desc' },
  { label: 'created desc', value: 'createdAt.desc' },
  { label: 'created asc', value: 'createdAt.asc' },
];

const filterOptions = [
  {
    name: 'Filter',
    options: [
      { label: 'no bank account info', value: 'noBankInfo' },
      { label: 'no tax id', value: 'noTaxId' },
    ],
  },
];

function variables({
  page,
  searchTerm,
  sort,
  selectedFilters,
}: {
  page: number;
  searchTerm: string | undefined;
  sort: string | undefined;
  selectedFilters: SelectedFilter[];
}) {
  return {
    page,
    limit: pageSize,
    searchTerm,
    sort,
    filters: {
      bankAccountNumber: !selectedFilters.find(
        (data) => data.optionValue === 'noBankInfo',
      ),
      bankRoutingNumber: !selectedFilters.find(
        (data) => data.optionValue === 'noBankInfo',
      ),
      taxIdNumber: !selectedFilters.find(
        (data) => data.optionValue === 'noTaxId',
      ),
    },
  };
}

function selectedFiltersReducer(
  state: SelectedFilter[],
  action: SelectedFilter,
) {
  const filters = unionBy([action, ...state], 'optionLabel');
  return filters;
}

const columnHelper = createColumnHelper<Worker>();

function TableActionBtn({ data }: { data: Worker }) {
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const [worker, setWorker] = useState<{
    id: string;
    firstName: string | null;
    lastName: string | null;
  } | null>(null);

  const [open, setOpen] = useState(false);
  const { mutate: deleteWorkerProfile } =
    trpc.a.workers.deleteWorkerProfile.useMutation({
      onSuccess: () => {
        setOpen(false);
        setWorker(null);
        utils.a.workers.workers.invalidate().then(() => {
          navigate('/workers');
        });
      },
    });

  const { mutate } = trpc.a.workers.impersonate.useMutation({
    onSuccess: (jwt: string) => {
      impersonate(jwt);
    },
  });

  const { id, firstName, lastName } = data;
  return (
    <div className="text-right">
      <button
        type="button"
        onClick={() => {
          setWorker({ id, firstName, lastName });
          setOpen(true);
        }}
        className="text-indigo-600 hover:text-indigo-900  ml-4"
      >
        Delete
      </button>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          navigate(`/workerProfiles/${id}`);
        }}
        className="text-indigo-600 hover:text-indigo-900  ml-4"
      >
        Edit
      </button>
      <button
        type="button"
        onClick={() => {
          mutate({ workerProfileId: id });
        }}
        className="text-indigo-600 hover:text-indigo-900 ml-4"
      >
        Impersonate
      </button>

      {worker && (
        <AlertModal
          open={open}
          setOpen={setOpen}
          title={`Delete ${worker.firstName} ${worker.lastName}?`}
          description={`Are you sure you want to delete ${worker.firstName} ${worker.lastName}? This action cannot be undone.`}
          onConfirm={() => {
            deleteWorkerProfile({ workerProfileId: worker.id });
          }}
        />
      )}
    </div>
  );
}

const columns = [
  columnHelper.accessor('fullName', {
    header: () => `Worker's Name`,
    cell: (info) => <span>{titleCase(info.getValue())}</span>,
  }),
  columnHelper.display({
    id: 'actions',
    header: () => <span className="sr-only">Actions</span>,
    cell: (props) => <TableActionBtn data={props.row.original} />,
  }),
];

export function Workers() {
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sort, setSort] = useState('createdAt.desc');

  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    selectedFiltersReducer,
    [],
  );

  const debouncedSearchTerm = useDebounce<string>(searchTerm, 275);

  const { data, error, isLoading, refetch } = trpc.a.workers.workers.useQuery(
    variables({ page, searchTerm: debouncedSearchTerm, sort, selectedFilters }),
  );

  const paginationData = useMemo(
    () => ({
      currentPage: page,
      setPage,
      nextPage: data?.nextPage,
      prevPage: data?.prevPage,
      total: data?.total,
      pageSize: data?.pageSize,
    }),
    [page, data],
  );

  useEffect(() => {
    refetch();
  }, [page, debouncedSearchTerm, sort, selectedFilters]);

  useEffect(() => {
    if (page !== 0) setPage(0);
  }, [selectedFilters]);

  return (
    <>
      <Impersonate />

      <div className="bg-gray-100 min-h-screen">
        <Header title="Workers" />

        <div className="m-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <PaginateContext.Provider value={paginationData}>
                  <Table
                    data={data?.workers || []}
                    columns={columns}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    error={error}
                    isLoading={isLoading}
                    tableType="Workers"
                    tableFilterProps={{
                      sort: {
                        name: 'Sort',
                        options: sortOptions,
                      },
                      filters: filterOptions,
                      currentFilters: selectedFilters,
                      setCurrentFilters: dispatchSelectedFilters,
                      sortValue: sort,
                      setSortValue: setSort,
                    }}
                  />
                </PaginateContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
