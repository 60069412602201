import { z } from 'zod';
import { BillingFrequencyEnum, DistributionMethodEnum } from './contracts';

export const EngagementTypeEnum = z.enum(['EOR', 'CONTRACTOR', 'PAYROLL']);

export type EngagementType = z.infer<typeof EngagementTypeEnum>;

export const eorContractEdit = z.object({
  clientId: z.string().optional(),
  billingAmountInCents: z.optional(
    z.preprocess((val) => parseFloat(val as string), z.number()),
  ),
  billingFrequency: z.optional(BillingFrequencyEnum),
  billingCurrency: z.string(),
  engagementType: z.optional(EngagementTypeEnum),
  isoCountryCode: z.string().optional(),
  name: z.string().optional(),
  distributionCurrency: z.string().optional(),
  startDate: z.string().optional(),
  jobTitle: z.string().optional(),
  seniorityLevel: z.string().optional(),
  scopeOfWork: z.string().optional(),
  distributionFrequency: z.string(),
  distributionMethod: z.optional(DistributionMethodEnum),
});

export type EorContractEdit = z.infer<typeof eorContractEdit>;
