import { zodResolver } from '@hookform/resolvers/zod';
import {
  PasswordResetType,
  passwordResetZod,
} from 'listo/src/zodObjects/credentials';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { trpc } from '../../lib/trpc';
import Logo from '../../public/images/circle-logo.svg';

export function ForgotPassword() {
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [failedEmail, setFailedEmail] = useState(false);

  const { mutateAsync: sendForgotPasswordEmail } =
    trpc.p.auth.requestPasswordReset.useMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordResetType>({
    resolver: zodResolver(passwordResetZod),
  });

  const onSubmit: SubmitHandler<PasswordResetType> = (data) => {
    setIsLoading(true);

    sendForgotPasswordEmail(data)
      .then(() => {
        setEmailSent(true);
      })
      .catch(() => {
        setFailedEmail(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={Logo} alt="logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {emailSent && (
            <div className="bg-green-50 border-l-4 border-green-800 p-2 mt-4 mb-8">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-green-800">
                    Check Email to Reset Password
                  </h3>
                </div>
              </div>
            </div>
          )}

          {failedEmail && (
            <div className="bg-red-50 border-l-4 border-red-800 p-2 mt-8">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    Email Failed to Send
                  </h3>
                </div>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  type="email"
                  autoComplete="email"
                  {...register('email', {
                    required: true,
                    pattern: /(.+)@(.+){2,}\.(.+){2,}/,
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errors.email && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Your email is invalid.
                  </p>
                )}
              </div>
            </div>

            <div>
              {!isLoading ? (
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign in
                </button>
              ) : (
                <button
                  type="button"
                  disabled
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <svg
                    className="animate-spin h-5 w-5 mr-3 ..."
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
