import { Alert } from './Alert';

export function buildErrors(keys: string[], errors: any): string[] {
  return keys
    .map((key) => {
      if (errors[key]) {
        return `${key} - ${errors[key].message}`;
      }
      return '';
    })
    .filter((error) => error !== '');
}

export { Alert };
