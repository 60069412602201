import 'react-tooltip/dist/react-tooltip.css';

import { Tooltip } from 'react-tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export function InfoBubble({
  tooltipId,
  tooltipContent,
}: {
  tooltipId: string;
  tooltipContent: string;
}) {
  return (
    <div className="inline-block px-1" style={{ verticalAlign: 'text-bottom' }}>
      <InformationCircleIcon
        width={20}
        height={20}
        data-tooltip-id={tooltipId}
        data-tooltip-html={tooltipContent}
      />
      <Tooltip id={tooltipId} />
    </div>
  );
}
