import { HTMLProps } from 'react';
import {
  FieldValues,
  Path,
  UseFormRegister,
  FieldErrors,
  UnPackAsyncDefaultValues,
} from 'react-hook-form';
import { InfoBubble } from '../InfoBubble';

export interface InputProperties<T extends FieldValues>
  extends HTMLProps<unknown> {
  label?: string;
  error?: string;
  reactHookForm?: {
    register: UseFormRegister<T>;
    fieldName: Path<UnPackAsyncDefaultValues<T>>;
    errors: FieldErrors<T>;
  };
  infoTooltipId?: string;
  infoTooltipContent?: string;
}

export function Field<T extends FieldValues>({
  error,
  label,
  children,
  reactHookForm,
  infoTooltipId,
  infoTooltipContent,
}: InputProperties<T>) {
  const reactHookError = reactHookForm?.errors[reactHookForm?.fieldName]
    ?.message as string | undefined;

  return (
    <div className="w-full">
      {label ? (
        <label
          htmlFor={label.toLowerCase()}
          className="block mb-1 text-sm font-medium text-gray-700"
        >
          {label}
          {infoTooltipId && infoTooltipContent && (
            <InfoBubble
              tooltipId={infoTooltipId}
              tooltipContent={infoTooltipContent}
            />
          )}
        </label>
      ) : null}
      <div className="mt-1">{children}</div>
      {error || reactHookError ? (
        <p
          className="mt-2 text-sm text-red-600"
          id={`${label?.toLowerCase()}-error`}
        >
          {error || reactHookError}
        </p>
      ) : null}
    </div>
  );
}
