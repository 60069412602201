import Header from '../../components/Header';
import { InvoicesTable } from './Table';

export function Invoices() {
  // const [filters] = useState<Filters>([
  //   {
  //     name: 'Filters',
  //     options: [
  //       { label: 'Generated', value: 'GENERATED' },
  //       { label: 'Gathering', value: 'GATHERING' },
  //       { label: 'Approval', value: 'APPROVAL' },
  //       { label: 'Invoiced', value: 'INVOICED' },
  //       { label: 'Collecting', value: 'COLLECTING' },
  //       { label: 'Distributing', value: 'DISTRIBUTING' },
  //       { label: 'Complete', value: 'COMPLETE' },
  //     ],
  //   },
  // ]);

  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <Header
        title="Invoices"
        buttonRoute="/invoices/create"
        buttonText="Create invoice"
      />

      <div className=" mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <InvoicesTable />
      </div>
    </main>
  );
}
