import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { trpc } from '../../lib/trpc';

export function Invitations({ contractId }: { contractId: string }) {
  const setNotification = useNotification((state) => state.setNotification);

  const { mutateAsync: copyLink, isLoading: copyLoading } =
    trpc.a.contracts.invitationLink.useMutation();

  const { mutate, isLoading: inviteLoading } =
    trpc.a.contracts.invite.useMutation({
      onSuccess: () => {
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Email sent',
        });
      },
      onError: () => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'something went wrong',
        });
      },
    });

  return (
    <ActionPanel
      title="Invite to Contract"
      content="Send an email to the contract or copy the link"
    >
      <div className="flex flex-col">
        <Button
          variant="secondary"
          text="Invitation Email"
          loading={inviteLoading}
          onClick={() => {
            mutate({ contractId });
          }}
        />
        <Button
          className="mt-4"
          loading={copyLoading}
          text="Copy Link"
          onClick={() => {
            copyLink({ contractId })
              .then((link) => {
                navigator.clipboard.writeText(link);
                setNotification({
                  type: 'success',
                  title: 'Success',
                  message: 'Link copied to clipboard',
                });
              })
              .catch(() => {
                setNotification({
                  type: 'error',
                  title: 'Error',
                  message: 'something went wrong',
                });
              });
          }}
        />
      </div>
    </ActionPanel>
  );
}
