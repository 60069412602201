import { Menu, Popover, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  MagnifyingGlassCircleIcon,
} from '@heroicons/react/24/outline';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { classNames } from 'listo/src/utils/strings';
import { Fragment } from 'react';
import { Empty, Loading } from 'ui/src/components/Table';

import Pagination from './Pagination';

interface ReactTableProps<T> {
  data: T[];
  columns: ColumnDef<T, string>[];
  searchTerm?: string;
  setSearchTerm: (value: string) => void;
  isLoading: boolean;
  tableType: string;
  error?: any;
  tableFilterProps?: TableFilterProps;
}

type SearchProps = {
  searchTerm?: string;
  setSearchTerm: (value: string) => void;
  tableType: string;
};

interface FilterProps {
  name: string;
  options: { value: string; label: string }[];
}

export type SelectedFilter = {
  filterName: string;
  optionLabel: string;
  optionValue: string | null;
};

interface TableFilterProps {
  filters: FilterProps[];
  currentFilters: SelectedFilter[];
  setCurrentFilters: (currentFilter: SelectedFilter) => void;
  sort: FilterProps;
  sortValue: string;
  setSortValue: (sortValue: string) => void;
  filterByTermValue?: string;
  setFilterByTermValue?: (filterByTerm: string) => void;
}

export function Search({ searchTerm, setSearchTerm, tableType }: SearchProps) {
  return (
    <div>
      <label htmlFor="mobile-search-candidate" className="sr-only">
        Search
      </label>
      <label htmlFor="desktop-search-candidate" className="sr-only">
        Search
      </label>
      <div className="relative flex-grow focus-within:z-10 pr-4">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassCircleIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          onChange={({ target: { value } }) => setSearchTerm(value)}
          value={searchTerm === undefined ? '' : searchTerm}
          type="text"
          name="mobile-search-candidate"
          id="mobile-search-candidate"
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded rounded-l-md pl-10 sm:hidden border-gray-300"
          placeholder="Search"
        />
        <input
          onChange={({ target: { value } }) => setSearchTerm(value)}
          value={searchTerm === undefined ? '' : searchTerm}
          type="text"
          name="desktop-search-candidate"
          id="desktop-search-candidate"
          className="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded rounded-l-md pl-10 sm:block sm:text-sm border-gray-300"
          placeholder={`Search ${tableType}`}
        />
      </div>
    </div>
  );
}

function Filter({
  filters,
  currentFilters,
  setCurrentFilters,
  setSortValue,
  sort,
  sortValue,
  filterByTermValue,
  setFilterByTermValue,
}: TableFilterProps) {
  return (
    <>
      <Menu
        as="div"
        className="relative z-10 inline-block text-left ml-auto mr-5"
      >
        <div>
          <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
            Sort
            <ChevronDownIcon
              className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-left absolute left-0 z-10 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {sort.options.map((option) => (
                <Menu.Item key={option.value}>
                  <button
                    type="button"
                    onClick={() => setSortValue(option.value)}
                    className={classNames(
                      sortValue === option.value ? 'bg-gray-100' : '',
                      'block w-full text-left px-4 py-2 text-sm font-medium text-gray-900',
                    )}
                  >
                    {option.label}
                  </button>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <button
        type="button"
        className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
        onClick={() => null}
      >
        Filters
      </button>

      <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
        {filters.map((filter) => {
          const numberOfSelectedOptions = currentFilters.reduce((acc, f) => {
            if (f.filterName === filter.name && f.optionValue) {
              return acc + 1;
            }
            return acc;
          }, 0);
          return (
            <Popover
              as="div"
              key={filter.name}
              id="desktop-menu"
              className="relative z-10 inline-block text-left ml-5"
            >
              <div>
                <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <span>{filter.name}</span>

                  {numberOfSelectedOptions > 0 ? (
                    <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                      {numberOfSelectedOptions}
                    </span>
                  ) : null}

                  <ChevronDownIcon
                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Popover.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[15rem] max-h-[80vh] overflow-y-auto">
                  {filter.name === 'Clients' && (
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded rounded-l-md pl-1 mb-2 border-gray-300"
                      placeholder="Find Client"
                      value={filterByTermValue}
                      onChange={({ target: { value } }) =>
                        setFilterByTermValue!(value)
                      }
                    />
                  )}

                  <form className="space-y-4">
                    {filter.options.map((option) => (
                      <div key={option.value} className="flex items-center">
                        <input
                          id={`filter-${filter.name}-${option.value}`}
                          name={`${filter.name}[]`}
                          defaultValue={option.value}
                          checked={
                            !!currentFilters.find(
                              (cf: any) =>
                                cf.optionValue === option.value &&
                                cf.filterName === filter.name,
                            )
                          }
                          type="checkbox"
                          className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                          onChange={({ target: { checked } }) => {
                            setCurrentFilters({
                              filterName: filter.name,
                              optionLabel: option.label,
                              optionValue: checked ? option.value : null,
                            });
                          }}
                        />
                        <label
                          htmlFor={`filter-${filter.name}-${option.value}`}
                          className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                        >
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </form>
                </Popover.Panel>
              </Transition>
            </Popover>
          );
        })}
      </Popover.Group>
    </>
  );
}

export function TableComponent<T extends object>({
  data,
  columns,
  isLoading,
  error,
}: {
  data: T[];
  columns: ColumnDef<T, string>[];

  isLoading: boolean;
  error?: any;
}) {
  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) return <Loading />;
  if (data && data.length === 0) return <Empty />;
  if (error) return <Empty />;

  return (
    <div className="mt-6 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Table<T extends object>({
  data,
  columns,
  searchTerm,
  setSearchTerm,
  isLoading,
  error,
  tableType,
  tableFilterProps,
}: ReactTableProps<T>) {
  return (
    <div className="m-2 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 min-h-[50vh]">
        <div className="inline-block min-w-full py-2 align-middle lg:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
              <Search
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                tableType={tableType}
              />
              {tableFilterProps && (
                <Filter
                  filters={tableFilterProps.filters}
                  currentFilters={tableFilterProps.currentFilters}
                  setCurrentFilters={tableFilterProps.setCurrentFilters}
                  setSortValue={tableFilterProps.setSortValue}
                  sort={tableFilterProps.sort}
                  sortValue={tableFilterProps.sortValue}
                  filterByTermValue={tableFilterProps.filterByTermValue}
                  setFilterByTermValue={tableFilterProps.setFilterByTermValue}
                />
              )}
            </div>
            <TableComponent
              data={data}
              columns={columns}
              isLoading={isLoading}
              error={error}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
