import numeral from 'numeral';
import { isInteger, round } from 'lodash';
import { twoDigitIsoCode } from '../countries';

export function formatPaymentAmount(
  amount: number,
  frequency: string,
  currency: string,
) {
  const formattedNumber = formatCentsToDollars(amount, currency);

  switch (frequency) {
    case 'ANNUALLY':
      return `${formattedNumber}/year`;
    case 'HOURLY':
      return `${formattedNumber}/hr`;
    default:
      return `${formattedNumber}/month`;
  }
}

export function stringWithDollarSignToFloat(value: string) {
  return parseFloat(value.replace(/\$|,/g, ''));
}

export function floatInDollarsToCents(amount: number | undefined) {
  if (amount === undefined) return 0;

  const fixed = amount.toFixed(2);

  if (fixed.includes('.')) {
    const [stringDollars, cents] = fixed.split('.');
    const stringCents = cents?.slice(0, 2);
    const numberCents = Number(stringCents);
    const dollars = Number(stringDollars);

    return dollars * 100 + numberCents;
  }
  return amount * 100;
}

export function centsToDollars(amount: number) {
  return amount / 100;
}

export function dollarsToCents(amount: number) {
  return amount * 100;
}

export function formatCurrency(dollars: number, currency: string | null) {
  const options: Partial<Intl.NumberFormatOptions> = isInteger(dollars)
    ? { maximumFractionDigits: 0 }
    : { minimumFractionDigits: 2 };

  if (!currency) {
    return `${Intl.NumberFormat('en-us', { ...options }).format(
      dollars,
    )} (unknown currency)`;
  }
  return Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: currency ?? undefined,
    ...options,
  }).format(dollars);
}

export function formatCentsToDollars(amount: number, currency: string | null) {
  const dollars = round(amount / 100, 2);
  return formatCurrency(dollars, currency);
}

export function dollarCurrencyFormat(amount: number) {
  return numeral(amount).format('$(0,0.00)');
}

const ibanCountries = [
  'AL',
  'AD',
  'AT',
  'AZ',
  'BH',
  'BY',
  'BE',
  'BA',
  'BR',
  'BG',
  'CR',
  'HR',
  'CY',
  'CZ',
  'DK',
  'DO',
  'EG',
  'SV',
  'EE',
  'FO',
  'FI',
  'FR',
  'GE',
  'DE',
  'GI',
  'GR',
  'GL',
  'GT',
  'VA',
  'HU',
  'IS',
  'IQ',
  'IE',
  'IL',
  'IT',
  'JO',
  'KZ',
  'XK',
  'KW',
  'LV',
  'LB',
  'LY',
  'LI',
  'LT',
  'LU',
  'MT',
  'MR',
  'MU',
  'MD',
  'MC',
  'ME',
  'NL',
  'MK',
  'NO',
  'PK',
  'PS',
  'PL',
  'PT',
  'QA',
  'RO',
  'LC',
  'SM',
  'ST',
  'SA',
  'RS',
  'SC',
  'SK',
  'SI',
  'ES',
  'SD',
  'SE',
  'CH',
  'TL',
  'TN',
  'TR',
  'UA',
  'AE',
  'GB',
  'VG',
];

/**
 * Whether a country requires IBAN numbers for international transfers
 */
export function requiresIban(countryCode?: string | null): boolean {
  if (!countryCode) return false;
  const twoDigit = twoDigitIsoCode(countryCode);

  return ibanCountries.includes(twoDigit.toUpperCase());
}
