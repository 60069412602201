import axios from 'axios';
import { useState } from 'react';
import { Button } from 'ui/src/components/Button';
import { PageLoader } from 'ui/src/components/PageLoader';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

async function uploadFile(signedUrl: string, file: File) {
  await axios.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
}

export function BulkContracts() {
  const [presignedUrl, setPresignedUrl] = useState<string | null>(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const [clientId, setClientId] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const { data: clients, isLoading: clientsLoading } =
    trpc.a.clients.list.useQuery({});

  const { isLoading } = trpc.a.contracts.presignedUrl.useQuery(undefined, {
    onSuccess: (data) => {
      setPresignedUrl(data.presignedUrl);
      setFileName(data.fileName);
    },
    trpc: {},
  });

  const { mutate: bulkUpload } = trpc.a.contracts.bulkUpload.useMutation({
    onSuccess: () => {
      setSuccess(true);
    },
  });

  if (isLoading || clientsLoading || clients === undefined)
    return <PageLoader />;

  return (
    <div>
      <Header title="Bulk Upload Contractors" />
      {success ? (
        <div className="p-8">
          <h1 className="text-lg font-bold">
            Your file is being processed. Check Slack for an update.
          </h1>
        </div>
      ) : (
        <div className="p-8">
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <select
                defaultValue="Select Client"
                onChange={(e) => {
                  setClientId(e.target.value);
                }}
              >
                <option disabled hidden value="Select Client">
                  Select Client
                </option>
                {clients.map((c) => (
                  <option key={c.id} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="sm:col-span-3">
              {presignedUrl ? (
                <input
                  type="file"
                  accept=".csv"
                  onChange={async (f) => {
                    if (f.target.files && f.target.files[0]) {
                      try {
                        await uploadFile(presignedUrl, f.target.files[0]);
                        setFileUploaded(true);
                      } catch (e) {
                        // eslint-disable-next-line no-console
                        console.error('e ', e);
                      }
                    }
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="flex justify-end">
            {fileName && fileUploaded && clientId ? (
              <Button
                text="Submit"
                onClick={() => bulkUpload({ fileName, clientId })}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}
