import { PlusIcon as PlusIconOutline } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatDate } from 'listo/src/utils/dates';
import { classNames, titleCase } from 'listo/src/utils/strings';
import { invoiceForm, InvoiceForm } from 'listo/src/zodObjects/invoices';
import { useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { PageLoader } from 'ui/src/components/PageLoader';
import { SelectInput } from 'ui/src/components/SelectInput';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';
import LineItems, { calculateTotal } from './LineItems';

export function InvoiceCreate() {
  const [clientId, setClientId] = useState<string | undefined>();
  const [total, setTotal] = useState<string>();
  const setNotification = useNotification((state) => state.setNotification);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<InvoiceForm>({ resolver: zodResolver(invoiceForm) });

  const { data: clients, isLoading, error } = trpc.a.clients.list.useQuery({});
  const { data: payPeriods, isLoading: payPeriodsLoading } =
    trpc.a.payPeriods.list.useQuery(
      {
        filters: {
          clientId,
        },
      },
      {
        enabled: !!clientId,
      },
    );

  const { mutate: createInvoice, isLoading: createInvoiceLoading } =
    trpc.a.invoices.create.useMutation({
      onSuccess: () => {
        navigate('/invoices');
        setNotification({
          type: 'success',
          title: 'Invoice created',
          message: 'Invoice created successfully',
        });
      },
      onError: (e) => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: e.message,
        });
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lineItems',
  });

  const onSubmit: SubmitHandler<InvoiceForm> = (data) => {
    if (!clientId) {
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Select a client',
      });
      return;
    }
    createInvoice({
      clientId,
      invoiceForm: data,
    });
  };

  if (isLoading) return <PageLoader />;
  if (error) return <div>{error.message}</div>;
  if (clients === undefined) return <PageLoader />;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Create Invoice" />
      <div className="align-middle sm:m-8 m-2">
        <div className="bg-white shadow sm:rounded-lg px-4 sm:px-6 lg:px-8 py-8">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div className="flex justify-between">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Invoice Overview
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Enter overview details
                    </p>
                  </div>
                  <div className="flex items-center">
                    <Button
                      variant="secondary"
                      text="Calculate Total"
                      onClick={(e) => {
                        e.preventDefault();
                        const { lineItems } = getValues();
                        const t = calculateTotal(lineItems);
                        // add a line item for the
                        setTotal(t);
                      }}
                    />
                    <h1 className="ml-2 text-xl font-semibold text-gray-900">
                      {total}
                    </h1>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-2">
                    <SelectInput
                      label="Provider"
                      defaultValue="QUICKBOOKS"
                      options={['STRIPE', 'QUICKBOOKS'].map((c) => ({
                        value: c,
                        label: titleCase(c),
                      }))}
                      reactHookForm={{
                        register,
                        fieldName: 'externalProvider',
                        errors,
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <SelectInput
                      loading={isLoading}
                      label="Client"
                      defaultOption={
                        { label: 'Select a Client', value: '' } as const
                      }
                      options={clients.map((c) => ({
                        value: c.id,
                        label: c.name,
                      }))}
                      selectProps={{
                        onChange: ({ target: { value } }) => {
                          setClientId(value);
                        },
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <SelectInput
                      loading={payPeriodsLoading}
                      label="Pay Period"
                      defaultOption={
                        {
                          label: 'Select a Pay Period (optional)',
                          value: '',
                        } as const
                      }
                      options={
                        payPeriods?.map((c) => ({
                          value: c.id,
                          label: `${formatDate(c.periodStart)} - ${formatDate(
                            c.periodEnd,
                          )}`,
                        })) || []
                      }
                      reactHookForm={{
                        register,
                        fieldName: 'payPeriodId',
                        errors,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="pt-8">
                <div className="mb-8 ">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Line Items
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">Add line items</p>
                </div>

                <LineItems
                  fields={fields}
                  clientId={clientId}
                  payPeriodId={getValues().payPeriodId}
                  getValues={getValues}
                  setValue={setValue}
                  remove={remove}
                  register={register}
                  errors={errors}
                />

                <div className="flex justify-end sm:pt-4 pt-2">
                  <button
                    disabled={!clientId}
                    type="button"
                    className={classNames(
                      'inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                      clientId ? '' : 'disabled opacity-50 cursor-not-allowed',
                    )}
                    onClick={() =>
                      append({
                        // default value of empty string
                        chargeType: '' as any,
                        contractId: '',
                        description: '',
                        amountInCents: 0,
                      })
                    }
                  >
                    <PlusIconOutline className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>

            <div className="pt-4">
              <div className="flex justify-end">
                <Button
                  text="Cancel"
                  className="mr-3"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/invoices');
                  }}
                  variant="secondary"
                />
                <Button
                  loading={createInvoiceLoading}
                  text="Save"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
