import { z } from 'zod';
import { countryCodes } from '../countries';

export const defaultPricing = {
  eorFee: {
    AL: 50000,
    DZ: 50000,
    AO: 50000,
    AR: 50000,
    AM: 50000,
    AU: 50000,
    AT: 50000,
    AZ: 50000,
    BS: 50000,
    BH: 50000,
    BD: 50000,
    BY: 50000,
    BE: 50000,
    BZ: 50000,
    BO: 50000,
    BA: 50000,
    BR: 50000,
    VG: 50000,
    BG: 50000,
    BF: 50000,
    KH: 50000,
    CA: 50000,
    CL: 50000,
    CN: 50000,
    CO: 50000,
    CR: 50000,
    HR: 50000,
    CY: 50000,
    CZ: 50000,
    DK: 50000,
    DO: 50000,
    EC: 50000,
    EG: 50000,
    SV: 50000,
    EE: 50000,
    FI: 50000,
    FR: 50000,
    GE: 50000,
    DE: 50000,
    GH: 50000,
    GR: 50000,
    GT: 50000,
    HN: 50000,
    HK: 50000,
    HU: 50000,
    IS: 50000,
    IN: 50000,
    ID: 50000,
    IE: 50000,
    IL: 50000,
    IT: 50000,
    JM: 50000,
    JP: 50000,
    JO: 50000,
    KZ: 50000,
    KE: 50000,
    KR: 50000,
    KW: 50000,
    KG: 50000,
    LA: 50000,
    LV: 50000,
    LB: 50000,
    LT: 50000,
    LU: 50000,
    MO: 50000,
    MK: 50000,
    MY: 50000,
    MT: 50000,
    MU: 50000,
    MX: 50000,
    MD: 50000,
    MC: 50000,
    MA: 50000,
    NP: 50000,
    NL: 50000,
    NZ: 50000,
    NI: 50000,
    NG: 50000,
    NO: 50000,
    OM: 50000,
    PK: 50000,
    PA: 50000,
    PY: 50000,
    PE: 50000,
    PH: 50000,
    PL: 50000,
    PT: 50000,
    QA: 50000,
    RO: 50000,
    RU: 50000,
    SA: 50000,
    SN: 50000,
    RS: 50000,
    SG: 50000,
    SK: 50000,
    SI: 50000,
    ZA: 50000,
    ES: 50000,
    LK: 50000,
    SE: 50000,
    CH: 50000,
    TW: 50000,
    TH: 50000,
    TT: 50000,
    TN: 50000,
    TR: 50000,
    UA: 50000,
    AE: 50000,
    GB: 50000,
    US: 50000,
    UY: 50000,
    UZ: 50000,
    VE: 50000,
    VN: 50000,
  },
  contractorFee: {
    AL: 4500,
    DZ: 4500,
    AO: 4500,
    AR: 4500,
    AM: 4500,
    AU: 4500,
    AT: 4500,
    AZ: 4500,
    BS: 4500,
    BH: 4500,
    BD: 4500,
    BY: 4500,
    BE: 4500,
    BZ: 4500,
    BO: 4500,
    BA: 4500,
    BR: 4500,
    VG: 4500,
    BG: 4500,
    BF: 4500,
    KH: 4500,
    CA: 4500,
    CL: 4500,
    CN: 4500,
    CO: 4500,
    CR: 4500,
    HR: 4500,
    CY: 4500,
    CZ: 4500,
    DK: 4500,
    DO: 4500,
    EC: 4500,
    EG: 4500,
    SV: 4500,
    EE: 4500,
    FI: 4500,
    FR: 4500,
    GE: 4500,
    DE: 4500,
    GH: 4500,
    GR: 4500,
    GT: 4500,
    HN: 4500,
    HK: 4500,
    HU: 4500,
    IS: 4500,
    IN: 4500,
    ID: 4500,
    IE: 4500,
    IL: 4500,
    IT: 4500,
    JM: 4500,
    JP: 4500,
    JO: 4500,
    KZ: 4500,
    KE: 4500,
    KR: 4500,
    KW: 4500,
    KG: 4500,
    LA: 4500,
    LV: 4500,
    LB: 4500,
    LT: 4500,
    LU: 4500,
    MO: 4500,
    MK: 4500,
    MY: 4500,
    MT: 4500,
    MU: 4500,
    MX: 4500,
    MD: 4500,
    MC: 4500,
    MA: 4500,
    NP: 4500,
    NL: 4500,
    NZ: 4500,
    NI: 4500,
    NG: 4500,
    NO: 4500,
    OM: 4500,
    PK: 4500,
    PA: 4500,
    PY: 4500,
    PE: 4500,
    PH: 4500,
    PL: 4500,
    PT: 4500,
    QA: 4500,
    RO: 4500,
    RU: 4500,
    SA: 4500,
    SN: 4500,
    RS: 4500,
    SG: 4500,
    SK: 4500,
    SI: 4500,
    ZA: 4500,
    ES: 4500,
    LK: 4500,
    SE: 4500,
    CH: 4500,
    TW: 4500,
    TH: 4500,
    TT: 4500,
    TN: 4500,
    TR: 4500,
    UA: 4500,
    AE: 4500,
    GB: 4500,
    US: 4500,
    UY: 4500,
    UZ: 4500,
    VE: 4500,
    VN: 4500,
  },
} as const;

const countrySpecificPriceZod = z.object({
  AL: z.number(),
  DZ: z.number(),
  AO: z.number(),
  AR: z.number(),
  AM: z.number(),
  AU: z.number(),
  AT: z.number(),
  AZ: z.number(),
  BS: z.number(),
  BH: z.number(),
  BD: z.number(),
  BY: z.number(),
  BE: z.number(),
  BZ: z.number(),
  BO: z.number(),
  BA: z.number(),
  BR: z.number(),
  VG: z.number(),
  BG: z.number(),
  BF: z.number(),
  KH: z.number(),
  CA: z.number(),
  CL: z.number(),
  CN: z.number(),
  CO: z.number(),
  CR: z.number(),
  HR: z.number(),
  CY: z.number(),
  CZ: z.number(),
  DK: z.number(),
  DO: z.number(),
  EC: z.number(),
  EG: z.number(),
  SV: z.number(),
  EE: z.number(),
  ET: z.number(),
  FI: z.number(),
  FR: z.number(),
  GE: z.number(),
  DE: z.number(),
  GH: z.number(),
  GR: z.number(),
  GT: z.number(),
  HN: z.number(),
  HK: z.number(),
  HU: z.number(),
  IN: z.number(),
  ID: z.number(),
  IE: z.number(),
  IL: z.number(),
  IT: z.number(),
  CI: z.number(),
  JM: z.number(),
  JP: z.number(),
  KZ: z.number(),
  KE: z.number(),
  KW: z.number(),
  LV: z.number(),
  LY: z.number(),
  LT: z.number(),
  LU: z.number(),
  MO: z.number(),
  MK: z.number(),
  MW: z.number(),
  MY: z.number(),
  MT: z.number(),
  MU: z.number(),
  MX: z.number(),
  MD: z.number(),
  MN: z.number(),
  ME: z.number(),
  MA: z.number(),
  MM: z.number(),
  NP: z.number(),
  NL: z.number(),
  NZ: z.number(),
  NI: z.number(),
  NE: z.number(),
  NG: z.number(),
  NO: z.number(),
  OM: z.number(),
  PK: z.number(),
  PA: z.number(),
  PY: z.number(),
  PE: z.number(),
  PH: z.number(),
  PL: z.number(),
  PT: z.number(),
  PR: z.number(),
  QA: z.number(),
  RO: z.number(),
  RU: z.number(),
  RW: z.number(),
  SN: z.number(),
  RS: z.number(),
  SG: z.number(),
  SK: z.number(),
  SI: z.number(),
  ZA: z.number(),
  KR: z.number(),
  ES: z.number(),
  LK: z.number(),
  SE: z.number(),
  CH: z.number(),
  TW: z.number(),
  TZ: z.number(),
  TH: z.number(),
  TT: z.number(),
  TN: z.number(),
  TR: z.number(),
  UA: z.number(),
  AE: z.number(),
  GB: z.number(),
  US: z.number(),
  UY: z.number(),
  UZ: z.number(),
  VE: z.number(),
  VN: z.number(),
});

export const pricingZod = z.object({
  eorFee: countrySpecificPriceZod.partial().optional(),
  contractorFee: countrySpecificPriceZod.partial().optional(),
});

export const countryOptions = ['**', ...countryCodes].map((c) => ({
  value: c,
  label:
    c === '**' ? 'All Countries - Set for any not already being overridden' : c,
  available: true,
}));

export type Pricing = z.infer<typeof pricingZod>;
