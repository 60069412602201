import { useEffect, useMemo, useReducer, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { unionBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Impersonate from '../../components/Impersonate';
import Table from '../../components/Table/Table';
import { useDebounce } from '../../hooks/useDebounce';
import { impersonate } from '../../hooks/useImpersonate';
import { RouterOutput, trpc } from '../../lib/trpc';
import { SelectedFilter } from './ClientFilters';
import { PaginateContext } from '../../components/Table/Pagination';

type Client = RouterOutput['a']['clients']['paginate']['clients'][0];

const sortOptions = [
  { label: 'Asc Name (a-z)', value: 'name.asc' },
  { label: 'Desc Name (z-a)', value: 'name.desc' },
  { label: 'created desc', value: 'createdAt.desc' },
  { label: 'created asc', value: 'createdAt.asc' },
];

const filterOptions = [
  {
    name: 'Status',
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Inactive', value: 'INACTIVE' },
    ],
  },
];

function selectedFiltersReducer(
  state: SelectedFilter[],
  action: SelectedFilter,
) {
  const filters = unionBy([action, ...state], 'optionLabel');
  return filters;
}

function TableActionBtn({ data }: { data: Client }) {
  const navigate = useNavigate();
  const { mutate } = trpc.a.clients.impersonate.useMutation({
    onSuccess: (jwt: string) => {
      impersonate(jwt);
    },
  });
  const { id } = data;

  return (
    <div className="text-right">
      <button
        type="button"
        onClick={() => {
          navigate(`/clients/${id}/info`);
        }}
        className="text-indigo-600 hover:text-indigo-900  ml-4"
      >
        View
      </button>
      <button
        type="button"
        onClick={() => {
          mutate(id);
        }}
        className="text-indigo-600 hover:text-indigo-900 ml-4"
      >
        Impersonate
      </button>
    </div>
  );
}

const columnHelper = createColumnHelper<Client>();

const columns = [
  columnHelper.accessor('name', {
    header: () => 'Client Name',
  }),
  columnHelper.display({
    id: 'actions',
    header: () => '',
    cell: (props) => <TableActionBtn data={props.row.original} />,
  }),
];

function variables({
  searchTerm,
  sort,
  selectedFilters,
  page,
}: {
  searchTerm: string | undefined;
  sort: string;
  selectedFilters: SelectedFilter[];
  page?: number;
}) {
  const statusFilters = selectedFilters
    .filter((filter) => filter.filterName === 'Status' && !!filter.optionValue)
    .map((filter) => filter.optionValue);

  return {
    page,
    limit: 10,
    searchTerm,
    sort,
    filters: {
      status: statusFilters.length ? statusFilters.join('|') : undefined,
    },
  };
}

export function Clients() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sort, setSort] = useState('createdAt.desc');
  const [page, setPage] = useState(0);

  const debouncedSearchTerm = useDebounce<string>(searchTerm, 275);

  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    selectedFiltersReducer,
    [{ filterName: 'Status', optionLabel: 'Active', optionValue: 'ACTIVE' }],
  );

  const { data, isLoading, error, refetch } = trpc.a.clients.paginate.useQuery(
    variables({ page, searchTerm: debouncedSearchTerm, sort, selectedFilters }),
  );

  const paginationData = useMemo(
    () => ({
      setPage,
      currentPage: page,
      nextPage: data?.nextPage,
      prevPage: data?.prevPage,
      total: data?.total,
      pageSize: data?.pageSize,
    }),
    [page, data],
  );

  useEffect(() => {
    if (page !== 0) setPage(0);
  }, [selectedFilters]);

  useEffect(() => {
    refetch();
  }, [page, debouncedSearchTerm, sort, selectedFilters]);

  return (
    <>
      <Impersonate />
      <div className="bg-gray-100 min-h-screen">
        <Header
          title="Clients"
          buttonRoute="/clients/create"
          buttonText="Create"
        />
        <div className="m-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div>
                <PaginateContext.Provider value={paginationData}>
                  <Table
                    data={data?.clients ?? []}
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                    columns={columns}
                    isLoading={isLoading}
                    error={error}
                    tableType="Clients"
                    tableFilterProps={{
                      sort: {
                        name: 'Sort',
                        options: sortOptions,
                      },
                      filters: filterOptions,
                      currentFilters: selectedFilters,
                      setCurrentFilters: dispatchSelectedFilters,
                      sortValue: sort,
                      setSortValue: setSort,
                    }}
                  />
                </PaginateContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
