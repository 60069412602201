import { z } from 'zod';

export const ClientDispositionEnum = z.enum(['ACTIVE', 'INACTIVE']);

export const clientFields = z.object({
  name: z.string(),
  disposition: ClientDispositionEnum,
});

export const createClientZod = z.object({
  client: z.object({
    name: z.string(),
    address1: z.string(),
    address2: z.string(),
    city: z.string(),
    zoneCode: z.string(),
    postalCode: z.string(),
    isoCountryCode: z.string(),
  }),
  entity: z.object({
    name: z.string(),
    corporationType: z.enum([
      'individual',
      'llc',
      's-corp',
      'c-corp',
      'sole-proprietorship',
      'unknown',
    ]),
    taxIdNumber: z.string(),
  }),
  user: z.object({
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string(),
  }),
});

export const priceOverrideTypes = {
  eorFee: 'eorFee' as string,
  contractorFee: 'contractorFee' as string,
} as const;

export const priceOverrideOptions = ['eorFee', 'contractorFee'];
export const priceOverrideTypeFormOptions = [
  { value: 'eorFee', label: 'EOR Fee' },
  { value: 'contractorFee', label: 'Contractor Fee' },
];

export const pricingOverrideForm = z.object({
  clientId: z.string(),
  availableCountryOptions: z.object({
    // TODO: What should this be?
    eorFee: z.array(z.any()),
    contractorFee: z.array(z.any()),
  }),
  lineItems: z.array(
    z.object({
      overrideType: z.string(),
      countryCode: z.string(),
      amount: z.string(),
      amountInCents: z.preprocess(
        (val) => parseFloat(val as string),
        z.number().min(1),
      ),
    }),
  ),
});

export type PricingOverrideForm = z.infer<typeof pricingOverrideForm>;

export type CreateClientType = z.infer<typeof createClientZod>;

export type ClientFields = z.infer<typeof clientFields>;
