import { TRPCLink } from '@trpc/client';
import { getHTTPStatusCodeFromError } from '@trpc/server/http';
import { observable } from '@trpc/server/observable';
import type { AppRouter } from 'api/src/trpc/trpcRouter';
import { DateTime } from 'listo/src/luxonUtc';

export const customLink: TRPCLink<AppRouter> =
  () =>
  ({ next, op }) =>
    observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          const statusCode = getHTTPStatusCodeFromError({
            ...err,
            code: err?.data?.code || 'INTERNAL_SERVER_ERROR',
          });
          if (statusCode === 401) {
            localStorage.removeItem('jwt');
            window.location.href = '/login?unauthenticated=true';
          }

          if (statusCode === 500 || statusCode === 400) {
            fetch('https://admin.listoglobal.com/latestVersion.txt')
              .then((res) => res.text())
              .then((version) => {
                const latestVersion = DateTime.fromISO(
                  version.replace(/\n/g, ''),
                );
                localStorage.setItem(
                  'latestVersion',
                  JSON.stringify(latestVersion.toISO()),
                );
              });
          }

          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
