import { ArrowUpIcon } from '@heroicons/react/20/solid';
import { UsersIcon, BriefcaseIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
// import ActionItems from '../../components/ActionItems';
import EmployeesAggregate from '../../components/EmployeesAggregate';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';

export function Dashboard() {
  const { data } = trpc.a.dashboard.stats.useQuery();

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header title="Dashboard" />
      <div className="m-8 block">
        <div className="align-middle inline-block min-w-full pb-4">
          <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
              {/* clients */}
              <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    <UsersIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    Clients
                  </p>
                </dt>
                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {data?.clients}
                  </p>
                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <Link
                        to="/clients"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        {' '}
                        View all
                        <span className="sr-only">Clients</span>
                      </Link>
                    </div>
                  </div>
                </dd>
              </div>
              {/* workers */}
              <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    <BriefcaseIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    Employees
                  </p>
                </dt>
                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {data?.workersHired}
                  </p>
                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <Link
                        to="/contracts?engagementType=eor"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        {' '}
                        View all
                        <span className="sr-only"> Employees</span>
                      </Link>
                    </div>
                  </div>
                </dd>
              </div>

              {/* Contractors */}
              <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    <ArrowUpIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    Contractors
                  </p>
                </dt>
                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {data?.contractors}
                  </p>
                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <Link
                        to="/contracts?engagementType=contractor"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        {' '}
                        View all
                        <span className="sr-only"> Contractors</span>
                      </Link>
                    </div>
                  </div>
                </dd>
              </div>

              <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    <BriefcaseIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    Payroll
                  </p>
                </dt>
                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {data?.payrolls}
                  </p>
                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <Link
                        to="/contracts?engagementType=eor"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        {' '}
                        View all
                        <span className="sr-only"> Employees</span>
                      </Link>
                    </div>
                  </div>
                </dd>
              </div>

              {/* <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden sm:col-span-2">
                <ActionItems />
              </div> */}
              {data?.employeeAggregates && (
                <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg sm:col-span-2">
                  <EmployeesAggregate aggregates={data?.employeeAggregates} />
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
