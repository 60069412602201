import { useQueryClient } from '@tanstack/react-query';
import jwt_decode from 'jwt-decode';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import useLocalStorage from 'ui/src/hooks/useLocalStorage';
import { listoAxios } from '../lib/listoAxios';
import { trpc } from '../lib/trpc';

interface AuthContext {
  authed: boolean;
  login: ({ email, password }: { email: string; password: string }) => any;
  logout: () => any;
}

const authContext = createContext<Partial<AuthContext>>({});

function useAuth() {
  const [authed, setAuthed] = useState<boolean | undefined>(undefined);
  const [jwt, setJwt] = useLocalStorage('jwt', '');
  const { mutateAsync } = trpc.p.auth.adminLogin.useMutation();
  const queryClient = useQueryClient();

  const setJwtAndAuthed = (jwtPayload: string) => {
    try {
      const decoded = jwt_decode(jwtPayload);
      const jwtIsExpired = Date.now() >= (decoded as any).exp * 1000;
      if (jwtIsExpired) {
        setAuthed(false);
      } else {
        setJwt(jwtPayload);
        setAuthed(true);
        listoAxios.defaults.headers.common.Authorization = `Bearer ${jwtPayload}`;
      }
    } catch (error) {
      setAuthed(false);
    }
  };

  useEffect(() => {
    if (jwt) {
      setJwtAndAuthed(jwt);
    } else {
      setAuthed(false);
    }
  }, [jwt]);

  return {
    authed,
    login({ email, password }: { email: string; password: string }) {
      return new Promise<void>((resolve, reject) => {
        mutateAsync({
          email,
          password,
        })
          .then((result) => {
            setJwtAndAuthed(result.jwt);
            resolve();
          })
          .catch(() => {
            setAuthed(false);
            setJwt('');
            reject();
          });
      });
    },
    logout() {
      queryClient.clear();
      setAuthed(false);
      setJwt('');

      return <Navigate to="/" />;
    },
  };
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return useContext(authContext);
}
