import { DateTime as LuxonDateTime } from 'luxon';
import { DateTime as LuxonBusiness } from 'luxon-business-days';

export function plusBusinessDays(
  date: LuxonDateTime,
  amount: number = 1,
): LuxonDateTime {
  return LuxonBusiness.fromMillis(date.toMillis())
    .setZone('UTC')
    .plusBusiness({ days: amount });
}

export function minusBusinessDays(
  date: LuxonDateTime,
  amount: number = 1,
): LuxonDateTime {
  return LuxonBusiness.fromMillis(date.toMillis())
    .setZone('UTC')
    .minusBusiness({ days: amount });
}

export function isBusinessDay(date: LuxonDateTime): boolean {
  const cloned = LuxonBusiness.fromMillis(date.toMillis()).setZone('UTC');
  return cloned.isBusinessDay() && !cloned.isHoliday();
}
