import { countryNameByIsoCode } from 'listo/src/countries';
import { titleCase, truthyToYesNo } from 'listo/src/utils/strings';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { Input } from 'ui/src/components/Input';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { PageLoader } from 'ui/src/components/PageLoader';
import Header from '../../components/Header';
import UploadBox from '../../components/UploadBox';
import { APP_URL } from '../../lib/constants';
import { trpc } from '../../lib/trpc';

function UploadQuote({
  quoteId,
  clientId,
}: {
  quoteId: string;
  clientId: string;
}) {
  const utils = trpc.useContext();
  const setNotification = useNotification((state) => state.setNotification);
  const { mutate: attachUpload } = trpc.a.quotes.attachUpload.useMutation({
    onSuccess: () => {
      utils.a.quotes.quote.invalidate({ quoteId });

      setNotification({
        type: 'success',
        title: 'Success',
        message: 'Quote uploaded',
      });
    },
    onError: (err) => {
      setNotification({
        type: 'error',
        title: 'Error',
        message: err.message,
      });
    },
  });

  return (
    <div className="lg:col-start-1 lg:col-span-1">
      <section aria-labelledby="quote">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="sm:px-6 px-4 py-5">
            <h2
              id="quote"
              className="text-lg font-medium text-gray-900 leading-6"
            >
              Upload Quote
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Upload a PDF quote. The quote will be emailed to the client for
              acceptance in-platform.
            </p>
          </div>
          <div className="p-4">
            <UploadBox
              clientId={clientId}
              allowedTypes={['.pdf']}
              readOnly={false}
              listoAssetType="quotes"
              onFileUploaded={(f) => {
                attachUpload({
                  quoteId,
                  uploadId: f.id,
                });
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export function Quote() {
  const { quoteId } = useParams<{ quoteId: string }>();
  const [email, setEmail] = useState('');
  const setNotification = useNotification((state) => state.setNotification);
  if (!quoteId) throw new Error('No quoteId');
  const {
    data: quote,
    isLoading,
    error,
  } = trpc.a.quotes.quote.useQuery({ quoteId });
  const { mutate: sendQuote, isLoading: isSending } =
    trpc.a.quotes.emailQuote.useMutation({
      onSuccess: () => {
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Quote emailed',
        });
      },
    });

  if (isLoading) return <PageLoader />;
  if (error) return <div>{error.message}</div>;
  if (!quote) return null;

  return (
    <div className="bg-gray-100 min-h-screen pb-8">
      <Header title="Quote" />
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-3">
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg p-6">
              <div>
                <div className="flex justify-between px-4 py-5 sm:px-6">
                  <div>
                    <div className="flex items-center ">
                      <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Quote Information
                      </h2>
                    </div>

                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Quote details and overview.
                    </p>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 border-b border-gray-200 pb-4 sm:pb-4">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Client Name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {titleCase(quote.client.name)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Country
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {countryNameByIsoCode(quote.isoCountryCode)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Accepted
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {truthyToYesNo(quote.accepted)}
                      </dd>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 mt-8">
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Client
                      </h2>
                      <div className="mt-1 grid grid-cols-1 gap-4">
                        <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400">
                          <div className="flex-shrink-0" />
                          <div className="min-w-0 flex-1">
                            <Link
                              to={`/clients/${quote.clientId}/info`}
                              className="focus:outline-none"
                            >
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900">
                                {quote.client.name}
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {quote.uploadId ? (
          <div className="lg:col-start-1 lg:col-span-1">
            <section aria-labelledby="quote">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="sm:px-6 px-4 py-5">
                  <h2
                    id="quote"
                    className="text-lg font-medium text-gray-900 leading-6"
                  >
                    Quote Uploaded
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    The quote has been uploaded.
                  </p>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <UploadQuote quoteId={quote.id} clientId={quote.clientId} />
        )}

        <ActionPanel
          title="Send Quote"
          content="Email a link to the quote or copy link to the clipboard."
        >
          <div className="flex flex-col">
            <Input
              inputProps={{
                placeholder: 'Email',
                value: email,
                onChange: (e) => {
                  setEmail(e.target.value);
                },
              }}
              inputType="email"
            />
            <Button
              className="mt-4"
              variant="secondary"
              text="Copy Link"
              onClick={() => {
                navigator.clipboard.writeText(`${APP_URL}/quotes/${quote.id}`);
                setNotification({
                  type: 'success',
                  title: 'Success',
                  message: 'Link copied to clipboard',
                });
              }}
            />
            <Button
              className="mt-2"
              text="Send Email"
              loading={isSending}
              onClick={() => {
                if (!email) {
                  setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Please enter an email',
                  });
                  return;
                }
                sendQuote({ quoteId: quote.id, email });
              }}
            />
          </div>
        </ActionPanel>
      </div>
    </div>
  );
}
