import { APP_URL } from '../../lib/constants';

export function Impersonate() {
  return (
    <iframe
      style={{ display: 'none' }}
      id="appIframe"
      title="app.listoglobal.com"
      src={APP_URL}
    />
  );
}
