import { Notification } from './Notification';

export function NotificationRegion() {
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end justify-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex flex-1 flex-col items-center justify-center space-y-4 sm:items-end">
        <Notification />
      </div>
    </div>
  );
}
