import { formatCentsToDollars } from 'listo/src/utils/currency';
import { formatDate } from 'listo/src/utils/dates';
import { titleCase } from 'listo/src/utils/strings';
import { Link, useParams } from 'react-router-dom';
import { PageLoader } from 'ui/src/components/PageLoader';
import { useState } from 'react';
import Header from '../../components/Header';
import { trpc } from '../../lib/trpc';
import { SendViaPingPong } from './SendViaPingPong';
import { UpdateConfirmationNumber } from './UpdateConfirmationNumber';

export function Distribution() {
  const { distributionId } = useParams();

  const {
    data: distribution,
    isLoading,
    error,
    refetch,
  } = trpc.a.distributions.distribution.useQuery({
    distributionId: distributionId as string,
  });

  const [confirmationNumber, setConfirmationNumber] = useState('');
  if (isLoading) return <PageLoader />;
  if (error) return <div>{error.message}</div>;
  if (distribution === undefined) return <PageLoader />;

  return (
    <div className="bg-gray-100 min-h-screen pb-8">
      <Header title="Distribution" />
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-3">
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg p-6">
              <div>
                <div className="px-4 py-5 sm:px-6">
                  <div className="flex items-center justify-between">
                    <h2
                      id="applicant-information-title"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Distribution Information
                    </h2>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 border-b border-gray-200 pb-4 sm:pb-4">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Worker Name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <Link
                          className="text-indigo-900"
                          to={`/contracts/${distribution.contractId}`}
                        >
                          {titleCase(distribution.workerProfile.fullName)}
                        </Link>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Amount
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatCentsToDollars(
                          distribution.amountInCents,
                          distribution.currency,
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Pay Period
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {distribution.payPeriod
                          ? `${formatDate(
                              distribution.payPeriod.periodStart,
                            )} - ${formatDate(
                              distribution.payPeriod.periodEnd,
                            )}`
                          : null}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Status
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {distribution.status}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Confirmation Number
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {distribution.confirmationNumber}
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="mt-8 max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-7xl">
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <SendViaPingPong
              distributionId={distribution.id}
              onChange={() => refetch()}
              onPaid={(number) => {
                // Passes the confirmation number from successful pingPong execution to the
                // widget below
                setConfirmationNumber(number);
              }}
            />
          </div>
          <div className="sm:col-span-4">
            <UpdateConfirmationNumber
              distributionId={distribution.id}
              confirmationNumber={confirmationNumber}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
