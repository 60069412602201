import { PlusIcon } from '@heroicons/react/20/solid';

import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import TableLoading from '../../components/TableLoading';
import { RouterOutput, trpc } from '../../lib/trpc';
import Table from '../../components/Table/Table';

type Admin = RouterOutput['a']['admins']['list'][0];

const columnHelper = createColumnHelper<Admin>();

function Empty() {
  const navigate = useNavigate();

  return (
    <div className="text-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No admins</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new admin.
      </p>
      <div className="mt-6">
        <button
          onClick={() => navigate('/admins/create')}
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Admin
        </button>
      </div>
    </div>
  );
}

function ActionButton({ admin }: { admin: Admin }) {
  const utils = trpc.useContext();

  const { mutate } = trpc.a.admins.delete.useMutation({
    onSuccess: () => {
      utils.a.admins.list.invalidate();
    },
  });

  return admin.fullName?.toLowerCase() !== 'admin apple' ? (
    <button
      type="button"
      className="text-indigo-600 hover:text-indigo-900"
      onClick={() => {
        mutate(admin.id);
      }}
    >
      Delete
    </button>
  ) : null;
}

const columns = [
  columnHelper.accessor('fullName', {
    header: `Admin`,
  }),

  columnHelper.display({
    id: 'actions',
    header: () => <span className="sr-only">Edit</span>,
    cell: ({ row }) => <ActionButton admin={row.original} />,
  }),
];

export function AdminsTable() {
  const { data, isLoading, error } = trpc.a.admins.list.useQuery();
  const [searchTerm, setSearchTerm] = useState<string>('');

  if (data && data.length === 0) return <Empty />;
  if (isLoading || data === undefined) return <TableLoading />;

  return (
    <Table
      data={data || []}
      columns={columns}
      error={error}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      isLoading={isLoading}
      tableType="Admins"
    />
  );
}
