import { z } from 'zod';

export const adminWorkerProfileZod = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),
  address1: z.string(),
  address2: z.string(),
  city: z.string(),
  zoneCode: z.string(),
  postalCode: z.string(),
  taxIdNumber: z.string(),
  bankAccountNumber: z.string().optional(),
  bankRoutingNumber: z.string().optional(),
  isoCountryCode: z.string(),
});

export type AdminWorkerProfileType = z.infer<typeof adminWorkerProfileZod>;

export const updateWorkerProfileZod = z.object({
  bankAccountNumber: z.string().optional(),
  bankRoutingNumber: z.string().optional(),
  bankAccountOwnerName: z.string().optional(),
  taxIdNumber: z.string().optional(),
  fullName: z.string().optional(),
  phone: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().optional(),
  city: z.string().optional(),
  zoneCode: z.string().optional(),
  postalCode: z.string().optional(),
  isoCountryCode: z.string().optional(),
});

export const updateTaxInfoZod = z.object({
  taxIdNumber: z.string().min(1, 'Tax ID number is required'),
});

export const updatePersonalInfoZod = z.object({
  fullName: z.string().optional(),
  phone: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().optional(),
  city: z.string().optional(),
  zoneCode: z.string().optional(),
  postalCode: z.string().optional(),
  isoCountryCode: z.string().optional(),
});

export const updateWorkerPasswordZod = z
  .object({
    currentPassword: z.string().min(1, 'Current password is required'),
    newPassword: z.string().min(1, 'New Password is required'),
    confirmNewPassword: z.string().min(1, 'Confirm password is required'),
  })
  .superRefine((data, ctx) => {
    const { newPassword, confirmNewPassword } = data;

    if (newPassword !== confirmNewPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['confirmNewPassword'],
        message: 'The passwords did not match.',
      });
    }
  });

export type UpdateTaxInfoType = z.infer<typeof updateTaxInfoZod>;
export type UpdatePersonalInfoType = z.infer<typeof updatePersonalInfoZod>;
export type UpdateWorkerProfileType = z.infer<typeof updateWorkerProfileZod>;
export type UpdateWorkerPasswordType = z.infer<typeof updateWorkerPasswordZod>;
