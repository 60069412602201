import { Outlet } from 'react-router-dom';
import { NotificationRegion } from 'ui/src/components/Notification';
import { SidebarProvider } from '../../hooks/useSidebar';
import Sidebar from '../Sidebar';
import { RequireAuth } from '../Utility/RequireAuth';

export function AppShell() {
  return (
    <RequireAuth>
      <div className="h-full">
        <SidebarProvider>
          <Sidebar />
          <div className="lg:pl-64 flex flex-col flex-1 min-h-screen">
            <Outlet />
          </div>
        </SidebarProvider>
        <NotificationRegion />
      </div>
    </RequireAuth>
  );
}
