import { unionBy } from 'lodash';
import capitalize from 'lodash/capitalize';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { createColumnHelper } from '@tanstack/react-table';
import { useDebounce } from 'ui/src/hooks/useDebounce';
import useIsFirstRender from 'ui/src/hooks/useIsFirstRender';
import { formatCentsToDollars } from 'listo/src/utils/currency';
import Header from '../../components/Header';
import { PaginateContext } from '../../components/Table/Pagination';
import Table from '../../components/Table/Table';
import { RouterOutput, trpc } from '../../lib/trpc';
import { SelectedFilter } from './ContractFilters';

const pageSize = 25;

type Contract = RouterOutput['a']['contracts']['paginate']['contracts'][0];

const sortOptions = [
  { label: 'Asc Name (a-z)', value: 'name.asc' },
  { label: 'Desc Name (z-a)', value: 'name.desc' },
  { label: 'created desc', value: 'createdAt.desc' },
  { label: 'created asc', value: 'createdAt.asc' },
];

const filterOptions = [
  {
    name: 'Filters',
    options: [
      { label: 'Pending Quote', value: 'pending_quote' },
      { label: 'Pending Deposit ', value: 'pending_deposit' },
      { label: 'Eor', value: 'eor' },
      { label: 'Contractor', value: 'contractor' },
    ],
  },
];

function selectedFiltersReducer(
  state: SelectedFilter[],
  action: SelectedFilter,
) {
  const filters = unionBy([action, ...state], 'optionLabel');
  return filters;
}

function variables({
  page,
  searchTerm,
  sort,
  selectedFilters,
}: {
  page: number;
  searchTerm: string | undefined;
  sort: string | undefined;
  selectedFilters: SelectedFilter[];
}) {
  const contractFilters = selectedFilters
    .filter((filter) => filter.filterName === 'Filters' && !!filter.optionValue)
    .map((filter) => filter.optionValue);
  return {
    page,
    limit: pageSize,
    searchTerm,
    sort,
    filters: {
      contractFilters: contractFilters.length
        ? contractFilters.join('|')
        : undefined,
    },
  };
}

const columnHelper = createColumnHelper<Contract>();

function TableActionBtn({ data: contract }: { data: Contract }) {
  const navigate = useNavigate();

  return (
    <div className="text-right">
      <button
        type="button"
        className="text-indigo-600 hover:text-indigo-900"
        onClick={() => {
          navigate(`/contracts/${contract.id}/edit`);
        }}
      >
        Edit
      </button>
      <button
        type="button"
        className="ml-4 text-indigo-600 hover:text-indigo-900"
        onClick={() => {
          navigate(`/contracts/${contract.id}`);
        }}
      >
        View
      </button>
    </div>
  );
}

const columns = [
  columnHelper.display({
    id: 'Name',
    header: 'Name',
    cell: ({ row }) => `${row.original.name} - ${row.original.isoCountryCode}`,
  }),
  columnHelper.display({
    id: 'Amount',
    header: 'Amount',
    cell: ({ row: { original } }) => (
      <>
        {formatCentsToDollars(
          original.billingAmountInCents,
          original.billingCurrency,
        )}
      </>
    ),
  }),

  columnHelper.display({
    id: 'actions',
    header: () => <span className="sr-only">Actions</span>,
    cell: (props) => <TableActionBtn data={props.row.original} />,
  }),
];

export function Contracts() {
  const isFirstRender = useIsFirstRender();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sort, setSort] = useState('createdAt.desc');
  const [page, setPage] = useState(0);
  const [searchParams] = useSearchParams();

  const debouncedSearchTerm = useDebounce<string>(searchTerm, 275);

  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    selectedFiltersReducer,
    [],
  );

  useEffect(() => {
    const engagementType = searchParams.get('engagementType');

    if (engagementType) {
      dispatchSelectedFilters({
        filterName: 'Filters',
        optionLabel: capitalize(engagementType),
        optionValue: engagementType,
      });
    }
  }, []);

  const { data, isLoading, error, refetch } =
    trpc.a.contracts.paginate.useQuery(
      variables({
        page,
        searchTerm: debouncedSearchTerm,
        sort,
        selectedFilters,
      }),
    );

  const paginationData = useMemo(
    () => ({
      setPage,
      currentPage: page,
      nextPage: data?.nextPage,
      prevPage: data?.prevPage,
      total: data?.total,
      pageSize: data?.pageSize,
    }),
    [page, data],
  );

  useEffect(() => {
    if (isFirstRender) return;
    refetch();
  }, [page, debouncedSearchTerm, sort, selectedFilters]);

  useEffect(() => {
    if (page !== 0) setPage(0);
  }, [selectedFilters, debouncedSearchTerm]);

  if (error) return <h1>error</h1>;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header
        title="Contracts"
        buttonRoute="/contracts/create"
        buttonText="New"
      />
      <div className="m-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <PaginateContext.Provider value={paginationData}>
              <Table
                data={data?.contracts || []}
                columns={columns}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                error={error}
                isLoading={isLoading}
                tableType="Contracts"
                tableFilterProps={{
                  sort: {
                    name: 'Sort',
                    options: sortOptions,
                  },
                  filters: filterOptions,
                  currentFilters: selectedFilters,
                  setCurrentFilters: dispatchSelectedFilters,
                  sortValue: sort,
                  setSortValue: setSort,
                }}
              />
            </PaginateContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
}
